import { cloneDeep } from "lodash";
import {saveToDisk} from "../../../../common/helpers/saveToDisk";

class InstructorCourseAttendanceController {
    constructor(UserService, $state, course, CoursesModel, FlashService, VueStoreFactory) {   
        this.$state = $state; 
        this.course = course;
        this.VueStoreFactory = VueStoreFactory;
        this.CoursesModel = CoursesModel;
        this.FlashService = FlashService;
        this.user = {};
        this.printing = false;
        this.loading = true;
        this.currentUserId = UserService.getCurrentUser().id;
        this.studentAttendance = [];

        this.print = this.print.bind(this);
        this.updateAttendance = this.updateAttendance.bind(this);
        this.getAttendance = this.getAttendance.bind(this);

        this.setup().then(response => {
            this.loading = false;
        }).catch(Error => {
            console.error(Error)
            this.loading = false;
        })
    }

    async setup() {
        if (this.VueStoreFactory.state.viewingCourse.class_id === 0) {
            let course = {
                id: this.course.id,
                class_id: this.course.classes[0].id,
                date:  new Date(this.course.classes[0].start).toLocaleDateString(),
                start: new Date(this.course.classes[0].start).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' }),
                end: new Date(this.course.classes[0].end).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' }),
            }
            this.VueStoreFactory.dispatch('SET_VIEW_COURSE', course);
        }
        const studentAttendance = await this.getAttendance(this.course.id, this.VueStoreFactory.state.viewingCourse.class_id)
        this.studentAttendance = studentAttendance;
    }

    print() {
        this.printing = true;
        this.CoursesModel.printRoster({'id': this.course.id, 'class_id' : this.VueStoreFactory.state.viewingCourse.class_id}).then(response => {
            this.printing = false;
            saveToDisk(response.file.url, response.file.name);
        });
    }

    getAttendance(courseId, class_id) {
        return this.CoursesModel.getCourseClassAttendance(courseId, class_id).then(response => {
           return response;
        })
    }
    
    updateAttendance(data) {
        //data is an object with keys courseId and students which is an array of students w classDates
        let submitData = cloneDeep(data);
        submitData.class_id = this.VueStoreFactory.state.viewingCourse.class_id;

        //remove classDates with null values for present key bc we arent saving those dates
        let studentsWithNoNulls = submitData.students.map((student) => {
            let datesWithNoNulls = student.classDates.filter((classDate) => {
                return classDate.present !== null;
            })
            student.classDates = datesWithNoNulls;
            return student;
        })
        submitData.students = studentsWithNoNulls;

        this.CoursesModel.updateCourseClassAttendance(submitData).then(response => {
            // console.log('response', response)
            this.FlashService.setMessage({
                'type' : 'success',
                'message' : response.message
            })
        }) 
    }
} 
InstructorCourseAttendanceController.$inject = ['UserService', '$state', 'course', 'CoursesModel', 'FlashService', 'VueStoreFactory'];
export default InstructorCourseAttendanceController;