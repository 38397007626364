import angular from 'angular'
import template from './instructor.html'
import InstructorController from './instructor.controller'

import FooterNav from './directives/footernav/instructor-footer-nav.module'
import Nav from './directives/nav/instructor-nav.module'
import BatchCreate from './directives/batch-create-entity/batch-create-entity.module'

import Dashboard from './dashboard/instructor-dashboard.module'

function getCurrentUser(AuthService, UserService) {
    return AuthService.getCurrentUser().then(response => {
        UserService.setCurrentUser(response);
        return UserService.getCurrentUser();
    }).catch(Error => {
        console.log(Error)
    });
}
getCurrentUser.$inject = ['AuthService', 'UserService'];
function config($stateProvider, $urlRouterProvider, USER_ROLES){
    $stateProvider
        .state('drivecoach.instructor', {
            url: '/instructor',
            controller: 'InstructorController',
            controllerAs: 'vm',
            template: template,
            resolve : {
                currentUser: getCurrentUser
            },
            data: {
                authorizedRoles: [
                    USER_ROLES.admin,
                    USER_ROLES.owner,
                    USER_ROLES.instructor
                ]
            }
        })
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module('instructor', [
    Nav.name,
    FooterNav.name,
    Dashboard.name,
    BatchCreate.name,
])
    .config(config)
    .controller('InstructorController', InstructorController );
