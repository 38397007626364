<template>
  <div class="fc-container" data-cy="calendarWrapper">
    <div v-if="loading" class="container-fluid loading">
      <i class="fa fa-circle-o-notch fa-spin fa-2x"></i> 
    </div>
    <FullCalendar 
      v-if="showElement" 
      buttonIcons="false"
      :buttonText="buttonText"
      contentHeight="auto"
      :custom-buttons="customButtons"
      :datesRender="dateRenderChange"
      :defaultView="defaultView"
      :defaultDate="startDate"
      eventLimitClick="day"
      :eventRender="eventRender"
      :events="events"
      :header="headerOptions"
      :maxTime="maxTime"
      :minTime="minTime"
      :plugins="calendarPlugins" 
      ref="dcCalendar"
      :resources="resources"
      filterResourcesWithEvents="true"
      schedulerLicenseKey='GPL-My-Project-Is-Open-Source'
      :views="viewOptions"
      @dateClick="handleDateClick"
      @eventClick="handleEventClick"/>
  </div>
</template>

<script>
  import FullCalendar from '@fullcalendar/vue'
  import dayGridPlugin from '@fullcalendar/daygrid'
  import timeGridPlugin from '@fullcalendar/timegrid'
  import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
  import interactionPlugin from '@fullcalendar/interaction'
  import moment from 'moment'
  import cloneDeep from 'lodash/cloneDeep'
  import sortBy from 'lodash/sortBy'
  import orderBy from 'lodash/orderBy'
  import tippy from 'tippy.js';
  import { hideAll } from 'tippy.js';
  import 'tippy.js/dist/tippy.css';

  export default {
    name: 'dcCalendar', //this becomes the ngvue directive markup aka <dc-component-name></dc-component-name>
    components: { FullCalendar },
    props: {
      'context': { type: String, default: '' },
      'calendarEvents': { type: Array, default: function() {
          return []
        }
      },
      'showElement': { type: Boolean, default: true },
      'calendarLoading': { type: Boolean, default: false },
      'calendarStartDate': { default: moment().format('YYYY-MM-DD') },
      'calendarView': { type: String, default: 'dayGridMonth' }
    },
    data() {
      return {
        customButtons: {
          instructorAgenda: {
            text: 'Agenda',
            click: this.handleAgendaButtonClick
          }
        },
        calendarPlugins: [ 
          dayGridPlugin, 
          timeGridPlugin,
          interactionPlugin,
          resourceTimeGridPlugin
        ],
        viewOptions: {
          dayGridMonth: {
            eventLimit: 5,
            showNonCurrentDates: false,
            fixedWeekCount: false
          },
          timeGrid: {
            titleFormat: { year: 'numeric', month: 'long', day: 'numeric' }
          },
          resourceTimeGrid: {
            titleFormat: { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' }
          },
          timeGridDay: {
            titleFormat: { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' }
          }
        },
        headerOptions: {
          left:   'today prev next',
          center: 'title',
          //dont show instructorDay view in instructor calendar
          right:  this.context === 'instructor' || this.context === 'instructor_team_calendar' ? 'dayGridMonth timeGridWeek instructorAgenda' : 'dayGridMonth timeGridWeek timeGridDay resourceTimeGridDay'
        },
        buttonText: {
          prev: 'Back',
          next: 'Next',
          today: 'Today',
          dayGridMonth: 'Month',
          timeGridWeek: 'Week',
          timeGridDay: 'Day',
          resourceTimeGridDay: 'Instructor Day'
        },
        calStart: null,
        calEnd: null
      }
    },
    methods: {
      dateRenderChange: function(info) {
        let newCalStart = moment(info.view.currentStart).valueOf();
        let newCalEnd = moment(info.view.currentEnd).valueOf();
        //only emit if start/end have changed
        //prevents events reload on min/max time change
        if(this.calStart !== newCalStart || this.calEnd !== newCalEnd) {
          this.$emit('fc-date-change', {
            startDate: newCalStart, 
            endDate: newCalEnd, 
            context: this.context,
            viewType: info.view.type
          });
        }
        this.calStart = newCalStart;
        this.calEnd = newCalEnd;
      },
      handleDateClick: function(arg) {
        let date = moment(arg.dateStr).format('YYYY-MM-DD');
        this.$emit('fc-date-click', { dateStr: date, context: this.context });
      },
      handleEventClick: function(arg) {
        let date = moment(arg.event.start).format('YYYY-MM-DD');
        let eventName = arg.event.extendedProps.label.name;
        hideAll({duration: 0});
        this.$emit('fc-event-click', { id: arg.event.id, context: this.context, eventName: eventName, event: arg.event, dateStr: date });
      },
      handleAgendaButtonClick() {
        let date = moment().format('YYYY-MM-DD');
        this.$emit('fc-agenda-click', {
          dateStr: date,
          context: this.context,
        });
      },
      getClickedDate: function(event) {
        //used to get specific date clicked on multiple day event
        var topLayer = $(event.el).closest(".fc-row");
        var eventLayer = topLayer.find(".fc-content-skeleton");
        let initialZIndex = $(eventLayer).css('z-index');
        
        $(eventLayer).css({ 'visibility': 'hidden', 'z-index': '-1'});
        
        var dayElement = $(document.elementFromPoint(event.jsEvent.pageX - window.pageXOffset, event.jsEvent.pageY - window.pageYOffset));
        
        $(eventLayer).css({ 'visibility': 'visible', 'z-index': initialZIndex });
        
        var clickedDate = dayElement.attr("data-date");
        return clickedDate;
      },
      formatDateRTO: function(event) {        
        if(!event.type) {
          event.type = 'RTO';
        }
        event.start = moment(event.begin).toDate();
        event.end = moment(event.end).toDate();

        if(event.rto_type === 'full' || event.rto_type === 'range') {
          event.allDay = true;
        }
        
        if(event.rto_type === 'range') {
          //full calendar end date is exclusive, so this is needed to render end date properly on range rto
          event.end = moment(event.end).add(1, 'days').toDate();
        }
        return event
      },
      formatEvents: function(events) {
        return events.map((event) => {
          let formattedEvent = cloneDeep(event);
          
          if(formattedEvent.type === 'RTO' || formattedEvent.label.type === 'RTO') {
            formattedEvent = this.formatDateRTO(formattedEvent);
          } 

          formattedEvent.url = '';
          formattedEvent.classNames = ['session'];
          formattedEvent.resourceId = formattedEvent.instructor_id;
          
          delete formattedEvent.startTime;
          delete formattedEvent.endTime;
          delete formattedEvent.start_time;
          delete formattedEvent.end_time;

          formattedEvent.title = formattedEvent.calendarDisplay
          //set data based on event type
          switch(formattedEvent.type) {
            case 'Course' :
              formattedEvent.classNames.push('course');
              break;
            case 'Event' :
              formattedEvent.classNames.push('event');
              break;
            case 'RTO' :
              formattedEvent.classNames.push('rto');
              break;
            case 'Drive Session' :
              formattedEvent.classNames.push('drive');
              if(formattedEvent.status === 'Taken' || formattedEvent.student_id !== 0) {
                formattedEvent.classNames.push('booked-session');
              } else if (moment().unix() >= formattedEvent.timestamp) {
                //set past open session styling
                formattedEvent.classNames.push('past-session');
                formattedEvent.title = 'Past ' + formattedEvent.title;
              }
              break;
            default : 
              //for drives
              formattedEvent.classNames.push('drive');
          }
          return formattedEvent;
        });
      },
      eventTooltip: function(el, description) {        
        return tippy(el, {
          content: description,
        });
      },
      eventRender: function(data) {
        let { event, el, view } = data;
        
        //allows html in event title
        let eventTitle = el.querySelectorAll('.fc-title')[0];
        
        //content for tooltip and to add extra content to event block in day/week views
        let eventBlockContent = eventTitle.textContent;
        let tooltipContent = eventTitle.textContent;
        
        if(event.extendedProps.type === 'Drive Session') {
          //show instructor if not in instructor portal
          if(this.context !== 'instructor') {
            tooltipContent += `<br>Instructor: ${event.extendedProps.instructor_name}`;
            eventBlockContent += `<br>Instructor: ${event.extendedProps.instructor_name}`;
          }
          
          
          if(this.context !== 'instructor') {
            const zones = event.extendedProps.zoneparts.map(zone => zone.name).join(', ');
            tooltipContent += `<br>Zones: ${zones}`;
            eventBlockContent += `<br>Zones: ${zones}`;
          }

          //show student name and pickup location if drive booked
          if(event.extendedProps.status === 'Taken') {
            if (event.extendedProps.hasOwnProperty('pickup_location')) {
              let address = event.extendedProps.pickup_location.address;
              tooltipContent += `<br>Pickup Location: ${address.street}, ${address.city}, ${address.state} ${address.zip}`;
              if(this.context === 'instructor') {
                eventBlockContent += `<br>Pickup Location: ${address.street}, ${address.city}, ${address.state} ${address.zip}`;
              }
            }

          } else {
            tooltipContent += `<br>Location: ${event.extendedProps.location.name}`;
            if(this.context === 'instructor') {
              eventBlockContent += `<br>Location: ${event.extendedProps.location.name}`;
            }
          }
          
          tooltipContent += `<br>Vehicle: ${event.extendedProps.vehicle.name}`;
          eventBlockContent += `<br>Vehicle: ${event.extendedProps.vehicle.name}`;

        } else if (event.extendedProps.type === 'Course') {
            if(event.extendedProps.name !== event.extendedProps.course_type_name) {
              tooltipContent += ` - ${event.extendedProps.course_type_name}`;
              eventBlockContent += ` - ${event.extendedProps.course_type_name}`;
            }

            //show instructor for admin calendar
            if(this.context !== 'instructor') {
              tooltipContent += `<br>Instructor: ${event.extendedProps.instructor_name}`;
              eventBlockContent += `<br>Instructor: ${event.extendedProps.instructor_name}`;
            }
              
            tooltipContent += `<br>Location: ${event.extendedProps.location_name}`;
            eventBlockContent += `<br>Location: ${event.extendedProps.location_name}`;
            
            tooltipContent += `<br>Classroom: ${event.extendedProps.classroom_name}`;
            eventBlockContent += `<br>Classroom: ${event.extendedProps.classroom_name}`;
              
        } else if (event.extendedProps.type === 'Event') {
          //show instructor 
          if(this.context !== 'instructor') {
            tooltipContent += `<br>Instructor: ${event.extendedProps.instructor_name}`;
            eventBlockContent += `<br>Instructor: ${event.extendedProps.instructor_name}`;
          }
        }

        if(view.type !== 'dayGridMonth') {
          //sets event block content
          eventTitle.innerHTML = eventBlockContent;
          
          let eventTime = el.querySelectorAll('.fc-time>span')[0];
          //all day rtos dont have time content
          if(eventTime) {
            let timeContent = eventTime.textContent;
            tooltipContent = `${timeContent}<br>${tooltipContent}`;
            eventBlockContent = `${timeContent}<br>${eventBlockContent}`;
          }
          
        } else if(event.allDay !== true) {
          let start = moment(event.start).format('h:mma');
          let end = moment(event.end).format('h:mma');
          tooltipContent = `${start} - ${end}<br>${tooltipContent}`;
          eventBlockContent = `${start} - ${end}<br>${eventBlockContent}`;
        }
        //sets tooltip content
        this.eventTooltip(el, tooltipContent);
      }
    },
    mounted () {
      console.log('mounted');
      //cal start and end are used to check if date has changed for date render function
      this.calStart = moment(this.fullCalendarApi.view.currentStart).valueOf();
      this.calEnd = moment(this.fullCalendarApi.view.currentEnd).valueOf();
      //set tooltip defaults
      tippy.setDefaultProps({
        placement: 'top',
        touch: false,
        allowHTML: true
      })
    },
    beforeDestroy() {
      this.$emit('fc-cal-destroy', {
        startDate: this.fullCalendarApi.view.currentStart, 
        endDate: this.fullCalendarApi.view.currentEnd, 
        context: this.context,
        viewType: this.fullCalendarApi.view.type
      })  
      hideAll({duration: 0});
      this.fullCalendarApi.destroy()
    },
    destroyed () {
      console.log('destroyed')
    },
    created () {
//      console.log('created')
    },
    computed: {
      //making these computed properties instead of part of the regular data object makes them reactive to changes in the angular state
      events: function() {
        return this.formatEvents(this.calendarEvents);
      },
      fullCalendarApi: function() {
        return this.$refs.dcCalendar.getApi();
      },
      resources: function() {
        return this.calendarEvents.map(function(el){
          let resource = {
            id: el.instructor_id,
            title: el.instructor_name
          }
          return resource;
        })
      },
      loading: function() {
        return this.calendarLoading;
      },
      startDate: function() {
        return this.calendarStartDate;
      },
      defaultView: function() {
        return this.calendarView;
      },
      minTime: function() {
        //this sets earliest visible time slot dynamically
        let minTime = "07:00:00";
        if(this.events.length > 0) {
          //filter out all day events with start time of '00:00:00'
          let sortedEvents = this.events.filter(event => {
            return event.allDay !== true
          });

          if(sortedEvents.length > 0) {
            //return start time of first sorted element
            let earliestEvent = sortBy(sortedEvents, event => {
              event.eventStart = moment(event.start).format('HH:mm:ss');
              return event.eventStart;
            })[0].eventStart

            minTime = (earliestEvent < "07:00:00" ? earliestEvent : "07:00:00");
          }
        }
        return minTime;
      },
      maxTime: function() {
        //this sets earliest visible time slot dynamically
        let maxTime = "20:00:00"
        if(this.events.length > 0) {
          //filter out all day events with start time of '00:00:00'
          let sortedEvents = this.events.filter(event => {
            return event.allDay !== true;
          });
          //return start time of first sorted element
          if(sortedEvents.length > 0) {
            
            let lastEventEnd = orderBy(sortedEvents, event => {
              event.eventEnd = moment(event.end).format('HH:mm:ss');
              return event.eventEnd;
            }, 'desc')[0].eventEnd;
            
            maxTime = (lastEventEnd > "20:00:00" ? lastEventEnd : "20:00:00");
          }

        }
        return maxTime;
      }
    }
  }
</script>

<style lang="scss">
  @import '~@fullcalendar/core/main.css';
  @import '~@fullcalendar/daygrid/main.css';
  @import '~@fullcalendar/timegrid/main.css';
  @import '../../../../../assets/css/partials/variables.scss';

  /////////////////
  //full calendar
  /////////////////
  .fullcalendar {
    font-family: $body-stack;
    font-size: 11px;
    padding-top: 15px;
    hr.fc-divider {
      width: auto !important;
    }
    .loading {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: rgba(245, 246, 250, 0.7);
      z-index: 1000;
      cursor: not-allowed;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .fc-popover {
      z-index: 1000;
    }
    .fc-view {
      z-index: 200;
    }
    .fc-event {
      cursor: pointer;
    }
  }
  //session blocks
  .fullcalendar .session {
    border-radius: 4px;
    background-color: $blue;
    color: $white;
    font-size: 10px;
    @media #{$small} {
      font-size: 14px;
    }
    padding: 2px 4px;
    &.booked-session {
      background-color: $green;
      border-color: $green;
    }
    &.past-session {
      background-color: $blue-grey;
      border-color: $blue-grey;
    }
    &.course {
      background-color: $teal;
      border-color: $teal;
    }
    &.event {
      background-color: $light-purple;
      border-color: $light-purple;
    }
    &.rto {
      background-color: $light-grey;
      border-color: $light-grey;
    }
    
  }
  //buttons

  .fc-button-primary.fc-button {
    padding: 10px 12px;
    color: $grey;
    border: 1px solid;
    border-color: $light-grey !important;
    margin: 0 !important;
    border-radius: 0px;
    text-transform: none;
    background: $white;
    font-size: 12px;
    line-height: 1;
    text-shadow: none;
    transition: all .2s ease-in-out;
    white-space: nowrap;
    box-shadow: 0 1px 2px rgba($light-grey,.5);
    font-weight: 500;
    &:hover, &:active {
      background: $black;
      color: $green;
    }
    &:first-child {
      border-radius: 4px 0px 0px 4px;
      border-right: 0;
    }
    &:last-child {
      border-radius: 0px 4px 4px 0px;
      border-left: 0;
    }
    &:nth-child(3) {
      border-left: 0;
    }
    &:disabled {
      color: $light-grey;
    }
    &.fc-button-active, &.fc-today-button, &.fc-customTodayButton-button {
      color: $blue !important;
      background-color: $white !important;
      &:hover, &:active {
        background-color: $grey !important;
        color: $white !important;
      }
      &:disabled {
        color: $blue !important;
      }
    }
    @media #{$small} {
      &.fc-dayGridMonth-button {
        border-right: 1px solid $light-grey;
      }
      &:first-child {
        border-radius: 0;
        border-right: 0;
      }
      &:last-child {
        border-radius: 0;
        border-left: 0;
      }
    }
  }

  //calendar body
  .fc th.fc-day-header {
    padding: 7px 0;
    text-transform: uppercase;
    font-size: 75%;
    background-color: #F5F6FA;
    border-left: 0;
    border-right: 0;
  }
  .fc-day-grid-event .fc-time {
    font-weight: normal;
  }
  .fc-unthemed td.fc-today {
    background: #e7e9f3;
  }
  .fc-dayGrid-view .fc-week-number, .fc-dayGrid-view .fc-day-number {
    padding: 10px 12px 0 0;
  }
  .fc-time-grid {
    tr {
      height: 25px;
    }
  }
  //fc header 
  #admin-calendar .fc-toolbar h2 {
    font-size: 1.35em;
    @media #{$tentwofour} {
      text-align: center;
    }
  }
  .fc-toolbar {
    flex-wrap: wrap;
    @media #{$small} {
      flex-direction: column;
      align-items: stretch;
      > * {
        flex-basis: 100%;
        display: flex;
        margin-bottom: 5px;
        .fc-button.btn {
          flex-basis: 33%;
        }
      }
      .fc-center {
        order: 1;
        justify-content: center;
      }
      .fc-left {
        order: 2;
      }
      .fc-right {
        order: 3;
      }
    }
    .fc-right, .fc-left, .fc-center {
      @media #{$tentwofour} {
        flex-basis: 33%; 
      }
    }
    .fc-right {
      @media #{$tentwofour} {
        display: flex;
        > .fc-dayGridMonth-button {
          margin-left: auto !important;
        }
      }
    }
  }
  
  //tooltips
  .tippy-content {
    font-size: 11.5px;
  }
  .tippy-tooltip {
    .tippy-arrow {
      height: 0;
    }
  }
  
  
</style>