import {Notifier} from '@airbrake/browser'

function ExceptionHandler($log, AIRBRAKE_PROJECT_ID, AIRBRAKE_PROJECT_KEY, ENV, UserService) {
  var airbrake = new Notifier({
    projectId: AIRBRAKE_PROJECT_ID,
    projectKey: AIRBRAKE_PROJECT_KEY,
    environment: ENV
  });

  airbrake.addFilter(function(notice) {
    notice.context.environment = ENV;
    notice.context.user = UserService.getCurrentUser();
    notice.context.ds_client_version = '1.18.4';
    return notice;
  });

  return function(exception, cause) {
    $log.error(exception);
    if (ENV === "production" || ENV === "development" || ENV === "staging" || ENV === "dev") {
      airbrake.notify({
        error: exception,
        params: {
          angular_cause: cause,
        },
      });
    }
  };
}

ExceptionHandler.$inject = ['$log', 'AIRBRAKE_PROJECT_ID', 'AIRBRAKE_PROJECT_KEY', 'ENV', 'UserService'];
export default ExceptionHandler;