import angular from 'angular'
import template from './admin-create-student-pick-up-location.html'
import AdminStudentCreatePickupLocationController from './admin-create-student-pick-up-location.controller'
import Residence from './residence/admin-student-create-residence-pick-up-location.module'
import Highschool from './high-school/admin-student-create-high-school-pick-up-location.module'

function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.admin.students.pickuplocations.create', {
            'url' : '/create',
            'controller' : AdminStudentCreatePickupLocationController,
            'controllerAs' : 'vm',
            'template' : template,
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner,
                    USER_ROLES.staff
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module('admin.dashboard.students.pickuplocations.create', [
    Residence.name,
    Highschool.name
]).config(config)
    .controller('AdminStudentCreatePickupLocationController', AdminStudentCreatePickupLocationController)
;
