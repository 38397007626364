import angular from 'angular'
import template from './admin-student-create-residence-pick-up-location.html'
import AdminStudentCreatePickupLocationResidenceController from './admin-student-create-residence-pick-up-location.controller'

function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.admin.students.pickuplocations.create.residence', {
            'url' : '/residence',
            'controller' : AdminStudentCreatePickupLocationResidenceController,
            'controllerAs' : 'vm',
            'template' : template,
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner,
                    USER_ROLES.staff
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];


export default angular.module( 'admin.dashboard.students.pickuplocations.create.residence', [] )
    .config(config)
    .controller( 'AdminStudentCreatePickupLocationResidenceController', AdminStudentCreatePickupLocationResidenceController )
;
