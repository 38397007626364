class TaskModalController {
    constructor(CrmTasksModel, $uibModalInstance, $rootScope, AdminTasksService, task){
        this.task = task;
        this.$uibModalInstance = $uibModalInstance;
        console.log('this.task modal controller', task)
        // CrmTasksModel.getTaskByID(this.task.$id).then(response => {
        //     console.log('response', response)
        //     this.task = Object.assign(this.task, response.data);
        //     console.log('task', this.task)
        // })
        this.cancel = this.cancel.bind(this)
        this.close = this.close.bind(this)
        $rootScope.$on('TASKS_UPDATED', this.close)
        $rootScope.$on('CLOSE_TASK_MODAL', this.cancel)
    }
    cancel() {
        this.$uibModalInstance.dismiss('cancel');
    }
    close() {
        this.$uibModalInstance.close({name: 'updated', task: this.task});
    }
}
TaskModalController.$inject = ['CrmTasksModel', '$uibModalInstance', '$rootScope', 'AdminTasksService', 'task'];
export default TaskModalController;
