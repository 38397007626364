import angular from 'angular'
import StudentDrivesController from './student-drives.controller'

import template from './student-drives.html'
function getStudent( UserService, $stateParams, UsersModel ) {
    const currentUser = UserService.getCurrentUser();
    return UsersModel.getStudentByID( currentUser.id ).then( function ( response ) {
        return response;
    } )
}
getStudent.$inject = ['UserService', '$stateParams', 'UsersModel'];
function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.student.drives', {
            url : '/drives',
            template : template,
            controller : StudentDrivesController,
            controllerAs : 'vm',
            resolve : {
                student : getStudent
            },
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.parent,
                    USER_ROLES.owner,
                    USER_ROLES.student
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

import BookDrive from './book-drive/book-drive.module'
import DrivesIndex from './index/student-drives-index.module'
import ViewDrive from './view/student-drive-view.module'
import CancelDrive from './cancel-drive/cancel-drive.module'
import UpcomingDrives from './upcoming/student-upcoming-drives.module'
import PastDrives from './student-past-drives/student-past-drives.module'
import Agenda from './agenda/student-agenda.module'
export default angular.module( 'student.drives', [
    BookDrive.name,
    DrivesIndex.name,
    ViewDrive.name,
    CancelDrive.name,
    UpcomingDrives.name,
    PastDrives.name,
    Agenda.name
] )
    .config( config )
    .controller( 'StudentDrivesController', StudentDrivesController )
;
