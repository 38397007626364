import angular from 'angular'
import template from './admin-edit-instructor-location.html'
import AdminInstructorEditLocation from './admin-edit-instructor-location.controller'
import {isJson} from '../../../../common/helpers/json'

getSchool.$inject = ['SchoolsModel', 'UserService'];
function getSchool(SchoolsModel, UserService) {
    let user = UserService.getCurrentUser();
    return SchoolsModel.getSchoolByID(user.school.id).then(response => {
        response.address = isJson(response.address) ? JSON.parse(response.address) : response.address;
        return response;
    })
}

getInstructor.$inject = ['UsersModel', '$stateParams'];
function getInstructor(UsersModel, $stateParams){
    return UsersModel.getInstructorByID($stateParams.instructorId).then(response => {
        let user = response;
        let locations = response.locations.filter(location => {
            location.address = isJson(location.address) ? JSON.parse(location.address) : location.address;
            return location;
        } );
        ;
        user.locations = locations;
        return user;
    });
}
function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.admin.instructors.editlocation', {
            'url' : '/:instructorId/edit-location/:locationId',
            'controller' : AdminInstructorEditLocation,
            'controllerAs' : 'vm',
            'template' : template,
            'resolve' : {
               'school' : getSchool, 
               'instructor' : getInstructor
            },
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'admin.dashboard.instructors.editlocation', [] )
    .config(config)
    .controller( 'AdminInstructorEditLocation', AdminInstructorEditLocation )
;
