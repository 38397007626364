import { PaymentMethods } from "../../../../../common/constants/payment_methods";
import { Months } from "../../../../../common/constants/months_and_years";
import { Years } from "../../../../../common/constants/months_and_years";
import { States } from "../../../../../common/constants/states";
import cloneDeep from "lodash/cloneDeep";

class AdminStudentViewFeesController {
  constructor($stateParams, UsersModel, FlashService, PoliciesModel) {
    this.loading = true;
    this.$stateParams = $stateParams;
    this.UsersModel = UsersModel;
    this.FlashService = FlashService;
    this.payment = {
      card: {}
    };
    this.payments = [];
    this.fees = [];
    this.addresses = [];
    this.paymentMethods = [];
    this.fee = null;
    this.payment_methods = cloneDeep(PaymentMethods);
    this.months = Months;
    this.years = Years;
    this.states = States;

    this.policies = {
      'billing' : {
        'policymeta': {
        }
      }
    }
    this.surchargeBilledSeparately = false;
    this.surchargeDetail = '';
    this.surchargeAmountBilled = 0;
    this.surchargeAmount = 0;
    this.paidToday = 0;

    this.handleAuthorizeCard = this.handleAuthorizeCard.bind(this);

    PoliciesModel.getByID('billing').then(response => {
      let policyMeta = {};
      response.policy.policymeta.forEach(meta => {
        policyMeta[meta.meta_key] = meta.meta_value;
      });
      this.policies.billing.policymeta = policyMeta;
      this.policies.billing.policymeta.id = response.policy.id;
      this.gateway = this.policies.billing.policymeta.billing_gateway;
      this.gateway_config = {
          authorize_api_client_key: this.policies.billing.policymeta.authorize_client_key,
          authorize_trans_key: this.policies.billing.policymeta.authorize_api_trans_key,
          authorize_api_login_id: this.policies.billing.policymeta.authorize_api_login_id,
          paypal_rest_api_client_id : this.policies.billing.policymeta.paypal_rest_api_client_id,
      }
      if (policyMeta.hasOwnProperty('surcharge_amount')) {
        this.surchargeBilledSeparately = parseFloat(policyMeta.surcharge_amount) > 0;
        this.surchargeAmount = parseFloat(policyMeta.surcharge_amount);
      }

      if (policyMeta.hasOwnProperty('surcharge_detail')) {
        this.surchargeDetail = policyMeta.surcharge_detail;
      }

      // this.loading = false;
      // this.submitting = false;
    });

    UsersModel.getStudentByID($stateParams.userId).then(response => {
      this.student = response;
      if (this.student.school.allows_electronic_payment === false) {
        this.payment_methods = this.payment_methods.filter(method => {
          return method.value !== "card";
        });
      }
    });

    this.UsersModel.getPickupLocations($stateParams.userId).then(response => {
      this.addresses = response.pickuplocations.map(location => {
        return location.address;
      });
    });

    this.UsersModel.getStudentPaymentMethods($stateParams.userId).then(response => {
      this.paymentMethods = response.payment_methods;
    });

    this.UsersModel.getStudentFees(this.$stateParams.userId).then(response => {
      this.fees = response.fees;
      this.loading = false;
    });
  }

  selectPaymentMethod(paymentMethodID) {
    this.payment.card = {
        payment_method_id: paymentMethodID,
        use_existing_payment_method: true
    }
  }

  handleAuthorizeCard(tokenData)
  {
      // set token data on order
      this.payment.card.token = {
          opaqueData : tokenData
      };
      delete this.payment.card.name_on_card;
      delete this.payment.card.number;
      delete this.payment.card.expiration_month;
      delete this.payment.card.expiration_year;
      delete this.payment.card.cvc;

      this.submitPayment();
  }
  
  checkSurchargeRequired() {
    return this.surchargeBilledSeparately && this.payment.payment_method === 'card';
  }

  calculateTotal() {
    if (this.surchargeBilledSeparately && this.payment.payment_method === 'card') {
      let surchargeAsDecimal = this.surchargeAmount / 100;

      let surcharge = (this.payment.payment_amount * surchargeAsDecimal) / 100;
      this.surchargeAmountBilled = surcharge.toFixed(2)
      let orderTotal = this.fee.amount + (surcharge.toFixed(2) * 100);
      let total = this.payment.payment_amount + (surcharge.toFixed(2) * 100);
      let totalInDollars = total / 100;
      this.orderTotal = orderTotal;
      this.paidToday = totalInDollars.toFixed(2);
      return surcharge.toFixed(2);
    }

    let orderTotalInCents = this.fee.amount;

    this.fee.amount = orderTotalInCents;
    return orderTotalInCents;
  }

  addBillingAddress(index, address) {
    if (!this.payment.hasOwnProperty("card")) {
      this.payment.card = {};
    }
    this.payment.card.billing_address = address;
  }

  voidFee(feeId, index) {
    this.submitted = true;
    this.UsersModel.voidFee(this.student.id, feeId).then(response => {
      this.submitted = false;
      this.FlashService.setMessage({
        type: "success",
        message: response.message
      });
      response.fee.amount_owed = 0;
      this.fees[index] = response.fee;
    });
  }

  selectFee(feeId, index) {
    this.fee = this.fees[index];
    // get payments
      this.UsersModel.getStudentFeePayments(this.$stateParams.userId, feeId).then(response => {
        this.payments = response.payments;
      })
  }

  submitPayment() {
    this.submitted = true;
    let payment = cloneDeep(this.payment);
    this.UsersModel.submitPayment(this.student.id, this.fee.id, {
      payment: payment
    })
      .then(response => {
        this.submitted = false;
        this.FlashService.setMessage({
          type: "success",
          message: response.message
        });
        // replace fee
        this.fees.forEach((_fee, index) => {
          if (_fee.id === response.fee.id) {
            this.fees[index] = response.fee;
            this.payments.push(response.payment)
          }
        });
        this.payment = {};
        //this.fee = null;
      })
      .catch(response => {
        this.submitted = false;
      });
  }
}
AdminStudentViewFeesController.$inject = [
  "$stateParams",
  "UsersModel",
  "FlashService",
  "PoliciesModel"
];
export default AdminStudentViewFeesController;
