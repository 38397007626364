import { toQueryString } from "../helpers/queryString"

function CoursesModel($http, APPURL) {

    let model = this,
        URLS = {
            FETCH: APPURL + '/api/v1/courses',
            UPDATE: APPURL + '/api/v1/courses',
            CREATE: APPURL + '/api/v1/courses'

        },
        courses;

    function extract(result) {
        return result.data;
    }

    function cacheResults(result) {
        courses = extract(result);
        return courses;
    }

    model.show = function (id) {
        return $http.get(URLS.FETCH + '/' + id).then(cacheResults);
    };

    model.get = function (data) {
        if (data) {
            return $http.get(URLS.FETCH + '?' + toQueryString(data)).then(cacheResults);
        }
        return $http.get(URLS.FETCH).then(cacheResults);
    };

    model.update = function (request) {
        return $http.put(URLS.FETCH + '/' + request.id, request).then(cacheResults);
    };

    model.create = function (request) {
        return $http.post(URLS.FETCH, request).then(cacheResults);
    };

    model.delete = function (data) {
        return $http.delete(URLS.FETCH + '/' + data.id).then(cacheResults);
    };

    model.search = function (params) {
        return $http.post(URLS.FETCH + '/search', params).then(cacheResults);
    };

    model.batchCreate = function (courses) {
        return $http.post(URLS.CREATE + '/batch', courses).then(cacheResults);
    };
  
    model.getCourseStudents = function(courseId) {
      return $http.get(URLS.FETCH + '/' + courseId + '/students').then(cacheResults);
    }

    model.getCourseClassDateStudents = function(course_id, class_date_id) {
      return $http.get(URLS.FETCH + '/' + course_id + '/classes/' + class_date_id + '/students').then(cacheResults);
    }

    model.addStudentToCourse = function(courseId, studentId, classDates) {
      return $http.post(URLS.CREATE + '/' + courseId + '/students', {student_id: studentId, class_dates: classDates}).then(cacheResults)
    }
    
    model.removeStudentFromCourse = function(courseId, studentId) {
      return $http.delete(URLS.FETCH + '/' + courseId + '/students/' + studentId).then(cacheResults)
    }
    
    model.printRoster = function(data) {
        if (data.class_id) {
            return $http.get(URLS.FETCH + '/' + data.id + '/print?class_id=' + data.class_id).then(cacheResults);   
        }
        return $http.get(URLS.FETCH + '/' + data.id + '/print').then(cacheResults);
    }
    
    model.notifyStudents = function(data) {
        return $http.post(URLS.FETCH + '/' + data.course_id + '/notify-students', {
            subject: data.subject,
            body: data.body,
            to: data.to
        }).then(cacheResults);
    }

    model.updateRosterRow = (student_id, course_id, received_contract) => {
        console.log("updating roster row", student_id, course_id, received_contract)
        const req = {
            "student_id": student_id,
            "course_id": course_id,
            "received_contract": received_contract === 1 ? "1" : "0"
        };
        return $http.patch(`${URLS.FETCH}/${course_id}/students/${student_id}`, req).then(cacheResults)
    }
    
    model.removeCourseProduct = function(data) {
        return $http.delete(URLS.FETCH + '/' + data.courseId + '/products/' + data.productId).then(cacheResults)
    }

    model.getCourseProducts = function(courseId) {
        return $http.get(URLS.FETCH + '/' + courseId + '/products').then(cacheResults);
    }

    model.getCourseAttendance = function(courseId) {
        return $http.get(URLS.FETCH + '/' + courseId + '/attendance').then(cacheResults) 
    }

    model.getCourseClassAttendance = function(course_id, class_id) {
        return $http.get(URLS.FETCH + '/' + course_id + '/attendance/' + class_id).then(cacheResults) 
    }

    model.updateCourseAttendance = function(data) {
        return $http.put(URLS.UPDATE + '/' + data.courseId + '/attendance', data.students).then(cacheResults) 
    }
    
    model.updateCourseClassAttendance = function(data) {
        return $http.put(URLS.UPDATE + '/' + data.courseId + '/attendance/' + data.class_id, data.students).then(cacheResults) 
    }

    model.getClassDates = function(course_id) {
        return $http.get(URLS.FETCH + '/' + course_id + '/classes').then(cacheResults);
    }

    model.getClassDate = function(course_id, class_id) {
        return $http.get(URLS.FETCH + '/' + course_id + '/classes/' + class_id).then(cacheResults);
    }

    
}
CoursesModel.$inject = ['$http' , 'APPURL'];
export default CoursesModel;
