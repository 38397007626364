import { inDollars } from "../../../../DriveScout/Billing/Teller";
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import { Product } from "../../../classes/Product";

class CouponsController {

    constructor(CouponsModel, ProductsModel, FlashService, $window){

        this.CouponsModel = CouponsModel;
        this.FlashService = FlashService;
        this.inDollars = inDollars.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.loading = false;
        this.$window = $window;
        this.forms = {
            'createCoupon' : {
                coupon : {}
            },
            'editCoupon' : {
                coupon: {},
                product: {}
            }
        };
        this.products = [];

        this.coupons = [];

        this.options = {
            valid: [
                { name: 'Valid', value: 1 },
                { name: 'Invalid', value: 0 }
                //needs to be 0 and 1 instead of true/false for laravel validator on backend
            ]
        }
        this.search = {}

        this.search.valid = 1;

        CouponsModel.get().then(response => {
            this.coupons = response.coupons;
        });

        ProductsModel.get({'paging' : false, 'disabled' : false}).then(response => {
            this.products = response.products.data.map(product => new Product(product));
        });
        
        this.updateCouponStart = this.updateCouponStart.bind(this)
        this.updateCouponEnd = this.updateCouponEnd.bind(this)
        this.searchCoupons = this.searchCoupons.bind(this)

    }

    openModal(coupon) {
        this.forms.editCoupon.coupon = Object.assign(coupon);
        this.modalShown = true;
    }

    closeModal() {
        this.modalShown = false;
        this.forms.editCoupon.coupon = {}; //reset
        this.forms.editCoupon.product = {}; //reset
    }

    addProductToCoupon()
    {
        const coupon = Object.assign(this.forms.editCoupon.coupon);
        const product = Object.assign(this.forms.editCoupon.product.$originalData);

        this.CouponsModel.addProduct(coupon, product).then(response => {
            this.FlashService.setMessage({
                'type' : 'success',
                'message' : response.message
            });
            this.closeModal();
            this.coupons = this.coupons.map(coupon => {
                if (coupon.id === response.coupon.id) {
                    return response.coupon;
                }
                return coupon;
            });
        });
    }

    removeProduct(coupon, product)
    {
        const confirm = this.$window.confirm("Are you sure you want to remove this product from this coupon?");

        if (confirm === false){
            return;
        }

        this.CouponsModel.removeProduct(coupon, product).then(response => {
            this.FlashService.setMessage({
                'type' : 'success',
                'message' : response.message
            });
            this.closeModal();
            this.coupons = this.coupons.map(coupon => {
                if (coupon.id === response.coupon.id) {
                    return response.coupon;
                }
                return coupon;
            });
        });
    }

    createCoupon(){
        this.loading = true;
        let coupon = Object.assign({}, this.forms.createCoupon.coupon)
        if (coupon.type === 'fixed') {
            coupon.amount = coupon.amount * 100;
        }
        
        if (coupon.min_spend && coupon.min_spend > 0) {
            coupon.min_spend = coupon.min_spend * 100;
        } else {
            delete coupon.min_spend;
        }

        if (coupon.max_spend && coupon.max_spend > 0) {
            coupon.max_spend = coupon.max_spend * 100;
        } else {
            delete coupon.max_spend;
        }

        this.CouponsModel.create(coupon).then(response => {
            this.loading = false;
            coupon.valid = true;
            this.coupons.push(response.coupon);
            this.FlashService.setMessage({
                'message' : response.message,
                'type' : 'success'
            })
            this.coupon = {}
        })
    }
    
    updateCouponStart(newDate) {
      this.forms.createCoupon.coupon.start = newDate.selected[0];
    }
    
    updateCouponEnd(newDate) {
      this.forms.createCoupon.coupon.end = newDate.selected[0];
    }

    toggleCouponValid(coupon){
        this.CouponsModel.update({id: coupon.id, valid: !coupon.valid}).then(response => {
            this.loading = false;
            coupon.valid = !coupon.valid;
            this.FlashService.setMessage({
                'message' : response.message,
                'type': 'success'
            })
        })
    }

    getSearchParams(removeNulls = true) {
        let params = {
            'couponCode': this.search.code ? this.search.code : null,
            'valid': this.search.valid ? this.search.valid.value : null
        }
        if (removeNulls === true) {
            params = omitBy(params, isNil)
        }
        return params;
    }

    searchCoupons() {
        this.loading = true;

        this.CouponsModel.get(this.getSearchParams()).then((response) => {
            this.coupons = response.coupons;
            this.loading = false;
        })
    }

}

CouponsController.$inject = ['CouponsModel', 'ProductsModel', 'FlashService', '$window'];
export default CouponsController;