"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createBillingGatewayFromPolicies = exports.BillingGateway = void 0;
var BillingGateway = /** @class */ (function () {
    function BillingGateway(name, config) {
        this.SupportedGateways = ['none', 'authorize', 'paypal', 'stripe'];
        this.$name = name;
        this.$config = config;
        this.validate();
    }
    BillingGateway.prototype.config = function () {
        return this.$config;
    };
    BillingGateway.prototype.validate = function () {
        if (this.SupportedGateways.includes(this.$name) === false) {
            throw new Error("Billing gateway ".concat(this.$name, " is not supported"));
        }
        return true;
    };
    BillingGateway.prototype.validateConfig = function () {
        if (this.$name === 'none') {
            return true;
        }
        if (this.$name === 'authorize') {
            // TODO: If no trans id or trans key provided, error
            return true;
        }
        if (this.$name === 'paypal') {
            // TODO: If no trans id or trans key provided, error
            /*
            if (this.gateway === 'paypal') {
                PayPalService.getClientToken().then(client_token => {
                    PayPalService.init(this.gateway_config.paypal_rest_api_client_id, client_token);
                    PayPalService.onApprove = this.handlePayPalPayment;
                    PayPalService.validateForm = OrdersModel.validateOrderForm;
                    PayPalService.getFormData = () => {
                        let order = Object.assign({}, this.order);
                        order.payments = order.payments.map(payment => {
                            payment.billing_address = payment._billing_address;
                            return payment;
                        })
                        return order;
                    }
                    $scope.$watch(() => this.order.payments, (oldVal, newVal) => {
                        this.calculateTotal();
                        PayPalService.makePurchaseUnits({
                            'products': this.order.products,
                            'paidToday': this.paidToday,
                            'surchargeAmountBilled': this.surchargeAmountBilled,
                            'surchargeBilledSeparately': this.surchargeBilledSeparately
                        });
                    }, true);
                })
            }
            */
            return true;
        }
        return true;
    };
    return BillingGateway;
}());
exports.BillingGateway = BillingGateway;
function createBillingGatewayFromPolicies(policies) {
    if (policies.policyExists('billing') === false) {
        throw new Error('No billing policy provided.');
    }
    var policy = policies.getPolicyByName('billing');
    var gateway = 'none';
    if (policy.metaExistsByKey('billing_gateway')) {
        gateway = policy.getMetaValueByKey('billing_gateway').toLowerCase();
    }
    if (gateway === 'stripe') {
        var stripe_publishable_key = null;
        var stripe_secret_key = null;
        try {
            stripe_publishable_key = policy.getMetaValueByKey('stripe_publishable_key');
        }
        catch (Error) {
            stripe_publishable_key = null;
        }
        try {
            stripe_secret_key = policy.getMetaValueByKey('stripe_secret_key');
        }
        catch (Error) {
            stripe_secret_key = null;
        }
        var config = {
            stripe_secret_key: stripe_secret_key,
            stripe_publishable_key: stripe_publishable_key,
        };
        return new BillingGateway(gateway, config);
    }
    if (gateway === 'authorize') {
        var authorize_api_client_key = null;
        try {
            authorize_api_client_key = policy.getMetaValueByKey('authorize_client_key');
        }
        catch (Error) {
            authorize_api_client_key = null;
        }
        var authorize_api_trans_key = null;
        try {
            authorize_api_trans_key = policy.getMetaValueByKey('authorize_api_trans_key');
        }
        catch (Error) {
            authorize_api_trans_key = null;
        }
        var authorize_api_login_id = null;
        try {
            authorize_api_login_id = policy.getMetaValueByKey('authorize_api_login_id');
        }
        catch (Error) {
            authorize_api_login_id = null;
        }
        var config = {
            authorize_api_login_id: authorize_api_login_id,
            authorize_api_trans_key: authorize_api_trans_key,
            authorize_api_client_key: authorize_api_client_key,
        };
        return new BillingGateway(gateway, config);
    }
    if (gateway === 'paypal') {
        var config = {
            paypal_rest_api_client_id: policy.getMetaValueByKey('paypal_rest_api_client_id')
        };
        return new BillingGateway(gateway, config);
    }
    return new BillingGateway("none", {});
}
exports.createBillingGatewayFromPolicies = createBillingGatewayFromPolicies;
