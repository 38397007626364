"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var OnlineDriversEdSettingsController = /** @class */ (function () {
    function OnlineDriversEdSettingsController($rootScope, $state, OnlineDriversEd, PoliciesModel, FlashService) {
        var _this = this;
        this.$rootScope = $rootScope;
        this.$state = $state;
        this.OnlineDriversEd = OnlineDriversEd;
        this.PoliciesModel = PoliciesModel;
        this.FlashService = FlashService;
        this.policies = {
            'onlinedriversed': {
                'id': 0,
                'policymeta': {
                    "success_message": ""
                }
            }
        };
        this.handleSuccessMessageChange = this.handleSuccessMessageChange.bind(this);
        PoliciesModel.getByID('onlinedriversed').then(function (response) {
            _this.policies.onlinedriversed.id = response.policy.id;
            var metaData = response.policy.policymeta.find(function (meta) {
                return meta.meta_key === "success_message";
            });
            console.log(metaData);
            _this.policies.onlinedriversed.policymeta = {
                "success_message": metaData.meta_value || ""
            };
            _this.busy = false;
            _this.loading = false;
        });
    }
    OnlineDriversEdSettingsController.prototype.handleSuccessMessageChange = function (data) {
        this.policies.onlinedriversed.policymeta.success_message = data.html;
    };
    OnlineDriversEdSettingsController.prototype.processPolicy = function () {
        var _this = this;
        this.busy = true;
        this.PoliciesModel.update({
            "id": this.policies.onlinedriversed.id,
            "success_message": this.policies.onlinedriversed.policymeta.success_message
        }).then(function (response) {
            _this.FlashService.setMessage({ 'type': 'success', 'message': response.message });
            _this.busy = false;
        }).catch(function (Error) {
            _this.busy = false;
        });
    };
    return OnlineDriversEdSettingsController;
}());
exports.default = OnlineDriversEdSettingsController;
OnlineDriversEdSettingsController.$inject = ["$rootScope", "$state", "OnlineDriversEd", "PoliciesModel", "FlashService"];
