import moment from "moment";

class PendingReportCardsController {
  constructor(
    UserService,
    $state,
    $stateParams,
    UsersModel,
    DrivesModel,
    ReportcardsModel
  ) {
    this.$stateParams = $stateParams;
    this.$state = $state;
    this.UsersModel = UsersModel;
    this.DrivesModel = DrivesModel;
    this.ReportcardsModel = ReportcardsModel;
    this.drives = [];
    this.instructor = UserService.getCurrentUser();
    this.loading = true;
    this.page_by = 20;
    this.currentPage = 1;
    this.totalItems = 0;

    UsersModel.getInstructorPendingReportCards(this.instructor.id, {
      page_by: this.page_by,
      page: this.currentPage,
    }).then((response) => {
      this.totalItems = response.drives.total;
      this.currentPage = response.drives.current_page;
      this.drives = response.drives.data.map((drive) => {
        let start = drive.drive_date + " " + drive.start_time;
        drive.start = moment(start, "YYYY-MM-DD HH:mm:ss").format(
          "MM/DD/YYYY HH:mm a"
        );
        return drive;
      });
      this.loading = false;
    });
  }

  pageChanged() {
    // this.loading = true;
    this.UsersModel.getInstructorPendingReportCards(this.instructor.id, {
      page_by: this.page_by,
      page: this.currentPage,
    }).then((response) => {
        this.totalItems = response.drives.total;
        this.currentPage = response.drives.current_page;
        this.drives = response.drives.data.map((drive) => {
          let start = drive.drive_date + " " + drive.start_time;
          drive.start = moment(start, "YYYY-MM-DD HH:mm:ss").format(
            "MM/DD/YYYY HH:mm a"
          );
          return drive;
        });
        // this.loading = false;
    });
  }

  gradeReportCard(drive) {
    if (drive.type.reportcardtype.name === "Pass/Fail") {
      this.$state.go(
        "drivecoach.instructor.dashboard.createpassfailreportcard",
        { driveId: drive.id, studentId: drive.student_id }
      );
    }

    if (drive.type.reportcardtype.name === "Graded") {
      this.$state.go("drivecoach.instructor.dashboard.createreportcard", {
        driveId: drive.id,
        studentId: drive.student_id,
      });
    }

    if (drive.type.reportcardtype.name === "Standard") {
      this.$state.go("drivecoach.instructor.dashboard.gradegenericreportcard", {
        driveId: drive.id,
        studentId: drive.student_id,
      });
    }
  }
}
PendingReportCardsController.$inject = [
  "UserService",
  "$state",
  "$stateParams",
  "UsersModel",
  "DrivesModel",
  "ReportcardsModel",
];
export default PendingReportCardsController;
