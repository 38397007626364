import angular from 'angular'
import Vue from 'vue';
import 'ngVue';

import VueCardFormat from 'vue-credit-card-validation';
Vue.use(VueCardFormat);

import dcOrderDetail from './OrderDetail.vue'

export default angular.module( 'directives.modal', [] )
  .directive('dcOrderDetail', ['createVueComponent', function (createVueComponent) {
    return createVueComponent(Vue.component('dcOrderDetail', dcOrderDetail))
  }])
