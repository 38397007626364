import angular from 'angular'
import StudentDrivesIndexController from './student-drives-index.controller'
import template from './student-drives-index.html'
function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.student.drives.index', {
            'url' : '/index/:date',
            'controller' : StudentDrivesIndexController,
            'controllerAs' : 'vm',
            'template' : template,
            data : {
                authorizedRoles : [
                    USER_ROLES.student,
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner
                ]
            }
        } )
    ;
}

config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];
export default angular.module( 'student.drives.index', [] )
    .config( config )
    .controller( StudentDrivesIndexController )
;
