import angular from "angular";
import template from "./admin-edit-student.html";
import AdminStudentEditController from "./admin-edit-student.controller";

function getSessionTypes(DriveSessionTypesModel) {
  return DriveSessionTypesModel.get().then((response) => {
    return response.sessionTypes.filter((type) => {
      return type.status === "Active";
    });
  });
}
getSessionTypes.$inject = ["DriveSessionTypesModel"];

function getCourseTypes(CourseTypesModel) {
  return CourseTypesModel.get().then((response) => {
    return response.courseTypes.filter((type) => {
      return type.status === "Active";
    });
  });
}
getCourseTypes.$inject = ["CourseTypesModel"];

function config($stateProvider, $urlRouterProvider, USER_ROLES) {
  $stateProvider.state("drivecoach.admin.students.edit", {
    url: "/edit/:studentId",
    controller: AdminStudentEditController,
    controllerAs: "vm",
    template: template,
    resolve: {
      sessiontypes: getSessionTypes,
      coursetypes: getCourseTypes,
    },
    data: {
      authorizedRoles: [
        USER_ROLES.admin,
        USER_ROLES.administrator,
        USER_ROLES.owner,
        USER_ROLES.staff,
      ],
    },
  });
}
config.$inject = ["$stateProvider", "$urlRouterProvider", "USER_ROLES"];

export default angular
  .module("admin.dashboard.students.edit", [])
  .config(config)
  .controller("AdminStudentEditController", AdminStudentEditController);
