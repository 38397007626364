<template>
  <section class="container-fluid">
    <div class="row">
      <div class="col-xs-12">
        <form @submit.prevent="submit()">
          <div ref="payment"></div>
          <div class="text-center">
            <button class="btn btn-dc" style="margin-top: 2em;">Submit</button>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import { loadStripe } from "@stripe/stripe-js";
import { Config } from "../../../../DriveScout/Config.js";

let stripe;
let elements;

export default {
  props: {
    clientSecret: { type: String, default: "" },
    publishableKey: { type: String, default: "" },
    returnUrl: { type: String, default: "" },
    beforeSubmit: { type: Function, async default() {
      return {}
    } },
    elementOptions: { type: Object, default: function(){
      return {
            wallets: {
                googlePay: 'never',
                applePay: 'never',
            },
        }
    } },
  },
  data() {
    return {
      pk: Config.STRIPE_PUBLISHABLE_KEY,
      elementsOptions: {
        appearance: {}, // appearance options
      },
      confirmParams: {
        return_url: "", // success url
      },
    };
  },
  async mounted() {
    this.elementsOptions.clientSecret = this.clientSecret;
    this.confirmParams.return_url = this.returnUrl;
    let pk = this.pk;
    if (this.publishableKey !== "") {
      pk = this.publishableKey;
    }
    stripe = await loadStripe(pk);
    const clientSecret = this.clientSecret;

    elements = stripe.elements({ clientSecret });
    const paymentElement = elements.create("payment", this.elementOptions);
    paymentElement.mount(this.$refs.payment);
  },
  methods: {
    async submit() {

      const response = await this.beforeSubmit();

      const { error } = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: this.returnUrl
        }
      });

      if (error.type === "card_error" || error.type === "validation_error") {
        // messages.value.push(error.message);
        console.log(error);
      } else {
        messages.value.push("An unexpected error occured.");
      }

    },
    tokenCreated(token) {
      this.$emit("stripeCardTokenCreated", token);
    },
    pay() {
      this.$refs.paymentRef.submit();
    },
  },
};
</script>
