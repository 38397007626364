import {States} from "../../../../../../common/constants/states";

class AdminStudentCreateHighschoolController {

    constructor($state, $stateParams, UsersModel, FlashService, SchoolsModel){
        this.submitted = false;
        this.hasPickupLocation = false;
        this.states = States;
        this.formData = {
            'student_id' : $stateParams.studentid
        }

        this.$state = $state;
        this.$stateParams = $stateParams;
        this.UsersModel = UsersModel;
        this.FlashService = FlashService;
        this.SchoolsModel = SchoolsModel;

        UsersModel.getPickupLocations( $stateParams.studentId ).then(response => {
            this.state = response.pickuplocations[0].address.state;
        });
    }

    hasHighschool() {
        return typeof this.formData.highschool !== 'undefined';
    }

    searchHighSchools() {
        this.highschools = [];
        this.loadingSchools = true;

        let data = {
            'name': this.highSchoolName,
            'state': this.state
        };

        this.SchoolsModel.getHighSchools(data).then(response => {
            this.loadingSchools = false;
            this.highschools = response;
        })

    }

    selectHighschool() {
        this.submitted = true;
        this.formData.pickuplocation = {
            'name': this.selectedHighSchool.name,
            'street': this.selectedHighSchool.street,
            'city': this.selectedHighSchool.city,
            'state': this.selectedHighSchool.state,
            'zip': this.selectedHighSchool.zip,
            'lat': this.selectedHighSchool.lat,
            'lng': this.selectedHighSchool.lng
        };
        this.hasPickupLocation = true;
        this.submitPickupLocation()
    }

    submitPickupLocation() {
        this.submitted = true;
        this.formData.student_id = this.$stateParams.studentId;
    //   console.log(this.formData)
        this.UsersModel.addPickupLocation(this.formData).then(response => {
          let type = 'success';
          if(response.message === 'Your pick up location was not in the school\'s service area. Your pick up location is the driving school.') { type = 'info' } 
          this.FlashService.setMessage({
              'type': type,
              'message': response.message
          });
          
          if (type === 'info') {
            //dont change state until info alert is closed
            this.$scope.$on('MESSAGE_CLEARED', () => {
              this.submitted = false;
              this.$state.go('drivecoach.admin.students.pickuplocations', {'studentId': this.formData.student_id}, {'reload': true});
            })
          } else {
            this.submitted = false;
            this.$state.go('drivecoach.admin.students.pickuplocations', {'studentId': this.formData.student_id}, {'reload': true});
          }
        });
    }
}
AdminStudentCreateHighschoolController.$inject = ['$state', '$stateParams', 'UsersModel', 'FlashService', 'SchoolsModel'];
export default AdminStudentCreateHighschoolController;