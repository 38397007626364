import angular from 'angular'
function InstructorDrivesController( $stateParams, DrivesModel ) {

    const vm = this;
    vm.date = $stateParams.date;

    init();

    // View drives by date
    function init(){
        DrivesModel.getDrivesByDate( $stateParams.date ).then( response => {
            if ( ! angular.isArray( response ) ) {
                response = [response];
            }
            vm.drives = response;
        } );
    }

}
InstructorDrivesController.$inject = [ '$stateParams', 'DrivesModel'];
export default InstructorDrivesController;