import moment from 'moment'
import {isJson} from "../../../common/helpers/json";
import {hours_list} from '../../../common/constants/hours_list'
import {minutes_list} from '../../../common/constants/minutes_list'

class AdminDrivesCreateController {

    constructor(
        $rootScope, 
        $scope, 
        $state, 
        UserService, 
        UsersModel, 
        VehiclesModel, 
        DrivesModel, 
        SchoolsModel, 
        FlashService, 
        BatchCreateService, 
        driveSession, 
        PoliciesModel, 
        $q,
        instructors,
        vehicles,
        sessionTypes){

        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this.$state = $state;
        this.UserService = UserService;
        this.UsersModel = UsersModel;
        this.VehiclesModel = VehiclesModel;
        this.DrivesModel = DrivesModel;
        this.FlashService = FlashService;
        this.BatchCreateService = BatchCreateService;
        driveSession.vehicle = vehicles[0];
        driveSession.instructor = instructors[0];
        this.driveSession = driveSession;
        this.instructors = instructors;
        this.vehicles = vehicles;
        this.sessiontypes = sessionTypes;
        this.driveSession.type = this.sessiontypes[0];
        if (this.driveSession.type.hidden === true) {
            this.driveSession.private = 'Y';
        }
      
        BatchCreateService.setDrive(this.driveSession);

        this.hasSectors = false;
        this.sectorsRequired = true;
        this.driveSessionMap = {
            location:  {
                'address': {
                    street: '',
                    city: '',
                    state: '',
                    zip: ''
                },
                'zones': []
            }
        };
        this.zones = [];
        this.sectors = [];
        this.policies = {};
        this.batchDupeDrive = 'N';
        this.overrideInstructorSettings = 'N';
        this.hoursList = hours_list;
        this.minutesList = minutes_list;
        this.currentUser = UserService.getCurrentUser();
        this.loading = true;
        this.use_service_area = false;
        this.allow_students_to_schedule = false;
      
        this.refreshDriveSessionVariableData = this.refreshDriveSessionVariableData.bind(this);
        this.handleDatepickerChange = this.handleDatepickerChange.bind(this);

        $rootScope.$on('MESSAGE_CLEARED', () => {
            this.submitted = false;
        });

        // load all of the things!

        const getSchool = SchoolsModel.getSchoolByID(this.currentUser.school_id);
        const getPolicies = PoliciesModel.get(); 
        const getUser = UsersModel.getUserByID(this.currentUser.id);

        $q.all([getSchool,getPolicies, getUser]).then(responses => {
            this.formatSchoolResponse(responses[0]);
            this.formatPoliciesResponse(responses[1]);
            this.handleInitialUserResponse(responses[2]);

            if (this.use_service_area === true) {
                const locations = responses[0].locations = responses[0].locations.filter(location => {
                    return location.zoneparts.length > 0;
                });
                this.driveSession.location = locations[0];
            }
        })

    }
  
    handleInitialUserResponse(response) {
        this.user = response;

        this.loading = false;
    }

    formatPoliciesResponse(response) {
        response.policies.forEach(policy => {
            let policyName = policy.name;
            this.policies[policyName] = {'id': policy.id};
            let policyMeta = {};
            policy.policymeta.forEach(meta => {
                policyMeta[meta.meta_key] = isJson(meta.meta_value) ? JSON.parse(meta.meta_value) : meta.meta_value;
            });
            this.policies[policyName].policymeta = policyMeta;
        });

        if (this.policies.drives.policymeta.minimum_session_length) {
            let _durMoment = [];
            if (this.policies.drives.policymeta.minimum_session_length < 60) {
                    _durMoment = [0, parseInt(this.policies.drives.policymeta.minimum_session_length)];
            }else{
                _durMoment = moment.duration(this.policies.drives.policymeta.minimum_session_length, 'minutes').format('H:mm').split(':');
            }
            this.driveSession.length.hours = parseInt(_durMoment[0]);
            this.driveSession.length.minutes = _durMoment[1].toString();
        }
        this.BatchCreateService.setDrive(this.driveSession);

        if (this.policies.drives.policymeta.use_service_area === "Y") {
            this.use_service_area = true;
        } else {
            this.use_service_area = false;
        }

        if (this.policies.drives.policymeta.allow_students_to_schedule === true){
            this.allow_students_to_schedule = true;
        } else {
            this.allow_students_to_schedule = false;
        }

        setTimeout(() => {
            this.hasSectors = false;
        }, 300)
    }
    
    formatSchoolResponse(response) {
        this.driveSession.location = response.locations[0];
        this.school = response;

        this.zones = [].concat(...this.driveSession.location.zones).map(zone => {
            return {
                id: zone.id,
                name: zone.name
            }
        });
        this.sectors = [].concat(...this.driveSession.location.zoneparts).map(sector => {
            return  {
                id: sector.id,
                name: sector.name,
                zone_id: sector.zone_id
            }
        });

        this.zones.forEach((zone, index) => {
            this.driveSession.zones[index] = zone;
        });

        this.sectors.forEach((sector, index) => {
            this.driveSession.sectors[index] = this.sectors[index];
        });

        this.center = {
            lat: this.school.address.lat,
            lng: this.school.address.lng,
            zoom: 12
        };
    }

    zoneSelected(zone) {
        // get array of selected zone ids
        const zoneSelected = this.driveSession.zones.filter(z => {
            if (z === null) {
                return false;
            }
            return z.id === zone.id;
        }).length > 0;
        if (zoneSelected){
            // add all sectors of zone
            this.sectors.forEach((sector, index) => {
                if (sector.zone_id === zone.id) {
                    this.driveSession.sectors[index] = sector;
                }
            });
        } else {
            // remove all sectors of zone
            this.sectors.forEach((sector, index) => {
                if (sector.zone_id === zone.id) {
                    delete this.driveSession.sectors[index];
                }
            });
        }
    }

    sectorSelected(sector){

        // is our sector selected?
        const sectorSelected = this.driveSession.sectors.filter(s => {
            if (s === null) {
                return false;
            }
            return s.id === sector.id;
        }).length > 0;

        if (sectorSelected){
            // add the sector's zone
            this.zones.forEach((zone, index) => {
                if (sector.zone_id === zone.id) {
                    this.driveSession.zones[index] = zone;
                }
            });

        } else {
            // remove the sector's zone
            this.zones.forEach((zone, index) => {
                // are there no selected sectors in this zone?
                const zoneSelected = this.driveSession.sectors.filter(sector => {
                    if (sector === null) {
                        return false;
                    }
                    return sector.zone_id === zone.id;
                }).length > 0;

                // if not deselect the zone
                if (zoneSelected === false) {
                    delete this.driveSession.zones[index];
                }
            });

        }
    }

    setDataOnDriveService() {
        this.BatchCreateService.setDrive(this.driveSession);
    }

    updateSessionTypeDefault() {
        if (this.driveSession.type.hidden === true) {
            this.driveSession.private = 'Y';
        } else {
            this.driveSession.private = 'N';
        }

        this.sectorsRequired = (this.driveSession.type.allow_custom_pickup_locations === true || this.driveSession.type.allow_custom_dropoff_locations === true) && this.driveSession.observation === false;
    }

    noteLengthValid() {
        if (typeof this.driveSession.notes === 'undefined') {
            return true;
        }
        return this.driveSession.notes.length <= 500;
    }

    refreshDriveSessionVariableData(newValues, oldValues, scope) {
        this.sectorsRequired = (this.driveSession.type.allow_custom_pickup_locations === true || this.driveSession.type.allow_custom_dropoff_locations === true) && this.driveSession.observation === false;
        //this.clearForm();
        this.BatchCreateService.setDrive(this.driveSession);
    }

    clearForm() {
        this.driveSession.instructor = null;
        this.driveSession.sectors = [];
        this.driveSession.vehicle = null;
        this.hasSectors = false;
    }

    mustSelectSectors(){
        let hasOnlyOneSector = this.sectors.length === 1;
        let isObservation = this.driveSession.observation === true;
        let allowsCustomDropoffsOrPickups = this.driveSession.type.allow_custom_dropoff_locations === true || this.driveSession.type.allow_custom_pickup_locations === true;
        let mustSelectSectors =  ! hasOnlyOneSector || ! isObservation || allowsCustomDropoffsOrPickups;
        return mustSelectSectors;
    }

    updateSessionForm() {
        let location = this.driveSession.location;
        let mapLocation = location;
        this.hasSectors = false;
        this.zones = [].concat(...this.driveSession.location.zones);
        this.zones.forEach((zone, index) => {
            this.driveSession.zones[index] = zone;
        });
        this.sectors = [].concat(...this.driveSession.location.zoneparts);
        
        this.sectors.forEach((sector, index) => {
            this.driveSession.sectors[index] = this.sectors[index];
        })
        
        mapLocation.address = isJson(mapLocation.address) ? JSON.parse(mapLocation.address) : mapLocation.address;
        this.driveSessionMap.location = {
            'address': mapLocation.address,
            'zones': mapLocation.zones
        };
    }

    checkSelect() {
        if (this.sectorsRequired === false || this.sectors.length === 1 || this.mustSelectSectors() === false) {
            this.hasSectors = true;
            return true;
        }
        this.hasSectors = false;
        if (typeof this.driveSession.sectors === 'undefined') {
            return false;
        }
        this.driveSession.sectors.forEach(sector => {
            if (sector && sector !== false) {
                this.hasSectors = true;
            }
        });
        return this.hasSectors;
    }

    updateSessionDate(newDate){
      this.driveSession.date = newDate.selected[0]
    }
  
    updateSessionTime(newTime) {
      this.driveSession.time = newTime.selected[0]
    }
  
    handleDatepickerChange(newDatetime) {
      if(newDatetime.pickerType === 'time') {
        this.updateSessionTime(newDatetime)
      } else if(newDatetime.pickerType === 'date') {
        this.updateSessionDate(newDatetime)
      }
    }

    processSession(isValid) {
        this.submitted = true;

        if (this.checkSelect() === false && this.use_service_area === true) {
            this.FlashService.setMessage({'type': 'error', 'message': 'You must select at least one sector.'});
            this.submitted = false;
            return false;
        }

        if (isValid) {
            const session = Object.assign({}, this.driveSession);
            if (session.hasOwnProperty('sectors')) {
                session.sectors = session.sectors.filter((sector) => sector !== null).map(sector => {
                    delete sector.geom;
                    return sector;
                });
            }
            if (session.hasOwnProperty('zones')) {
                delete session.zones;
            }
            session.location = {'id' : session.location.id};
            session.instructor = {'id' : session.instructor.id};
            session.formatTime = moment(this.driveSession.time).format("HH:mm:ss");
            session.formatDate = moment(this.driveSession.date).format("YYYY-MM-DD");
            session.override_instructor_settings = this.overrideInstructorSettings;
            session.sectors = session.sectors.filter(sector => sector);
            if (session.instructor.id === 0) {
                delete session.instructor;
            }
            if (session.vehicle.id === 0) {
                delete session.vehicle;
            }

            if (this.use_service_area === false) {
                delete session.location;
                delete session.zoneparts;
                delete session.sectors;
                delete session.zones;
            }
            this.DrivesModel.createDrive(session).then(response => {
                this.FlashService.setMessage({'type': 'success', 'message': response.message});
                this.driveSession = {
                    'length' : {},
                    'sectors' : [],
                    'private' : 'N',
                    'observation' : false
                };
                this.submitted = false;
                this.$state.go('drivecoach.admin.calendar', {}, {reload: true});
            }).catch(Error => {
                this.submitted = false;
            });

        } else {
            this.FlashService.setMessage({'type': 'error', 'message': 'Please fill in the required fields.'});
            this.submitted = false;
        }

    }

}

AdminDrivesCreateController.$inject = ['$rootScope', '$scope', '$state', 'UserService', 'UsersModel', 'VehiclesModel', 'DrivesModel', 'SchoolsModel', 'FlashService', 'BatchCreateService', 'driveSession', 'PoliciesModel', '$q', 'instructors', 'vehicles', 'sessionTypes'];
export default AdminDrivesCreateController;
