import angular from 'angular'
import InstructorDriveController from './instructor-view-drive.controller'
import template from './instructor-view-drive.html'
import { isJson } from '../../../common/helpers/json';

function getPolicy(PoliciesModel) {
    return PoliciesModel.getByID('drives').then(data => {
        let policy = data.policy;
        let policyMeta = {};
        policy.policymeta.forEach(meta => {
            policyMeta[meta.meta_key] = isJson(meta.meta_value) ? JSON.parse(meta.meta_value) : meta.meta_value;
        });
        policy.policymeta = policyMeta;

        policy.policymeta.id = policy.id;

        let response = {};

        if (!policyMeta.hasOwnProperty('allow_adhoc_pickup_locations')) {
            response.AdhocPickupLocationPolicyData = {
                'id' : policy.id,
                'FeatureEnabled' : false
            }
        } else {
            response.AdhocPickupLocationPolicyData = {
                'id' : policy.id,
                'FeatureEnabled' : policyMeta.allow_adhoc_pickup_locations === "Y"
            }
        }

        if (!policyMeta.hasOwnProperty('use_report_cards')) {
            response.UseReportCards = {
                'id' : policy.id,
                'FeatureEnabled' : false
            }
        } else {
            response.UseReportCards = {
                'id' : policy.id,
                'FeatureEnabled' : policyMeta.use_report_cards === "Y"
            }
        }

        if (!policyMeta.hasOwnProperty('use_service_area')) {
            response.UseServiceArea = {
                'id' : policy.id,
                'FeatureEnabled' : false
            }
        } else {
            response.UseServiceArea = {
                'id' : policy.id,
                'FeatureEnabled' : policyMeta.use_service_area === "Y"
            }
        }

        if (!policyMeta.hasOwnProperty('allow_students_to_schedule')) {
            response.StudentsCanBook = {
                'id' : policy.id,
                'FeatureEnabled' : false
            }
        } else {
            response.StudentsCanBook = {
                'id' : policy.id,
                'FeatureEnabled' : policyMeta.allow_students_to_schedule === true
            }
        }
        return response;
    });
}
getPolicy.$inject = ['PoliciesModel'];

function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.instructor.dashboard.drive', {
            'url' : '/drive/:driveId',
            'controller' : 'InstructorDriveController',
            'controllerAs' : 'vm',
            'template' : template,
            'resolve': {
                policyData: getPolicy,
            },
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.owner,
                    USER_ROLES.instructor
                ]
            }
        } )

    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'instructor.dashboard.drive', [] )
    .config(config)
    .controller( 'InstructorDriveController', InstructorDriveController )
;
