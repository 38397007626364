import angular from 'angular'
import template from './admin-student-pick-up-locations.html'
import AdminStudentPickupLocationsController from './admin-student-pick-up-locations.controller'
import CreatePickupLocations from './create/admin-create-student-pick-up-location.module'

function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.admin.students.pickuplocations', {
            'url' : '/pickup-locations/:studentId',
            'controller' : AdminStudentPickupLocationsController,
            'controllerAs' : 'vm',
            'template' : template,
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner,
                    USER_ROLES.staff
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'admin.dashboard.students.pickuplocations', [
        CreatePickupLocations.name
        ] ).config(config)
        .controller( 'AdminStudentPickupLocationsController', AdminStudentPickupLocationsController )
    ;
