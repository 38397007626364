"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var states_1 = require("../../../../../common/constants/states");
var saveToDisk_1 = require("../../../../../common/helpers/saveToDisk");
var AdminStudentFilesController = /** @class */ (function () {
    function AdminStudentFilesController($state, $stateParams, UsersModel, FlashService, FilesModel) {
        var _this = this;
        this.initialLoad = true;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.states = states_1.States;
        this.UsersModel = UsersModel;
        this.FlashService = FlashService;
        this.submitted = false;
        this.FilesModel = FilesModel;
        this.files = [];
        UsersModel.getFiles(this.$stateParams.userId).then(function (response) {
            _this.files = response.files;
        });
    }
    AdminStudentFilesController.prototype.formatDate = function (datestring) {
        return new Date(datestring).toLocaleDateString("en-US", {
            day: "2-digit", "month": "2-digit", "year": "numeric"
        });
    };
    AdminStudentFilesController.prototype.deleteFile = function (id) {
        var _this = this;
        this.FilesModel.destroy(id).then(function (response) {
            _this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
            _this.files = _this.files.filter(function (file) {
                return file.id !== id;
            });
        }).catch(function (Error) {
        });
    };
    AdminStudentFilesController.prototype.downloadFile = function (id) {
        var _this = this;
        this.FilesModel.get(id).then(function (response) {
            _this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
            (0, saveToDisk_1.saveToDisk)(response.temporary_url);
        }).catch(function (Error) {
        });
    };
    return AdminStudentFilesController;
}());
AdminStudentFilesController.$inject = ['$state', '$stateParams', 'UsersModel', 'FlashService', 'FilesModel'];
exports.default = AdminStudentFilesController;
