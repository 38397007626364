import moment from 'moment';
import { cloneDeep } from 'lodash';

class LeadsController {
  constructor(LeadsModel, FlashService, CreateStudentService, $state) {
    this.loading = true;
    this.submitting = false;
    this.currentPage = 0;
    this.LeadsModel = LeadsModel;
    this.leads = [];

    this.patch = this.patch.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.submit = this.submit.bind(this)
    this.handleError = this.handleError.bind(this)

    this.FlashService = FlashService;
    this.CreateStudentService = CreateStudentService;
    this.$state = $state;
    this.leadsFilter = {'status' : 'open'}
    this.lead = {}; //used for create lead form
    this.editLead = {}; //used for edit lead form
    this.modalShown = false;

    LeadsModel.get(this.leadsFilter).then(response => {
      this.leads = response.leads.data.filter(lead => {
        lead.created_at = moment(lead.created_at).format('MM/DD/YYYY h:mm a');
        return lead;
      });
      this.loading = false;
      this.totalItems = response.leads.total;
    });

    
  }

  filterBy(status='open') {
      this.leadsFilter.status = status;
      this.getLeads(this.leadsFilter,...{'page': this.currentPage});
  }

  createStudent(lead){
      let student = {
          'firstname' : lead.firstname,
          'lastname' : lead.lastname,
          'phone' : lead.phone,
          'email' : lead.email
      }
      this.CreateStudentService.set('student', student);
      this.$state.go('drivecoach.admin.students.create');
  }

  handleError(error) {
    this.submitting = false;
  }

  getLeads(){
    let params = Object.assign({}, this.leadsFilter);
    if (this.currentPage > 1) {
      params.page = this.currentPage
    }

    this.LeadsModel.get(params).then(response => {
        this.leads = response.leads.data.filter(this.formatLead);
        this.submitting = false;
    }).catch(this.handleError);
  }
  
  archiveLead(lead){
      this.submitting = true;
    lead.status = 'archived';
    this.LeadsModel.update(lead).then(response => {
        this.FlashService.setMessage({
            'type' : 'success',
            'message' : response.message
        })
        this.getLeads();
    }).catch(this.handleError);
  }

  deleteLead(lead){
      this.submitting = true;
    this.LeadsModel.delete(lead).then(response => {
        this.FlashService.setMessage({
            'type' : 'success',
            'message' : response.message
        })
        this.getLeads();
    }).catch(this.handleError);
  }

  formatLead(lead){
    lead.created_at = moment(lead.created_at).format('MM/DD/YYYY h:mm a');
    return lead;
  }

  createLead(){
      this.submitting = true;
    this.LeadsModel.create(this.lead).then(response => {
        this.FlashService.setMessage({
            'type' : 'success',
            'message' : response.message
        })
        this.getLeads();
        this.lead = {};
    }).catch(this.handleError);
  }

  patch(updatedLead) {
    const leads = this.leads.map(lead => {
      if (lead.id === updatedLead.id) {
        return updatedLead;
      }
      return lead;
    });
    this.leads = leads;
  }

  pageChanged() {
    this.submitting = true;
    const params = {
      page: this.currentPage,
          ...this.leadsFilter
    };
    this.LeadsModel.get(params).then(response => {
      this.leads = response.leads.data.filter(this.formatLead);
      this.submitting = false;
    });
  }

  openModal(lead) {
    this.editLead = cloneDeep(lead);
    this.modalShown = true;
    console.log(this.modalShown)
  }

  closeModal() {
    this.modalShown = false;
    this.editLead = {} //reset
  }

  submit(lead) {
    //submit for edit lead modal
    this.submitting = true;
    this.LeadsModel.update(lead).then(response => {
      this.FlashService.setMessage({
          'type' : 'success',
          'message' : response.message
      })
      //following 3 lines update leads with edited lead
      this.patch(lead)
      const params = { page: this.currentPage, ...this.leadsFilter };
      this.getLeads(params);

      this.editLead = {}; //reset
      this.modalShown = false;
    }).catch(error => {})
  }
}
LeadsController.$inject = ['LeadsModel', 'FlashService', 'CreateStudentService', '$state'];
export default LeadsController;
