"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var saveToDisk_1 = require("../../../../common/helpers/saveToDisk");
var InstructorViewCourseController = /** @class */ (function () {
    function InstructorViewCourseController(UserService, $state, UsersModel, course, CoursesModel, FlashService, VueStoreFactory) {
        this.$state = $state;
        this.course = course;
        this.CoursesModel = CoursesModel;
        this.user = {};
        this.printing = false;
        this.loading = true;
        this.currentUserId = UserService.getCurrentUser().id;
        this.FlashService = FlashService;
        this.VueStoreFactory = VueStoreFactory;
        this.UsersModel = UsersModel;
        this.print = this.print.bind(this);
        this.handleLinkClick = this.handleLinkClick.bind(this);
        this.handleSaveRoster = this.handleSaveRoster.bind(this);
        this.getStudents = this.getStudents.bind(this);
        this.handleSendEmail = this.handleSendEmail.bind(this);
        this.setCourseClassDate = this.setCourseClassDate.bind(this);
        if (this.VueStoreFactory.state.viewingCourse.class_id === 0) {
            var course_1 = {
                id: this.course.id,
                class_id: this.course.classes[0].id,
                date: new Date(this.course.classes[0].start).toLocaleDateString(),
                start: new Date(this.course.classes[0].start).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' }),
                end: new Date(this.course.classes[0].end).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' }),
                instructor: this.course.classes[0].instructor,
                instructor_id: this.course.classes[0].instructor_id
            };
            this.VueStoreFactory.dispatch('SET_VIEW_COURSE', course_1);
        }
        this.user = this.setup();
    }
    InstructorViewCourseController.prototype.setup = function () {
        return __awaiter(this, void 0, void 0, function () {
            var students, user;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getStudents(this.course.id)];
                    case 1:
                        students = _a.sent();
                        return [4 /*yield*/, this.UsersModel.getUserByID(this.currentUserId)];
                    case 2:
                        user = _a.sent();
                        return [2 /*return*/, user];
                }
            });
        });
    };
    InstructorViewCourseController.prototype.print = function () {
        var _this = this;
        this.printing = true;
        this.CoursesModel.printRoster({ 'id': this.course.id, 'class_id': this.VueStoreFactory.state.viewingCourse.class_id }).then(function (response) {
            _this.printing = false;
            (0, saveToDisk_1.saveToDisk)(response.file.url, response.file.name);
        });
    };
    InstructorViewCourseController.prototype.handleLinkClick = function (data) {
        this.$state.go(data.destination, data.params);
    };
    InstructorViewCourseController.prototype.handleSaveRoster = function (data) {
        var _this = this;
        this.CoursesModel.updateRosterRow(data.student_id, data.course_id, data.received_contract).then(function (response) {
            _this.getStudents(_this.course.id);
            _this.FlashService.setMessage({ 'type': 'success', 'message': response.message });
            _this.submitted = false;
        }).catch(function (error) {
            console.log('error', error);
            _this.submitted = false;
        });
    };
    InstructorViewCourseController.prototype.getStudents = function (courseId) {
        var _this = this;
        return this.CoursesModel.getCourseClassDateStudents(courseId, this.VueStoreFactory.state.viewingCourse.class_id).then(function (response) {
            _this.course.students = response.students;
            _this.loading = false;
        }).catch(function (error) {
            console.log('error', error);
            _this.loading = false;
        });
    };
    InstructorViewCourseController.prototype.setCourseClassDate = function (classDate) {
        var _this = this;
        return this.CoursesModel.getCourseClassDateStudents(this.course.id, classDate.id).then(function (response) {
            _this.course.students = response.students;
            _this.loading = false;
        }).catch(function (Error) {
            console.error(Error);
            _this.loading = false;
        });
    };
    InstructorViewCourseController.prototype.handleSendEmail = function (data) {
        var _this = this;
        this.CoursesModel.notifyStudents(data).then(function (response) {
            _this.emailSent = true;
            _this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
        }).catch(function (Error) {
            console.error(Error);
        });
    };
    return InstructorViewCourseController;
}());
InstructorViewCourseController.$inject = ['UserService', '$state', 'UsersModel', 'course', 'CoursesModel', 'FlashService', 'VueStoreFactory'];
exports.default = InstructorViewCourseController;
