import uniq from 'lodash/uniq'
import {isJson} from "../../../../common/helpers/json";

class AdminInstructorLocationsController {

    constructor($state, $stateParams, UsersModel, FlashService, instructor, school){
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.UsersModel = UsersModel;
        this.FlashService = FlashService;

        this.school = school;
        this.user = instructor;

        this.center = {
            lat: this.school.address.lat,
            lng: this.school.address.lng,
            zoom: 34
        }

        this.instructorId = $stateParams.instructorId;
        this.locations = this.user.locations;
        this.location = this.locations[0];
        this.loading = false;
    }

    getMatchingZones(location){
        const results = [];
        let sectors = location.zones;
        uniq(this.user.zones, 'id').forEach(sector =>  {
            sectors.forEach(s => {
                if ( s.id === sector.id ) {
                    results.push( s );
                }
            } );
        });
        return uniq( results, true );
    }

    setSectorPopover(zone, location){
        this.zone = zone;
        this.sectors = this.getMatchingSectors(zone, location)
    }

    getMatchingSectors(zone, location ) {
        const results = [];
        let sectors = location.zoneparts;
        uniq(this.user.zoneparts, 'id').forEach(sector =>  {
            sectors.forEach(s => {
                if ( s.id === sector.id && s.zone_id === zone.id ) {
                    results.push( s );
                }
            } );
        });
        return uniq( results, true );
    }

    removeLocation( id ) {
        const data = {
            'user_id' : this.$stateParams.instructorId,
            'location_id' : id
        };
        this.UsersModel.unattachInstructorLocation( data ).then(response => {
            this.FlashService.setMessage( {
                'type' : 'success',
                'message' : response.message
            });
            this.$state.go(
                'drivecoach.admin.instructors.view', 
                {'instructorId' : this.$stateParams.instructorId}
            );
        } );
    }

}

AdminInstructorLocationsController.$inject = ['$state', '$stateParams', 'UsersModel', 'FlashService', 'instructor', 'school'];
export default AdminInstructorLocationsController;
