import verifiedImage from '../../../assets/images/unverified-students.svg'
import feesImage from '../../../assets/images/student-fees.svg'
import actionRequiredImage from '../../../assets/images/requiring-action.svg'
import openSessionsImage from '../../../assets/images/open-sessions.svg'
import {Task} from "../../classes/Task";
import cloneDeep from 'lodash/cloneDeep'
import moment from'moment'
import { isJson } from '../../common/helpers/json';

class AdminDashboardController {

    constructor($scope, $rootScope, UserService, ReportsModel, CrmTasksModel, CalendarService, DrivesFilterService, CalendarModel, AdminTasksService, FlashService, SchoolsModel, SchoolSetupModel){

        this.CalendarService = CalendarService;
        this.DrivesFilterService = DrivesFilterService;
        this.CalendarModel = CalendarModel;
        this.CrmTasksModel = CrmTasksModel;
        this.AdminTasksService = AdminTasksService;
        this.$rootScope = $rootScope;
        this.FlashService = FlashService;
        this.currentUser = UserService.getCurrentUser();
        this.$scope = $scope;
        this.SchoolsModel = SchoolsModel;
        this.SchoolSetupModel = SchoolSetupModel;

        this.today = new Date;
        this.agendaDate = moment(this.today).format('YYYY-MM-DD');
        this.tasks_last_page = 0;
        this.verifiedImage = verifiedImage;
        this.feesImage = feesImage;
        this.actionRequiredImage = actionRequiredImage;
        this.openSessionsImage = openSessionsImage;
        this.user = UserService.getCurrentUser();
        this.setSchoolName();

        this.handleSetupStepChange = this.handleSetupStepChange.bind(this);
        this.handleSetupSetStep = this.handleSetupSetStep.bind(this);
        this.completeSchoolSetup = this.completeSchoolSetup.bind(this);

        let address = {
            "street" : "",
            "city" : "",
            "state" : "",
            "zip" : ""
        }
        if (isJson(this.user.school.address) && this.user.school.address !== null) {
            address = JSON.parse(this.user.school.address);
        }

        this.setup = {
            loading: true,
            step: "step1",
            owner: {
                firstname : this.user.firstname,
                lastname: this.user.lastname,
                email: this.user.email,
                username: this.user.username,
                phone: "",
            },
            schoolData: {
                school_name: this.user.school.name,
                school_phone: this.user.school.phone,
                school_email: this.user.school.email,
                school_timezone: this.user.school.timezone,
            },
            address : {
                street: address.street,
                city: address.city,
                state: address.state,
                zip: address.zip,
            }
        }

        this.SchoolSetupModel.get().then(response => {
            this.setup.step = response.step;

            this.setup.owner.firstname = response.user_data.firstname;
            this.setup.owner.lastname = response.user_data.lastname;
            this.setup.owner.email = response.user_data.email;
            this.setup.owner.phone = response.user_data.phone;
            this.setup.owner.username = response.user_data.username;

            this.setup.schoolData.school_name = response.school_data.school_name;
            this.setup.schoolData.school_phone = response.school_data.school_phone;
            this.setup.schoolData.school_email = response.school_data.school_email;
            this.setup.schoolData.school_timezone = response.school_data.school_timezone;
            this.setup.schoolData.address = response.school_data.address;

            this.setup.loading = false;
        });

        this.stats = {
            'students_with_fees' : 0,
            'unverified_students': 0,
            'open_sessions' : 0,
            'sessions_requiring_action' : 0
        };

        this.currentPage = 1;
        this.tasks = [];
        ReportsModel.getAdminDashboard().then(response => {
            this.stats = response.data;
        });

        this.AdminTasksService.loadTasks({'complete' : false}).then((response) => {
            this.handleTaskResponse(response);
        });

        $rootScope.$on('TASKS_UPDATED', () => {
            this.AdminTasksService.loadTasks({'complete' : false}).then(response => this.handleResponse(response));
        });

        let initialParams = DrivesFilterService.getFilterParameters();

        if( typeof initialParams.start_date === 'undefined' ){
            //console.log('FilterParams in Object: ' , DrivesFilterService.getFilterParameters() );
            let start_date = moment().startOf('month').format('YYYY-MM-DD')
            let end_date = moment().endOf('month').format('YYYY-MM-DD')
            initialParams = {
                'start_date': start_date,
                'end_date': end_date,
                'status': 'all',
                'statusIsnt': 'Inactive - Scheduling Conflict'
            };
        }

        /**
         * Update the calendar when the items in the service are updated
         */
        $rootScope.$broadcast('CALENDAR_LOADING');

        CalendarModel.filterAdminCalendar(initialParams)
            .then(response => {
                this.CalendarModel.setAgenda(cloneDeep(response), 5);
                let agendaData = this.CalendarModel.getAgendaData();
                if (agendaData.data.length === 0) {
                   this.agendaDate = new Date;
                }else if(agendaData.data[0].hasOwnProperty('drive_date') ){
                   this.agendaDate = agendaData.data[0].drive_date;
                }else{
                    this.agendaDate = agendaData.data[0].date;
                }
            });
    }

    setSchoolName() {
        this.schoolName = this.user.school.name;
    }

    loadMoreTasks(){
        this.AdminTasksService.loadTasks({'page' : this.currentPage + 1, complete: false }).then(response => {
            this.currentPage = response.data.current_page;
            response.data.data.forEach(task => {
                this.AdminTasksService.addTask(new Task(task));
            });
        })
    }

    handleTaskResponse(response) {
        this.tasks_last_page = response.data.last_page;
        this.currentPage = response.data.current_page;
        let tasks = response.data.data.map(task => new Task(task))
        this.AdminTasksService.setTasks(tasks);
        this.tasks = this.AdminTasksService.getTasks();
    }

    handleSetupStepChange(event)
    {
        this.SchoolSetupModel.update({step: event.step, ...event.data}).then(response => {
            this.setup.step = response.step;
        });
    }

    handleSetupSetStep(step){
        this.setup.step = step;
    }

    completeSchoolSetup(data)
    {
        this.SchoolSetupModel.completeSetup().then(response => {
            this.user = response.school.owner;
            delete response.school.owner;
            this.user.school = response.school;
            this.schoolName = response.school.name;
            /*
            this.FlashService.setMessage({
                'message' : response.message,
                'type' : 'succes'
            });
            */
        }).catch(Error => {
            console.log(Error);
        });
    }
}
AdminDashboardController.$inject = ['$scope', '$rootScope', 'UserService', 'ReportsModel', 'CrmTasksModel', 'CalendarService', 'DrivesFilterService', 'CalendarModel', 'AdminTasksService', 'FlashService', 'SchoolsModel', 'SchoolSetupModel'];
export default AdminDashboardController;
