import angular from 'angular';
function run($rootScope, $state, AUTH_EVENTS, AuthService, ENV, DrivesFilterService, UserService, CreateStudentService, FlashService) {
    angular.extend($rootScope, {
        center: {},
        markers: {},
        geojson: {},
        controls: {
            fullscreen: {
                position: 'topleft'
            }
        }
    });

    loadThirdPartyScripts();
    loadUI();
    loadAuth();

    $rootScope.$on('$stateChangeStart', clearCalendarAndAgendaFilterParameters);
    $rootScope.$on('$stateChangeStart', clearCreateStudentService);

    function clearCalendarAndAgendaFilterParameters(event, toState, toParams, fromState, fromParams, options)
    {
        if(toState.name !== 'drivecoach.admin.agenda' &&
            toState.name !== 'drivecoach.admin.calendar' &&
            toState.name !== 'drivecoach.admin.drives.edit' &&
            toState.name !== 'drivecoach.admin.courses.edit' &&
            toState.name !== 'drivecoach.admin.events.edit' ){
            DrivesFilterService.clearFilterParameters();
        }
    }

    function clearCreateStudentService(event, toState, toParams, fromState, fromParams, options)
    {
        if (fromState.name === 'drivecoach.admin.students.create') {
            CreateStudentService.clear();
        }
    }

    //
    // AUTH related things
    //

    function loadAuth(){
        $rootScope.$on('$stateChangeStart', guard);
        watchAuth();
    }

    function guard(event, next) {
        const allowedRoute = allowRoute(next)
        if (allowedRoute === false)
        {
            if (typeof next.data !== 'undefined') {
                var authorizedRoles = next.data.authorizedRoles;
                AuthService.isAuthorized(authorizedRoles).then(response => {
                    // console.log(response);
                }).catch(Error => {
                    event.preventDefault();
                    if (AuthService.isAuthenticated() === false) {
                        // user is not allowed
                        $rootScope.$broadcast(AUTH_EVENTS.notAuthorized);
                    } else {
                        console.error(Error)
                        // user is not logged in
                        $rootScope.$broadcast(AUTH_EVENTS.notAuthenticated);
                    }
                })

            }
        }
    }

    function watchAuth() {
        $rootScope.$on(AUTH_EVENTS.logoutSuccess, logout);

        $rootScope.$on(AUTH_EVENTS.notAuthorized, notAuthorized);
        $rootScope.$on(AUTH_EVENTS.notAuthenticated, () => {
            AuthService.goToLogin();
        });
        $rootScope.$on(AUTH_EVENTS.sessionTimeout, AuthService.goToLogin);
    }

    function notAuthorized() {
        FlashService.setMessage({'type': 'error', 'message': 'You are not authorized to access this page.'})
        AuthService.goToLogin();
    }

    function isStaff(user){
        return user.role.type === 'staff' ||
            user.role.type === 'administrator' ||
            user.role.type === 'owner';
    }

    /**
     * Navigate the user to their respective dashboards upon successfully loading
     * the application
     * @return void
     */
    function navigateToDestination(user) {
        // Do not redirect users if they are on specific routes that don't require
        // authentication

        if (user.role.type === 'student' && ($state.is('drivecoach.student') || allowRoute($state.name))) {
            return $state.go( 'drivecoach.student.dashboard')
        }

        if (user.role.type === 'instructor' && ($state.is('drivecoach.instructor') || allowRoute($state.name))) {
            return $state.go( 'drivecoach.instructor.dashboard')
        }

        if (user.role.type === 'admin' || user.role.type === 'administrator'
            || user.role.type === 'staff') {
            return $state.go('drivecoach.admin.dashboard')
        }
    }

    /**
     *
     * @param {String} route
     * @returns {Boolean}
     */
    function allowRoute(route)
    {
        let allowedRoute = (
            route === 'drivecoach.forgotpassword' ||
            route === 'drivecoach.resetpassword' ||
            route === 'drivecoach.signup.student' ||
            route === 'drivecoach.signup.admin' ||
            route === 'drivecoach.login'
        );

        return allowedRoute === true;
    }


    //
    // UI related things
    //
    let historyStates = [];
    let isGoingBack = false;
    function loadUI(){
        // close sidebar menu on successful state change
        $rootScope.$on('$stateChangeStart', trackHistory);
        $rootScope.$on('$stateChangeSuccess', closeSidebar);
        $rootScope.$on('$stateChangeSuccess', trackHistoryForBackButtonDirective);
        $rootScope.getHistory = getHistory;
        // for back directive in directives.goback and the footernav directives
        $rootScope.previousState;
        $rootScope.currentState;
    }

    function trackHistoryForBackButtonDirective(event, to, toParams, from, fromParams) {
        $rootScope.previousState = {
            'name' : from.name,
            'params' : fromParams
        };
        $rootScope.currentState = {
            'name' : to.name,
            'params' : toParams
        };

        // slidey
        const slidey = document.querySelector('.slidey');
        if (slidey) {
            if (isGoingBack) {
                setTimeout(() => {
                    slidey.classList.add('slide-enter-left');
                    slidey.classList.remove('slide-exit-bottom')
                }, 400);
                setTimeout(() => {
                    slidey.classList.remove('slide-enter-left');
                    slidey.classList.add('slide-enter-active')
                }, 800);
            } else {
                setTimeout(() => {
                    slidey.classList.add('slide-enter-bottom');
                    slidey.classList.remove('slide-exit-left')
                }, 400);
                setTimeout(() => {
                    slidey.classList.remove('slide-enter-bottom');
                    slidey.classList.add('slide-enter-active')
                }, 800);
            }
        }
    }

    function trackHistory(event, next) {
        historyStates.push(next.name);
        if (historyStates.length > 2 && historyStates[historyStates.length - 1] === historyStates[historyStates.length - 3]) {
            isGoingBack = true;
            historyStates.pop();
            historyStates.pop();
        } else if (historyStates.length > 0 && next.name === 'drivecoach.admin.dashboard') {
            isGoingBack = true;
            historyStates = ['drivecoach.admin.dashboard'];
        } else {
            isGoingBack = false
        }

        try {
            let slidey = document.querySelector('.slidey');
            if (isGoingBack) {
                slidey.classList.add('slide-exit-bottom');
            } else {
                slidey.classList.add('slide-exit-left');
            }
        } catch (e) {
        }
    }

    function closeSidebar(event, to, toParams, from, fromParams) {
        const menuContainer = document.getElementById('st-container');
        //classie.remove( menuContainer, 'st-menu-open' );
        angular.element( menuContainer ).removeClass('st-menu-open');
    }

    function getHistory() {
        return {
            length : historyStates.length,
            currentState : historyStates[historyStates.length - 1]
        };
    }

    //
    // Customer Success type stuff
    //

    function loadThirdPartyScripts(){
        $rootScope.$on(AUTH_EVENTS.loginSuccess);
        initAcceptJS();
        $rootScope.$on('$stateChangeSuccess', setIntercomUser);
    }

    function initAcceptJS() {
        if (ENV !== 'production') {
            console.info('Loading Authorize in Test Mode...');
            let src = 'https://jstest.authorize.net/v1/Accept.js';
            var s = document.createElement( 'script' );
            s.setAttribute( 'src', src );
            document.body.appendChild( s );
        } else {
            let src = 'https://js.authorize.net/v1/Accept.js';
            var s = document.createElement( 'script' );
            s.setAttribute( 'src', src );
            document.body.appendChild( s );
        }
    }

    // sets the current intercom user to match the logged in user
    function setIntercomUser(){
        if (AuthService.isAuthenticated() && ENV === 'production') {
            window.Intercom("boot", {
                app_id: "zyog25by"
            });
            if (isStaff(UserService.getCurrentUser())) {
                window.Intercom("update");
            }
        }
    }

    function logout() {
        $state.go('drivecoach.login');
    }

}
run.$inject = ['$rootScope', '$state', 'AUTH_EVENTS', 'AuthService', 'ENV', 'DrivesFilterService', 'UserService','CreateStudentService', 'FlashService'];
export default run;
