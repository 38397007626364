import moment from 'moment'
import uniqBy from 'lodash/uniqBy'
class StudentDrivesViewController {

    constructor($state, UsersModel, driveSession, FlashService, student , pickupLocations, cancellationRules){

        this.$state = $state;
        this.UsersModel = UsersModel;
        this.FlashService = FlashService;
        this.cancellationRules = cancellationRules;

        this.user = student;
        this.driveSession = driveSession;

        this.updatePickupLocation = this.updatePickupLocation.bind(this);
        this.driveIsInThePast = this.driveIsInThePast.bind(this);
        this.parseInt = this.parseInt.bind(this);

        this.studentCanEditPickupLocation = this.driveSession.type.allow_custom_pickup_locations && ! this.driveIsInThePast() && this.driveSession.observation === false;
        this.studentCanEditDropoffLocation = this.driveSession.type.allow_custom_dropoff_locations && ! this.driveIsInThePast() && this.driveSession.observation === false;

        if( this.driveSession.observation === true ){
            this.driveSession.type.name = this.driveSession.type.name + ' (Observation)';
        }

        if( this.driveSession.pickuplocation ) {
            pickupLocations.push( this.driveSession.pickuplocation );
        }
        if( this.driveSession.dropofflocation ) {
            pickupLocations.push( this.driveSession.dropofflocation );
        }

        pickupLocations = uniqBy(pickupLocations, 'id');

        pickupLocations.forEach(location => {
            if( location.id === this.driveSession.pickuplocation.id ){
                this.driveSession.pickuplocation = location;
            }
            if( this.driveSession.dropofflocation.hasOwnProperty('id') && location.id === this.driveSession.dropofflocation.id ){
                this.driveSession.dropofflocation = location;
            }
        });

        pickupLocations.map(location => {
            location.name = location.name + ' - ' + location.address.street;
            return location;
        });

        this.allowCancellation = this.driveSession.status !== 'Complete' && moment(this.driveSession.drive_date, 'MM/DD/YYYY').isAfter(moment());
        this.pickuplocations = pickupLocations;
    }

    /**
     * Do not allow students to change pick up or drop off locations within 24 hours of the session start time
     * @returns {boolean}
     */
    studentAllowedToChangePickupLocation(){
        let timeUntilSessionStarts = moment(this.driveSession.drive_date + ' ' + this.driveSession.start_time, 'MM/DD/YYYY h:mm A').diff( moment(), 'minutes' );
        return timeUntilSessionStarts >= 1440
    }

    driveIsInThePast(){
        return moment(this.driveSession.drive_date + ' ' + this.driveSession.start_time, 'MM/DD/YYYY h:mm A')
            .isBefore(moment());
    }

    parseInt(number){
        return parseInt(number, 10);
    }

    updatePickupLocation() {
        this.submitted = true;

        if( ! this.studentAllowedToChangePickupLocation() ){
            return this.FlashService.setMessage( {
                'type' : 'danger',
                'message' : 'You may only update a session pick-up location or drop-off location up to twenty four ' +
                            'hours before it begins. If you need them to be changed call your driving school.'
            } );
        }

        // this.driveSession.session_length info into minutes
        this.driveSession.session_length = ( parseInt( this.driveSession.sessionLengthHours ) * 60 ) + parseInt( this.driveSession.sessionLengthMinutes );

        this.UsersModel.updateStudentDrivePickupLocation( this.driveSession ).then(response => {
            this.FlashService.setMessage( { 'type' : 'success', 'message' : response.message } );
            this.submitted = false;
            this.$state.go( 'drivecoach.student.upcoming', {}, {'reload' : true} );
        });

    }

}
StudentDrivesViewController.$inject = ['$state', 'UsersModel', 'driveSession', 'FlashService', 'student', 'pickupLocations', 'cancellationRules'];
export default StudentDrivesViewController
