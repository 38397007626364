"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var InstructorCancelSessionController = /** @class */ (function () {
    function InstructorCancelSessionController($state, driveSession, DrivesModel, FlashService, $window) {
        this.$state = $state;
        this.DrivesModel = DrivesModel;
        this.FlashService = FlashService;
        this.$window = $window;
        this.drive = driveSession;
        this.student_id = this.drive.student_id;
        this.cancellationReasons = [
            "Student no show",
            "Weather",
            "Vehicle related",
            "Other",
        ];
        this.submit = this.submit.bind(this);
        this.submitting = false;
        this.cancelData = {
            id: this.drive.id,
            cancelation_reason: "",
            cancelation_reason_explanation: "",
            student_id: this.student_id,
        };
        this.loading = false;
    }
    InstructorCancelSessionController.prototype.callPhone = function (phone) {
        this.$window.location = "tel:" + phone;
    };
    InstructorCancelSessionController.prototype.submit = function () {
        var _this = this;
        var confirmDelete = this.$window.confirm("Are you sure you want to cancel this session?");
        if (!confirmDelete) {
            return;
        }
        this.submitting = true;
        this.DrivesModel.cancelDriveSession(this.cancelData).then(function (response) {
            _this.submitting = false;
            _this.FlashService.setMessage({
                type: "success",
                message: response.message,
            });
            _this.$state.go("drivecoach.instructor.dashboard");
        });
    };
    return InstructorCancelSessionController;
}());
InstructorCancelSessionController.$inject = [
    "$state",
    "driveSession",
    "DrivesModel",
    "FlashService",
    "$window",
];
exports.default = InstructorCancelSessionController;
