import angular from 'angular'
import moment from 'moment'
import {isJson} from "../../../common/helpers/json";
import template from './admin-edit-drive.html'
import AdminDrivesEditController from './admin-edit-drive.controller'
import PickupLocationModalInstanceCtrl from './pickup-location-modal.controller'
import { dateTimeStringToDate, timeStringToDate } from '../../../common/helpers/time';

function getDriveSession($stateParams, DrivesModel) {
    return DrivesModel.getDriveByID($stateParams.id).then(response => {
        if (response.drive.location){
            response.drive.location = transformLocation(response.drive.location);
        }

        // convert drive session length in minutes into separate hours and minutes
        let _durMoment = [];
        if (response.drive.session_length < 60) {
            _durMoment = [0, parseInt(response.drive.session_length)];
        } else {
            _durMoment = moment.duration(response.drive.session_length, 'minutes').format('H:mm').split(':');
        }

        response.drive.sessionLengthHours = parseInt(_durMoment[0]);
        response.drive.sessionLengthMinutes = _durMoment[1].toString();

        response.drive.drive_date = moment(response.drive.drive_date + ' ' + response.drive.start_time).toDate();
        response.drive.start_time = timeStringToDate(response.drive.start_time);
        response.drive.sectors = [];

        response.drive.studentReadOnly = false;

        if (response.drive.student_id && response.drive.status === 'Taken') {
            response.drive.studentReadOnly = true;
        }

        return response.drive;
    })
}
getDriveSession.$inject = ['$stateParams', 'DrivesModel'];

function transformSector(sector){
    delete sector.geom;
    return sector;
}

function transformZone(zone){
    delete zone.area;
    delete zone.centroid;
    delete zone.center;
    return zone;
}

function transformLocation(location){
    if (!location){
        return location;
    }
    location.address = isJson(location.address) ? JSON.parse(location.address) : location.address;
    if (location.zoneparts){
        location.zoneparts = location.zoneparts.map(transformSector);
    }
    if (location.zones){
        location.zones = location.zones.map(transformZone);
    }
    return location;
}

function getAdhocLocations(UserService, SchoolsModel)
{
    let school_id = UserService.getCurrentUser().school_id;
    return SchoolsModel.getAdhocPickupLocations(school_id).then(response => {
        return response.pickuplocations.map(transformLocation);
    });
}
getAdhocLocations.$inject = ['UserService', 'SchoolsModel'];

function getCampuses(LocationsModel)
{
    return LocationsModel.getLocations().then(response => {
        return response.map(transformLocation);
    });
}
getCampuses.$inject = ['LocationsModel'];

function getSessionTypes(DriveSessionTypesModel)
{
    return DriveSessionTypesModel.get().then(response => {
        return response.sessionTypes.filter(type => {
            return type.status === 'Active';
        });
    });
}
getSessionTypes.$inject = ['DriveSessionTypesModel'];


function getVehicles(VehiclesModel)
{
    return VehiclesModel.getVehicles({'all' : true, 'status': 'available'}).then(response => {
        return [{'id' : 0, 'name' : 'None'}].concat(response.vehicles);
    });
}
getVehicles.$inject = ['VehiclesModel'];

function getInstructors(UsersModel)
{
    return UsersModel.getInstructors({'all' : true, 'status': 'Active'}).then(response => {
        return [{'id' : 0, 'fullname' : 'None'}].concat(response.instructors);
    });
}
getInstructors.$inject = ['UsersModel'];

function getPolicy(PoliciesModel) {
    return PoliciesModel.getByID('drives').then(data => {
        let policy = data.policy;
        let policyMeta = {};
        policy.policymeta.forEach(meta => {
            policyMeta[meta.meta_key] = isJson(meta.meta_value) ? JSON.parse(meta.meta_value) : meta.meta_value;
        });
        policy.policymeta = policyMeta;

        policy.policymeta.id = policy.id;

        let response = {};

        if (!policyMeta.hasOwnProperty('allow_adhoc_pickup_locations')) {
            response.AdhocPickupLocationPolicyData = {
                'id' : policy.id,
                'FeatureEnabled' : false
            }
        } else {
            response.AdhocPickupLocationPolicyData = {
                'id' : policy.id,
                'FeatureEnabled' : policyMeta.allow_adhoc_pickup_locations === "Y"
            }
        }

        if (!policyMeta.hasOwnProperty('use_report_cards')) {
            response.UseReportCards = {
                'id' : policy.id,
                'FeatureEnabled' : false
            }
        } else {
            response.UseReportCards = {
                'id' : policy.id,
                'FeatureEnabled' : policyMeta.use_report_cards === "Y"
            }
        }

        if (!policyMeta.hasOwnProperty('use_service_area')) {
            response.UseServiceArea = {
                'id' : policy.id,
                'FeatureEnabled' : false
            }
        } else {
            response.UseServiceArea = {
                'id' : policy.id,
                'FeatureEnabled' : policyMeta.use_service_area === "Y"
            }
        }

        if (!policyMeta.hasOwnProperty('allow_students_to_schedule')) {
            response.StudentsCanBook = {
                'id' : policy.id,
                'FeatureEnabled' : false
            }
        } else {
            response.StudentsCanBook = {
                'id' : policy.id,
                'FeatureEnabled' : policyMeta.allow_students_to_schedule === true
            }
        }
        return response;
    });
}
getPolicy.$inject = ['PoliciesModel'];

function config($stateProvider, $urlRouterProvider, USER_ROLES) {
    $stateProvider
        .state('drivecoach.admin.drives.edit', {
            'url': '/edit/:id',
            resolve: {
                driveSession: getDriveSession,
                policyData: getPolicy,
                AdhocLocations: getAdhocLocations,
                campuses: getCampuses,
                sessionTypes: getSessionTypes,
                vehicles: getVehicles,
                instructors: getInstructors
            },
            'controller': AdminDrivesEditController,
            'controllerAs': 'vm',
            'template': template,
            data: {
                authorizedRoles: [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner,
                    USER_ROLES.staff
                ]
            }
        })
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module('admin.drives.edit', [])
    .config(config)
    .controller('AdminDrivesEditController', AdminDrivesEditController)
    .controller( 'PickupLocationModalInstanceCtrl', PickupLocationModalInstanceCtrl );
