import moment from 'moment'
import uniq from 'lodash/uniq'
import sortBy from 'lodash/sortBy'
import filter from 'lodash/filter'
import chunk from 'lodash/chunk'
import { isJson } from '../helpers/json'
function CalendarModel( $rootScope, $http, APPURL, $state ) {

    const model = this,
        URLS = {
            FETCH : APPURL + '/api/v1/calendar',
            UPDATE : APPURL + '/api/v1/calendar',
            CREATE : APPURL + '/api/v1/calendar'
        };
    let data;

    model.getFilterOptions = getFilterOptions;
    model.getFutureEntities = getFutureEntities;
    // model.processAgenda = processAgenda;

    model.getAgenda = getAgenda;
    model.getAgendaData = getAgendaData;
    model.setAgenda = setAgenda;


    model.filterAdminCalendar = filterAdminCalendar;
    model.downloadAdminCalendar = downloadAdminCalendar;
    model.getInstructorCalendar = getInstructorCalendar;
    model.getStudentCalendar = getStudentCalendar;

    model.getAdminAgenda = getAdminAgenda;
    model.getInstructorAgenda = getInstructorAgenda;
    model.getInstructorTeamAgenda = getInstructorTeamAgenda;
    model.getStudentAgenda = getStudentAgenda;

    model.getStylingForDriveCard = getStylingForDriveCard;

    model.agendaData = {};

    model.agendaMethods = {
        'processDrive' : processDrive,
        'processCourse' : processCourse,
        'processEvent' : processEvent,
        'processRTO' : processRTO
    };
  
    /////////////////////////

    function getStylingForDriveCard(drive){
        let styling = 'drive'; // drive.typeDisplay.toLowerCase().replace(' ', '-');

        if( drive.status === 'Available' ){
            styling += ' open-session';
        }
        if( drive.actionRequired ){
            styling += ' danger';
        }

        return styling;
    }

    function filterAdminCalendar(params) {
        return $http.post( URLS.FETCH + '/admin', params ).then( cacheResults );
    }

    function downloadAdminCalendar(params) {
        return $http.post( URLS.FETCH + '/admin/export', params ).then( cacheResults );
    }

    function getAdminAgenda(params){
        return $http.post( URLS.FETCH + '/admin/agenda', params ).then( cacheResults );
    }

    function getInstructorCalendar(params){
        return $http.post( URLS.FETCH +  '/instructor', params ).then( cacheResults );
    }
  
    function getInstructorTeamCalendar(params){
      //needs to be updated with own team-cal route on backend
        return $http.post( URLS.FETCH +  '/admin', params ).then( cacheResults );
    }

    function getInstructorAgenda(params){
        return $http.post( URLS.FETCH +  '/instructor/agenda', params ).then( cacheResults );
    }
  
    function getInstructorTeamAgenda(params){
        return $http.post( URLS.FETCH +  '/instructor/team-agenda', params ).then( cacheResults );
    }

    function getStudentCalendar(params){
        return $http.post( URLS.FETCH + '/student', params ).then( cacheStudentCalendarResults );
    }

    function getStudentAgenda(params){
        return $http.post( URLS.FETCH + '/student/agenda', params ).then( cacheResults );
    }

    function processDrive( drive ) {
        drive.date = drive.drive_date;
        drive.startTime = moment( drive.start_time, 'HH:mm:s' ).format( 'h:mm a' );
        drive.typeDisplay = drive.type.name;
        drive.type = 'Drive Session';
        if ( drive.private === 'Y' ) {
            drive.typeDisplay = 'Drive Hidden';
        }
        drive.timestamp = moment(drive.drive_date + ' ' + drive.start_time ).unix();
        if (drive.location){
            drive.location = isJson(drive.location) ? JSON.parse(drive.location) : drive.location;
        }

        if( drive.pickuplocation && drive.pickuplocation.hasOwnProperty('address') ){
            drive.pickuplocation.address = angular.fromJson( drive.pickuplocation.address );
        }

        const minutes = moment.duration( drive.session_length, 'minutes' );
        drive.endTime = moment( drive.start_time, 'HH:mm:s' ).add( minutes, 'minutes' ).format( 'h:mm a' );

        drive.duration = moment.duration( drive.session_length, 'minutes' ).asHours();
        drive.duration = moment.duration( drive.duration, 'hours' ).format("h [hours], m [minutes]");

        drive.actionRequired = drive.hasUngradedReportcards === true
            && drive.status === 'Taken'
            && moment( drive.date , 'YYYY-MM-DD' ).isBefore(moment());

        drive.styling = getStylingForDriveCard(drive);

        if( drive.status === 'Available' ){
            drive.styling += ' open-session';
        }
        if( drive.actionRequired ){
            drive.styling += ' danger';
        }

        return drive; //return drive.status === 'Taken';
    }

    function toQueryString( obj ) {
        const parts = [];
        for ( let i in obj ) {
            if ( obj.hasOwnProperty( i ) ) {
                parts.push( encodeURIComponent( i ) + "=" + encodeURIComponent( obj[i] ) );
            }
        }
        return parts.join( "&" );
    }

    function extract( result ) {
        return result.data;
    }

    function cacheResults( result ) {
        data = extract( result );
        return data;
    }

    function cacheStudentCalendarResults(result){
        data = extract(result);
        setAgenda(result.data);
        return data;
    }

    function cacheCalendarResults( result ) {
        data = extract( result );
        return data;
    }

    ////////////////

    function getFilterOptions( data ) {
        if ( data ) {
            return $http.get( URLS.FETCH + '/options?' + toQueryString( data ) ).then( cacheResults );
        }
        return $http.get( URLS.FETCH + '/options' ).then( cacheResults );
    }


    function getFutureEntities(data){
        if ( data ) {
            return $http.get( URLS.FETCH + '/future-entities?' + toQueryString( data ) ).then( cacheResults );
        }
        return $http.get( URLS.FETCH + '/future-entities' ).then( cacheResults );
    }

    function processCourse( course ) {
        course.type = 'Course';
        course.typeDisplay = course.name;
        course.location = angular.fromJson( course.location );
        course.timestamp = moment(moment(course.date).format('YYYY-MM-DD') + ' ' + course.time ).unix();
        const minutes = moment.duration( course.duration, 'minutes' );

        course.endTime = moment( course.time, 'HH:mm:s' ).add( minutes.asMinutes(), 'minutes' ).format( 'h:mm a' );

        course.startTime = moment( course.time, 'HH:mm:s' ).format( 'h:mm a' );
        // TODO: This causes issues in the admin agenda probably because its being formatted somewhere else as well
        // Investigate before using this directive anywhere else
        //course.duration = moment.duration( course.duration, 'minutes' ).asHours();
        //course.duration = moment.duration( course.duration, 'hours' ).format("h [hours], m [minutes]");

        //console.log(course.duration, minutes.asMinutes(), course.date, course.endTime);
        course.styling = 'course';

        return course;
    }

    function processEvent( event ) {
        event.typeDisplay = 'Event';

        event.type = 'Event';
        event.location = angular.fromJson( event.location );
        event.timestamp = moment(moment(event.date).format('YYYY-MM-DD') + ' ' + event.time ).unix();

        const minutes = moment.duration( event.duration, 'minutes' );
        event.endTime = moment( event.time, 'HH:mm:s' ).add( minutes, 'minutes' ).format( 'h:mm a' );

        event.startTime = moment( event.time, 'HH:mm:s' ).format( 'h:mm a' );
        //event.duration = moment.duration( event.duration, 'minutes' ).asHours();
        //event.duration = moment.duration( event.duration, 'hours' ).format("h [hours], m [minutes]");

        event.styling = 'event';

        return event;
    }

    function processRTO(rto){
        rto.typeDisplay = 'RTO';

        const _start_ = moment(rto.begin, 'YYYY-MM-DD HH:mm:s');
        const _end_ = moment(rto.end, 'YYYY-MM-DD HH:mm:s');

        rto.date = _start_.format('MM/DD/YYYY');
        rto.timestamp = _start_.unix();

        rto.styling = 'rto';

        // Dont show times for full or range type RTO
        if( rto.type === 'full' || rto.type === 'range' ){
            const s_moment = angular.copy(_start_).hours(23).minutes(59);
            const e_moment = angular.copy(_end_).hours(0).minutes(0);
            rto.endTime = s_moment.format('h:mm a');
            rto.startTime = e_moment.format('h:mm a');
            rto.duration = moment.duration( s_moment.diff( e_moment ) ).format("h [hours], m [minutes]");
        }else{
            rto.endTime = moment(rto.end, 'YYYY-MM-DD HH:mm:s').format('h:mm a');
            rto.startTime = moment(rto.begin, 'YYYY-MM-DD HH:mm:s').format('h:mm a');
            rto.duration = moment.duration( _end_.diff( _start_ ) ).format("h [hours], m [minutes]");
        }
        rto.type = rto._type;
        return rto;
    }

    /**
     * Process the entities from the calendar to build the agenda
     * TODO: This is in three places, should eventually be put into a directive or service or something.
     * Right now its in the admin agenda, instructor agenda, and (to some degree) the calendar directive
     * @param response
     */
    function processAgenda( response, limit = 50 ) {
        model.agendaItems = [];
        let _allItems = [];

        //TODO: All of this can probably be refactored to use a more functional approach
        if ( typeof response.data.drives !== 'undefined' ) {
            response.data.drives
                .filter(model.agendaMethods.processDrive)
                .forEach( drive => {
                    _allItems.push( drive );
                } );
        }

        if ( typeof response.data.courses !== 'undefined' ) {
            response.data.courses
                .filter(model.agendaMethods.processCourse)
                .forEach( course => {
                    _allItems.push( course );
                } );
        }

        if ( typeof response.data.events !== 'undefined' ) {
            response.data.events
                .filter( model.agendaMethods.processEvent )
                .forEach( event => {
                    _allItems.push( event );
                } );
        }

        if( typeof response.data.timeoffrequests !== 'undefined' ){
            response.data.timeoffrequests
                .filter(model.agendaMethods.processRTO)
                .forEach( rto => {
                    _allItems.push(rto);
                });
        }

        _allItems = uniq(_allItems);
        let sorted = sortBy(_allItems, entity => {
            return entity.timestamp;
        } );

        let filtered = filter(sorted, item => {
            return item.timestamp > moment().unix();
        });

        model.totalAgendaItems = chunk( filtered , limit);
        model.currentAgendaIndex = 0;
        let agendaData;
        if (model.totalAgendaItems.length !== 0) {
            agendaData = model.totalAgendaItems[0]
        } else {
            agendaData = [];
        }
        model.agendaData = {
            'data' : agendaData,
            'total' : model.totalAgendaItems.length,
            'all' : model.totalAgendaItems,
            'list' : filtered
        };
        return model.agendaData;

    }

    function getAgenda(){
        return model.agendaData.data;
    }

    function getAgendaData(){
        return model.agendaData;
    }

    function setAgenda(data, limit = 50){
        processAgenda(data, limit);
        $rootScope.$broadcast('ADMIN_CALENDAR_AGENDA_UPDATED');
        $rootScope.$broadcast('STUDENT_CALENDAR_AGENDA_UPDATED');
        return model.agendaData;
    }

}
CalendarModel.$inject = ['$rootScope','$http', 'APPURL', '$state'];
export default CalendarModel;
