import '../assets/images/map-pin.png'
import '../../node_modules/bootstrap-sass/assets/stylesheets/_bootstrap.scss'
import '../../node_modules/leaflet/dist/leaflet.css'
import '../assets/css/style.scss'
import angular from 'angular';
import 'angular-cookies'
import 'vue';
import 'ngVue'
import 'ngVue/build/plugins.js'
import run from './drivecoach.run'
import config from './drivecoach.config'
import MainController from './drivecoach.maincontroller'
import 'angular-moment'
import ngAnimate from 'angular-animate'
import AngularStorage from 'angular-storage'
import ngSanitize from 'angular-sanitize'
import uiMask from 'angular-ui-mask'
import uiBootstrap from 'angular-ui-bootstrap'
import 'angular-simple-logger'
import 'ui-select'
import 'leaflet'
import 'ui-leaflet'
import router from 'angular-ui-router'
import Signup from './signup/signup.module'
import {Login} from './login/login.module'
import Logout from './logout/logout.module'
import {Services} from './common/services.module'
import Directives from './common/directives.module'
import ResetPassword from './reset-password/reset-password.module'
import ForgotPassword from './forgot-password/forgot-password.module'
import {Constants} from './common/constants'
import {Models} from './common/models'
import Admin from './admin/admin.module'
import Student from './student/student.module'
import Instructor from './instructor/instructor.module'

let dependencies = [
    'ngVue',
    'ngVue.plugins',
    router,
    'nemLogging',
    'ui-leaflet',
    'ui.select',
    'ngCookies',
    uiBootstrap,
    uiMask,
    ngSanitize,
    AngularStorage,
    ngAnimate,
    'angularMoment',
    Constants.name,
    Models.name,
    Services.name,
    Directives.name,
    ForgotPassword.name,
    ResetPassword.name,
    Login.name,
    Logout.name,
    Signup.name,
    Student.name,
    Admin.name,
    Instructor.name,
];
angular.module( 'DriveCoach', dependencies )
    .controller('MainController', MainController)
    .run(run)
    .config(config);
