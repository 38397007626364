import { isJson } from "../helpers/json"
function UsermetaModel( $http, APPURL ) {

    let model = this,
        URLS = {
            FETCH : APPURL + '/api/v1/usermeta',
            UPDATE : APPURL + '/api/v1/usermeta',
            CREATE : APPURL + '/api/v1/usermeta'
        },
        usermeta,
        user,
        metadata;

    function extract( result ) {
        return result.data;
    }

    function cacheResults( result ) {
        usermeta = extract( result );
        return usermeta;
    }

    // TODO: Turn meta data stored as json into objects
    function cacheUser( result ) {
        user = extract( result );
        metadata = {};
        user.usermeta.forEach( function ( meta ) {
            metadata[meta.meta_key] = isJson( meta.meta_value ) ? JSON.parse( meta.meta_value ) : meta.meta_value;
        } );
        user.usermeta = metadata;
        return user;
    }

    model.getUserByID = function ( userId ) {
        return $http.get( URLS.FETCH + '/' + userId ).then( cacheResults );
    };

    model.get = function () {
        return $http.get( URLS.FETCH ).then( cacheResults );
    };

    model.update = function ( meta ) {
        let id = (meta.user_id) ? meta.user_id : meta.meta_id;
        return $http.put( URLS.FETCH + '/' + id, meta ).then( cacheResults );
    };

    model.delete = function ( user ) {
        return $http.delete( URLS.FETCH + '/' + user.id ).then( cacheResults );
    }

}
UsermetaModel.$inject = ['$http', 'APPURL'];
export default UsermetaModel;