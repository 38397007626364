import moment from 'moment'
import clone from 'lodash/clone'
function AdminEventsBatchCreateController($state, BatchCreateService, EventsModel, FlashService) {

    let vm = this;

    vm.endDate = null;

    vm.showCreateBatchSessionForm = 'yes'; // yes for testing, change to no for production

    vm.event = BatchCreateService.getEvent();
    vm.events = [];

    vm.duplicateSession = duplicateSession;
    vm.toggleCreateBatchSessionForm = toggleCreateBatchSessionForm;
    vm.updateEndDate = updateEndDate;
  
    // http://stackoverflow.com/questions/22929204/iterate-throught-between-two-dates-in-javascript
    function momentIterate(options) {
        let events = [];
        if (typeof options.days === 'undefined') {
            options.days = {
                'monday': true,
                'tuesday': true,
                'wednesday': true,
                'thursday': true,
                'friday': true,
                'saturday': true,
                'sunday': true
            };
        }

        let startDate = moment(options.startDate);
        let endDate = moment(options.endDate);
        for (let date = moment(startDate); date.diff(endDate) < 0; date.add('days', 1)) {
            let formattedDateKey = date.format('dddd').toLowerCase();
            if (typeof options.days[formattedDateKey] !== 'undefined' && options.days[formattedDateKey] === true) {
                let newEvent = clone(vm.event, true);
                //console.log(date.format('MM/DD/YYYY') , date.format('dddd'));
                newEvent.formattedDate = date.format("YYYY-MM-DD");
                newEvent.formattedTime = moment(vm.event.time).format("HH:mm:ss");
                events.push(newEvent);
            }
        }

        return events;
    }

    function duplicateSession() {
        let dupeOptions = {
            'startDate': vm.event.date,
            'endDate': vm.endDate,
            'days': vm.days
        };

        let events = momentIterate(dupeOptions);

        EventsModel.batchCreate(events).then(function (response) {
            FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
            if (response.conflicts !== 0) {
                FlashService.setMessage({
                    'type': 'error',
                    'message': 'There were scheduling conflicts, visit the scheduling conflicts page to review them.'
                })
            }
            BatchCreateService.setEvent(null);
            $state.go('drivecoach.admin.calendar', {}, {'reload': true});
        })

    }

    function toggleCreateBatchSessionForm() {
        vm.showCreateBatchSessionForm = 'yes';
    }
  
    function updateEndDate(newDate) {
      vm.endDate = newDate.selected[0]
    }

}
AdminEventsBatchCreateController.$inject = ['$state', 'BatchCreateService', 'EventsModel', 'FlashService'];
export default AdminEventsBatchCreateController;