import {inDollars} from '../../../../DriveScout/Billing/Teller'
import {isJson} from '../../../common/helpers/json'
import moment from 'moment'
import { saveToDisk } from "../../../common/helpers/saveToDisk";
import { dateTimeStringToDate, toYMD } from "../../../common/helpers/time";

class InstructorOrdersController {

    constructor(OrdersModel, FlashService, OrderDetailsService, PoliciesModel, $q, UserService, UsersModel){
        this.loading = true;
        this.submitting = false;
        this.currentPage = 0;
        this.OrdersModel = OrdersModel;
        this.FlashService = FlashService;
        this.OrderDetailsService = OrderDetailsService;
        this.PoliciesModel = PoliciesModel;
        this.$q = $q;
        this.UsersModel = UsersModel;

        this.orders = [];
      
        this.busy = false;
        this.page_by = 20;
        this.modalShown = false;
        this.order = {}; //used for order details modal
        this.billingConfig = {}; //used for order details modal
        this.clearPaymentForm = false; //change to true after form submit to clear vue form
        this.viewing_order = 0;

        this.currentUser = UserService.getCurrentUser();

        this.patchOrder = this.patchOrder.bind(this);
        this.setStartDate = this.setStartDate.bind(this);
        this.setEndDate = this.setEndDate.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.submitPayment = this.submitPayment.bind(this);
        this.voidPayment = this.voidPayment.bind(this);
        this.voidOrder = this.voidOrder.bind(this);
        this.sendOrder = this.sendOrder.bind(this);
        this.printOrder = this.printOrder.bind(this);

        this.statsParams = {
            start_date: new Date(new Date().setDate(new Date().getDate() - 7)),
            end_date: new Date(),
            page_by: 20
        }

        OrdersModel.get({
            'created_by' : this.currentUser.id,
            'page_by': this.page_by, 
            'start_date': new Date(this.statsParams.start_date).toLocaleDateString(), 
            'end_date': new Date(this.statsParams.end_date).toLocaleDateString()
        }).then(response => {
            this.orders = response.orders.data.map(order => {
                order.created_at = moment(dateTimeStringToDate(order.created_at)).format('MM/DD/YYYY h:mm a')
                order.lineitems = order.lineitems.map(item => {
                    if (item.product_details) {
                        item.product_details = isJson(item.product_details) ? JSON.parse(item.product_details) : item.product_details;
                    }
                    return item;
                })
                return order
            });
            this.loading = false;
            this.totalItems = response.orders.total
        })

        this.inDollars = inDollars.bind(this);

    }

    setStartDate(date){
        this.statsParams.start_date = date.selected[0];
    }

    setEndDate(date){
        this.statsParams.end_date = date.selected[0];
    }
 
    applyPreset(preset){

        let daysAgo = new Date().setDate(new Date().getDate() - 7);

        if (preset === "7_DAYS"){
            daysAgo = new Date().setDate(new Date().getDate() - 7);
        }
        if (preset === "30_DAYS"){
            daysAgo = new Date(new Date().setDate(new Date().getDate() - 30))
        }
        if (preset === "90_DAYS"){
            daysAgo = new Date(new Date().setDate(new Date().getDate() - 90))
        }
        
        this.statsParams.start_date = new Date(
            daysAgo.getFullYear(),
            daysAgo.getMonth(),
            daysAgo.getDate(),
            0,0,0
        );
        this.statsParams.end_date = new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate(),
            23,59,0
        );
        this.submit();
    }

    submit()
    {
        this.busy = true;
        let params = {
            'created_by' : this.currentUser.id,
            'page': this.currentPage,
            'page_by' : this.statsParams.page_by,
            'start_date': toYMD(new Date(
                this.statsParams.start_date.getFullYear(),
                this.statsParams.start_date.getMonth(),
                this.statsParams.start_date.getDate(),
                0,0,0
            )),
            'end_date': toYMD(new Date(
                this.statsParams.end_date.getFullYear(),
                this.statsParams.end_date.getMonth(),
                this.statsParams.end_date.getDate(),
                23,59,0
            )),
        }
        if (this.statsParams.status){
            params.status = this.statsParams.status;
        }
        if (this.statsParams.source){
            params.source = this.statsParams.source;
        }
        let salesParams = {
            'start_date': toYMD(new Date(
                this.statsParams.start_date.getFullYear(),
                this.statsParams.start_date.getMonth(),
                this.statsParams.start_date.getDate(),
                0,0,0
            )),
            'end_date': toYMD(new Date(
                this.statsParams.end_date.getFullYear(),
                this.statsParams.end_date.getMonth(),
                this.statsParams.end_date.getDate(),
                23,59,0
            ))
        }
        if (this.statsParams.status){
            salesParams.status = this.statsParams.status;
        }
        if (this.statsParams.source){
            salesParams.source = this.statsParams.source;
        }
        this.OrdersModel.salesReports(salesParams).then(response => {
            this.stats = response.stats;
        });
        this.OrdersModel.get(params).then(response => {
            this.orders = response.orders.data.filter(order => {
                order.created_at = moment(dateTimeStringToDate(order.created_at)).format('MM/DD/YYYY h:mm a')
                order.lineitems = order.lineitems.filter(item => {
                    if (item.product_details) {
                        item.product_details = isJson(item.product_details) ? JSON.parse(item.product_details) : item.product_details;
                    }
                    return item;
                })
                return order
            });
            this.page_by = response.orders.per_page;
            this.submitting = false;
            this.busy = false;
            this.totalItems = response.orders.total;
        }).catch(Error => {
            this.submitting = false;
            this.busy = false;
        });
    }

    patchOrder(updatedOrder){
        let orders = this.orders.map(order => {
           if(order.id === updatedOrder.id){
               return updatedOrder;
           }
           return order;
        });
        this.orders = orders;
    }

    pageChanged(){
        this.submitting = true;
        let params = {
            'created_by' : this.currentUser.id,
            'page': this.currentPage,
            'page_by' : this.statsParams.page_by,
            'start_date': toYMD(new Date(
                this.statsParams.start_date.getFullYear(),
                this.statsParams.start_date.getMonth(),
                this.statsParams.start_date.getDate(),
                0,0,0
            )),
            'end_date': toYMD(new Date(
                this.statsParams.end_date.getFullYear(),
                this.statsParams.end_date.getMonth(),
                this.statsParams.end_date.getDate(),
                23,59,0
            ))
        }
        if (this.statsParams.status){
            params.status = this.statsParams.status;
        }
        if (this.statsParams.source){
            params.source = this.statsParams.source;
        }
        this.OrdersModel.get(params).then(response => {
            this.orders = response.orders.data.filter(order => {
                order.created_at = moment(dateTimeStringToDate(order.created_at)).format('MM/DD/YYYY h:mm a')
                order.lineitems = order.lineitems.filter(item => {
                    if (item.product_details) {
                        item.product_details = isJson(item.product_details) ? JSON.parse(item.product_details) : item.product_details;
                    }
                    return item;
                })
                return order;
            });
            this.page_by = response.orders.per_page;
            this.submitting = false;
            this.totalItems = response.orders.total;
        });
    }

    showOrderDetails(order) {
        this.$q.all([this.PoliciesModel.getByID('billing'),this.OrdersModel.getDetails({id: order.id})]).then(responses => { 
            this.order = this.OrderDetailsService.setupOrderDetails(responses[1], order); 
            this.billingConfig = this.OrderDetailsService.configureBilling(responses[0]); 
            if (this.billingConfig.gateway === 'paypal') {
                // console.log('Configuring PayPal Gateway');
                this.configurePaypal(order, billingConfig);
            }
            this.clearPaymentForm = false; 
            this.modalShown = true;
            this.loading = false; 
        }) 
    }

    closeModal() {
        this.modalShown = false;
        this.order = {};
        this.viewing_order = null;
        this.clearPaymentForm = true;
    }

    submitPayment(data) {
        let payment = data.payment;
        this.submitting = true;
        this.OrderDetailsService.setPayment(payment)
        this.OrderDetailsService.submitPayment().then(response => {
            // console.log('details service response', response)
            this.FlashService.setMessage({
                'type' : 'success',
                'message' : response.message
            })
            this.order.payments.push(response.payment);
            this.submitting = false;
            this.order.paid = response.order.paid;
            this.order.notes = response.order.notes;
            this.patchOrder(this.order)
            this.clearPaymentForm = true;
        }).catch(Error => {
            this.submitting = false;
        })
    }

    voidPayment(data) {
        let { payment_id, index } = data;
        this.submitting = true;
        this.UsersModel.voidPayment(this.order.student.id, payment_id).then(response => {
            this.submitting = false;
            this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
            this.order.payments.splice(index, 1, response.payment)
            this.patchOrder(this.order)
        }).catch(Error => {
            this.submitting = false;
        });
    }

    voidOrder(data) {
        this.submitting = true;
        this.OrdersModel.voidOrder(data.order_id).then(response => {
            this.submitting = false;
            this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
            this.order.status = 'VOID';
            this.patchOrder(this.order)
        }).catch(Error => {
            this.submitting = false;
        });
    }

    sendOrder(data) {
        this.submitting = true;
        this.OrdersModel.sendOrder(data.order_id, {'recipient': data.receipt_email}).then(response => {
            this.submitting = false;
            this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
        }).catch(Error => {
            this.submitting = false;
        });
    }

    printOrder(data) {
        this.submitting = true;
        this.OrdersModel.printOrder(data.order_id).then(response => {
            this.submitting = false;
            this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
            saveToDisk(response.url);
        }).catch(Error => {
            this.submitting = false;
        });
    }

}

InstructorOrdersController.$inject = ['OrdersModel', 'FlashService', 'OrderDetailsService', 'PoliciesModel', '$q', 'UserService', 'UsersModel'];
export default InstructorOrdersController;
