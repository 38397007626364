import { States } from '../../../common/constants/states'
import { createBillingGatewayFromPolicies} from '../../../classes/billing/BillingGateway'
import {PaymentMethods} from "../../../common/constants/payment_methods";
import {Months} from "../../../common/constants/months_and_years";
import {Years} from "../../../common/constants/months_and_years";
import cloneDeep from 'lodash/cloneDeep'
import { Coupon } from '../../../classes/Coupon';
import { inDollars } from '../../../../DriveScout/Billing/Teller';

class dsStudentOrderFormController {

    constructor($scope, UsersModel, FlashService, UserService, OrdersModel, PayPalService, CreateOrderService, CouponsModel){
        this.PayPalService = PayPalService;
        this.UsersModel = UsersModel;
        this.OrdersModel = OrdersModel;
        this.CouponsModel = CouponsModel;
        this.FlashService = FlashService;
        this.billingAddressDoesNotAlreadyExist = false;
        this.student = UserService.getCurrentUser();
        this.inDollars = inDollars.bind(this);

        const $cashier = CreateOrderService.cashier();

        this.$cashier = $cashier;
        this.addresses = this.$cashier.addresses();

        const policies = this.$cashier.policies();
        this.gateway = policies.getPolicyByName('billing').getMetaValueByKey('billing_gateway');
        this.gateway_config = createBillingGatewayFromPolicies(policies).config();

        this.surchargeBilledSeparately = this.$cashier.surchargeBilledSeparately();
        this.surchargeAmount = this.$cashier.surchargeAmount();
        
        this.surchargeDetail = this.$cashier.surchargeDetail();

        if (this.$cashier.order().hasStudent() === true && this.$cashier.order().student().$originalData.guardian) {
            const $payments = this.$cashier.order().payments().map(payment => {
                payment.creditCard().setName(this.$cashier.order().student().$originalData.guardian.firstname + ' ' + this.$cashier.order().student().$originalData.guardian.lastname)
                return payment;
            });
            this.$cashier.order().setPayments($payments);
        }

        if (this.$cashier.order().hasStudent() === true && ! this.$cashier.order().student().$originalData.guardian) {
            const $payments = this.$cashier.order().payments().map(payment => {
                // console.log(this.$cashier.order().student());
                payment.creditCard().setName(this.$cashier.order().student().firstname() + ' ' + this.$cashier.order().student().lastname())
                return payment;
            })
            this.$cashier.order().setPayments($payments)
        }

        this.payment_methods = cloneDeep(PaymentMethods);
        this.months = Months;
        this.years = Years;
        this.states = States;
    }


    removeCoupon()
    {
        this.$cashier.removeCoupon();
        this.$cashier.calculateTotal();
        this.updateAmountPaid();
    }

    updateAmountPaid()
    {
        if (this.$cashier.allowsPartialPayments() === false) {
            this.$cashier.order().payments()[0].setAmount(this.$cashier.totalDue());
        }
    }

    submitCouponCode()
    {
        const code = this.coupon_code;
        this.CouponsModel.validateCoupon(code).then(response => {
            const coupon = new Coupon(response.coupon)
            this.$cashier.addCoupon(coupon)
            delete this.coupon_code
            this.FlashService.setMessage({
                'message' : response.message,
                'type' : 'success'
            })
            this.updateAmountPaid()
        })
    }

    handleAmountChange(){
        // this.checkForOverpayment();
        this.$cashier.calculateTotal();
    }

    removeProductFromOrder(product) {
        this.$cashier.removeProduct(product);
        this.updateAmountPaid()
        this.$cashier.calculateTotal();
    }

    /**
     * If the billing address is already an available address that means its a pick-up location. Students cannot have
     * duplicate pick-up locations, so prevent the admin from being able to select the option to add it as a
     * pick-up location
     *
     * @param billingAddress
     * @returns {void}
     */
    checkBillingAddressAlreadyExistsAsPickupLocation(billingAddress = '') {
        if (!billingAddress) {
            return false;
        }

        let addressExists = this.$cashier.addresses().filter(address => {
            return address.street().toLowerCase() === billingAddress.toLowerCase()
        }).length > 0;
        if (!addressExists) {
            this.billingAddressDoesNotAlreadyExist = true;
            this.$cashier.order().$payments[0].$add_billing_address_as_pickup_location = false;
        } else {
            this.billingAddressDoesNotAlreadyExist = false;
            this.$cashier.order().$payments[0].$add_billing_address_as_pickup_location = true;
        }
    }

    updateBillingAddressesList(){
        this.UsersModel.getPickupLocations(this.$cashier.order().student().id()).then(response => {
            this.addresses = response.pickuplocations.map(location => {
                return location.address;
            });
        })

    }

    addBillingAddress(index, address){
        this.$cashier.addBillingAddress(index, address);
        this.$cashier.calculateTotal();
        this.checkBillingAddressAlreadyExistsAsPickupLocation(address.street())
    }
    
    selectPaymentMethod(id, paymentIndex) {
        this.$cashier.setCustomerPaymentMethod(id, paymentIndex);
    }

    handleAuthorizeCard(tokenData)
    {
        let valid = this.$cashier.order().products().length === 0 || !this.$cashier.order().student() || this.$cashier.order().submitted === true;
        if (!valid) {
            // fail
        }

        // set token data on order
        this.$cashier.order().$payments = this.$cashier.order().payments().map(payment => {
            if (payment.paymentMethod() === 'card') {
                payment.creditCard().tokenize(tokenData);
            }
            return payment;
        });

        this.submit(this.$cashier.order());
    }

}
dsStudentOrderFormController.$inject = ['$scope', 'UsersModel', 'FlashService', 'UserService', 'OrdersModel', 'PayPalService', 'CreateOrderService', 'CouponsModel'];
export default dsStudentOrderFormController;
