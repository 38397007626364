import isArray from 'lodash/isArray'

class StudentDrivesIndexController {

    constructor($stateParams, DrivesModel){
        this.date = $stateParams.date;
        DrivesModel.getDrivesByDate( $stateParams.date ).then( response => {

            if ( ! isArray( response ) ) {
                response = [response];
            }

            this.drives = response.filter( drive => {
                drive.start_time = new Date(drive.drive_date + ' ' + drive.start_time );
                return drive.status === 'Available';
            } );

        } );
    }

}
StudentDrivesIndexController.$inject = [ '$stateParams', 'DrivesModel'];

export default StudentDrivesIndexController;
