import {PaymentMethods} from "../../../../common/constants/payment_methods";
import moment from 'moment'
import cloneDeep from 'lodash/cloneDeep'
import { inDollars } from "../../../../../DriveScout/Billing/Teller";
import { saveToDisk } from "../../../../common/helpers/saveToDisk";

class OrderReportsController {
    constructor(OrdersModel, UserService, OrderDetailsService, PoliciesModel, $q, FlashService, PayPalService, UsersModel) {
        this.OrdersModel = OrdersModel;
        this.OrderDetailsService = OrderDetailsService;
        this.PoliciesModel = PoliciesModel;
        this.$q = $q;
        this.FlashService = FlashService;
        this.PayPalService = PayPalService;
        this.UsersModel = UsersModel;
        this.payment_methods = cloneDeep(PaymentMethods);
        this.reportTotalPaid = 0;
        this.payments = [];
        this.viewing_order = 0;
        this.payment_methods.unshift({
            'name': 'All',
            'value': 'all'
        });
        this.filter = {
            'payment_method': this.payment_methods[0],
            'startDate': moment('10/05/2021').toDate(),
            'endDate': moment().endOf('month').toDate()
        }
        this.inDollars = inDollars.bind(this);

        this.loading = true;
        this.selectionHasStudents = false;

        this.applyingFilter = false;
        this.modalShown = false;
        this.order = {}; //used for order details modal
        this.billingConfig = {}; //used for order details modal
        this.clearPaymentForm = false; //change to true after form submit to clear vue form

        this.updateStartDate = this.updateStartDate.bind(this)
        this.updateEndDate = this.updateEndDate.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.submitPayment = this.submitPayment.bind(this)
        this.voidPayment = this.voidPayment.bind(this)
        this.voidOrder = this.voidOrder.bind(this)

        this.currentUser = UserService.getCurrentUser();
      
        OrdersModel.reports(this.getFilterParams(this.filter)).then(response => {
            this.payments = response.data.payments;
            this.loading = false;
            if (this.payments.length > 0) {
                this.reportTotalPaid = this.payments.reduce((carry, payment) => {
                    return carry + parseInt(payment.amount);
                }, 0)
            }
        })
    }

    showOrderDetails(payment) {
        this.loading = true;
        this.viewing_order = payment.order_id;
        this.$q.all([this.PoliciesModel.getByID('billing'),this.OrdersModel.getDetails({id: payment.order_id})]).then(responses => { 
            this.order = this.OrderDetailsService.setupOrderDetails(responses[1], payment); 
            this.billingConfig = this.OrderDetailsService.configureBilling(responses[0], payment); 
            if (this.billingConfig.gateway === 'paypal') {
                // console.log('Configuring PayPal Gateway');
                this.configurePaypal(payment, billingConfig);
            }
            this.clearPaymentForm = false; 
            this.modalShown = true;
            this.loading = false; 
        }) 
    }

    closeModal() {
        this.modalShown = false;
        this.order = {};
        this.viewing_order = null;
        this.clearPaymentForm = true;
    }

    getFilterParams() {
        let filter = Object.assign({}, this.filter);
        return {
            'payment_method': filter.payment_method.value,
            'start_date': moment(filter.startDate).format('YYYY-MM-DD'),
            'end_date': moment(filter.endDate).format('YYYY-MM-DD')
        }
    }

    submitFilter() {
        this.reportTotalPaid = 0;
        this.loading = true;
        this.OrdersModel.reports(this.getFilterParams(this.filter)).then(response => {
            this.payments = response.data.payments;
            this.loading = false;
            if (this.payments.length > 0) {
                this.reportTotalPaid = this.payments.reduce((carry, payment) => {
                    return carry + parseInt(payment.amount);
                }, 0)
            }
        })
    }

    submitPayment(data) {
        let payment = data.payment;
        this.submitted = true;
        this.OrderDetailsService.setPayment(payment)
        this.OrderDetailsService.submitPayment().then(response => {
            this.FlashService.setMessage({
                'type' : 'success',
                'message' : response.message
            })
            this.order.payments.push(response.payment);
            this.submitted = false;
            this.order.paid = response.order.paid;
            this.order.notes = response.order.notes;
            this.clearPaymentForm = true;
        }).catch(Error => {
            this.submitted = false;
        })
    }
    
    updateStartDate(newDate) {
      this.filter.startDate = newDate.selected[0]
    }
    
    updateEndDate(newDate) {
      this.filter.endDate = newDate.selected[0]
    }

    voidPayment(data) {
        let { payment_id, index } = data;
        this.submitted = true;
        this.UsersModel.voidPayment(this.order.student.id, payment_id).then(response => {
            this.submitted = false;
            this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
            this.order.payments.splice(index, 1, response.payment)
        }).catch(Error => {
            this.submitted = false;
        });
    }

    voidOrder(data) {
        this.submitted = true;
        this.OrdersModel.voidOrder(data.order_id).then(response => {
            this.submitted = false;
            this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
            this.order.status = 'VOID';
        }).catch(Error => {
            this.submitted = false;
        });
    }

    sendOrder(data) {
        this.submitting = true;
        this.OrdersModel.sendOrder(data.order_id, {'recipient': data.receipt_email}).then(response => {
            this.submitting = false;
            this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
        }).catch(Error => {
            this.submitting = false;
        });
    }

    printOrder(data) {
        this.submitting = true;
        this.OrdersModel.printOrder(data.order_id).then(response => {
            this.submitting = false;
            this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
            saveToDisk(response.url);
        }).catch(Error => {
            this.submitting = false;
        });
    }

    configurePaypal(payment, billingConfig) {
        //this isnt right but its not hooked up rn.. need to figure out how to set details on the payment that gets emitted from vue. possibly do inside vue order detail component
        return this.PayPalService.getClientToken().then(client_token => {
            this.PayPalService.init(billingConfig.gateway_config.paypal_rest_api_client_id, client_token);
            // this.PayPalService.onApprove = this.OrderDetailsService.handlePayPalPayment;
            this.PayPalService.validateForm = this.OrdersModel.validatePaymentForm;
            this.PayPalService.getFormData = () => {
                let payment = cloneDeep(payment);
                if (this.currentUser.role.type === 'student') {
                    payment.payment_method = 'card'
                }
                payment.order_id = this.order.id;
                return {'order_id': this.order.id, 'payment': payment};
            }
        })
    }
}

OrderReportsController.$inject = ['OrdersModel', 'UserService', 'OrderDetailsService', 'PoliciesModel', '$q', 'FlashService', 'PayPalService', 'UsersModel'];

export default OrderReportsController;