import cloneDeep from 'lodash/cloneDeep'
function AdminSettingsReportCardController($scope, $state, UserService, TasksModel, FlashService, SchoolsModel) {

    const vm = this;
    vm.currentUser = UserService.getCurrentUser();
    vm.school = vm.currentUser.school;
    vm.tasks = [];
    vm.selectedTasks = [];
    vm.addCustomTaskToReportCard = addCustomTaskToReportCard;
    vm.addTaskToReportCard = addTaskToReportCard;
    vm.removeTask = removeTask;

    init();

    function prepareDataForUpdate() {
        let tasks = cloneDeep(vm.selectedTasks);
        return {
            'school_id': vm.currentUser.school_id,
            'tasks': tasks
        };
    }

    function addCustomTaskToReportCard() {
        let task = {
            'name': vm.customTask
        };
        vm.selectedTasks.push(task);
        let data = prepareDataForUpdate();
        SchoolsModel.updateTasks(data).then(response => {
            FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
            vm.customTask = null;
            vm.task = null;
        });
    }

    function addTaskToReportCard() {
        let task = {
            'name': vm.task.name
        };
        vm.selectedTasks.push(task);
        let data = prepareDataForUpdate();
        SchoolsModel.updateTasks(data).then(response => {
            FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
            vm.customTask = null;
            vm.task = null;
        });
    }

    function removeTask(index) {
        vm.selectedTasks.splice(index, 1);
        let data = prepareDataForUpdate();
        SchoolsModel.updateTasks(data).then(response => {
            FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
            vm.customTask = null;
            vm.task = null;
        });
    }

    function init() {
        TasksModel.get().then(function (response) {
            vm.tasks = response.tasks.filter(task => task.approved === true);
        });
        SchoolsModel.getTasks(vm.currentUser.school_id).then(function (response) {
            vm.selectedTasks = response.tasks;
        });
        
    }

}

AdminSettingsReportCardController.$inject = ['$scope', '$state', 'UserService', 'TasksModel', 'FlashService', 'SchoolsModel'];
export default AdminSettingsReportCardController;
