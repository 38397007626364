import moment from 'moment'
import isEmpty from 'lodash/isEmpty'

class ViewStudentDetailsModalController {

    constructor($uibModalInstance, student, UsersModel){
        this.$uibModalInstance = $uibModalInstance;
        this.UsersModel = UsersModel;
        this.student = {};
        this.nextSession = {};
        this.hasNextSession = false;
        this.loading = true;
        
        UsersModel.getStudentByID(student.id).then(response => {
            //this.student = response.student;
            if(response.usermeta.permit) {
                response.usermeta.permit.issuedate = moment(response.usermeta.permit.issuedate).toDate();
                response.usermeta.permit.expirationdate = moment(response.usermeta.permit.expirationdate).toDate();
            }
            
            response.school.address = JSON.parse(response.school.address);
            let student = response;
            this.student = student;
            this.loading = false;
            this.getNextSession();
        }).catch(error => {
            this.loading = false;
        })
    }

    cancel() {
        this.$uibModalInstance.dismiss( 'cancel' );
    }

    getNextSession() {
        this.UsersModel.getNextStudentEntity( { 'id' : this.student.id } ).then( response => {
            if( isEmpty(response.entity) ){
                this.nextSession = {};
                this.hasNextSession = false;
            } else {
                this.nextSession = {
                    'nextDrive': response.entity,
                    'nextDriveDate': moment(response.entity.date + ' ' + response.entity.time),
                    'untilDrive': moment(response.entity.date + ' ' + response.entity.time).format('h:mm a')
                }
                this.hasNextSession = true;
            }
        } )

    }
}
ViewStudentDetailsModalController.$inject = ['$uibModalInstance', 'student', 'UsersModel'];
export default ViewStudentDetailsModalController;
