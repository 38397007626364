"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var time_1 = require("../../../common/helpers/time");
var json_1 = require("../../../common/helpers/json");
var InstructorViewStudentController = /** @class */ (function () {
    function InstructorViewStudentController(student, usermeta, StudentUsermetaModel, FlashService, PoliciesModel) {
        var _this = this;
        this.student = student;
        this.PoliciesModel = PoliciesModel;
        PoliciesModel.getByID('drives').then(function (response) {
            _this.policies = {
                'drives': {
                    'policymeta': {
                        "instructor_can_view_student_orders": "N"
                    }
                }
            };
            var policyMeta = {
                "instructor_can_view_student_orders": "N"
            };
            response.policy.policymeta.forEach(function (meta) {
                policyMeta[meta.meta_key] = meta.meta_value;
            });
            _this.policies.drives.policymeta = policyMeta;
            _this.busy = false;
            _this.loading = false;
            if (_this.policies.drives.policymeta.instructor_can_view_student_orders === 'Y') {
                _this.canViewStudentOrders = true;
            }
        });
        // let drivespolicy = this.VueStoreFactory.state.policies.policies.find((policy) => {
        //     return policy.name === 'drives'
        // });
        // console.log(drivespolicy)
        this.usermeta = usermeta.map(function (meta) {
            meta.meta_value = (0, json_1.isJson)(meta.meta_value) ? JSON.parse(meta.meta_value) : meta.meta_value;
            return meta;
        });
        this.dob = "";
        var dob = this.usermeta.find(function (meta) { return meta.meta_key === 'dob'; });
        if (dob) {
            this.dob = (0, time_1.dateStringToDate)(dob.meta_value).toLocaleDateString('en-US');
        }
        this.certificate = {
            certificate_number: this.usermeta.find(function (meta) { return meta.meta_key === 'certificate_number'; }).meta_value,
            certificate_issue_date: (0, time_1.dateOrEmptyString)(this.usermeta.find(function (meta) { return meta.meta_key === 'certificate_issue_date'; }).meta_value)
        };
        this.permit = {
            number: this.usermeta.find(function (meta) { return meta.meta_key === 'permit'; }).meta_value.number,
            issuedate: (0, time_1.dateOrEmptyString)(this.usermeta.find(function (meta) { return meta.meta_key === 'permit'; }).meta_value.issuedate),
            expirationdate: (0, time_1.dateOrEmptyString)(this.usermeta.find(function (meta) { return meta.meta_key === 'permit'; }).meta_value.expirationdate)
        };
        this.loading = true;
        this.FlashService = FlashService;
        this.StudentUsermetaModel = StudentUsermetaModel;
        this.student = student;
        this.loading = false;
        this.submitted = false;
    }
    InstructorViewStudentController.prototype.updateStudentCertificate = function () {
        var _this = this;
        var issue_date = "";
        if (this.certificate.certificate_issue_date !== "" && this.certificate.certificate_issue_date !== null) {
            // @ts-ignore
            issue_date = (0, time_1.toYMD)(this.certificate.certificate_issue_date);
        }
        var certificate_issue_date = {
            'user_id': this.student.id,
            'meta_key': 'certificate_issue_date',
            'meta_value': issue_date,
        };
        var certificate_number = {
            'user_id': this.student.id,
            'meta_key': 'certificate_number',
            'meta_value': this.certificate.certificate_number,
        };
        this.StudentUsermetaModel.update(this.student.id, [certificate_issue_date, certificate_number]).then(function (response) {
            _this.submitted = false;
            _this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
        }).catch(function (Error) {
            _this.submitted = false;
        });
    };
    InstructorViewStudentController.prototype.updatePermit = function () {
        var _this = this;
        var issue_date = "";
        if (this.permit.issuedate !== "" && this.permit.issuedate !== null) {
            issue_date = (0, time_1.toYMD)(new Date(this.permit.issuedate));
        }
        var expiration_date = "";
        if (this.permit.expirationdate !== "" && this.permit.expirationdate !== null) {
            expiration_date = (0, time_1.toYMD)(new Date(this.permit.expirationdate));
        }
        var permit = {
            'id': this.usermeta.find(function (meta) { return meta.meta_key === 'permit'; }).id,
            'user_id': this.student.id,
            'meta_key': 'permit',
            'meta_value': JSON.stringify({
                'number': this.permit.number,
                'issuedate': issue_date,
                'expirationdate': expiration_date,
            }),
        };
        if (permit.id === 0) {
            delete permit.id;
        }
        this.StudentUsermetaModel.update(this.student.id, [permit]).then(function (response) {
            _this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
            _this.submitted = false;
        }).catch(function (Error) {
            _this.submitted = false;
        });
    };
    return InstructorViewStudentController;
}());
InstructorViewStudentController.$inject = [
    "student",
    "usermeta",
    "StudentUsermetaModel",
    "FlashService",
    "PoliciesModel"
];
exports.default = InstructorViewStudentController;
