"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var EmailsController = /** @class */ (function () {
    function EmailsController($stateParams, $http, APPURL) {
        var _this = this;
        this.emails = {};
        $http
            .get(APPURL + "/api/v1/users/" + $stateParams.userId + "/email-history")
            .then(function (response) {
            _this.emails.student = response.data.data;
            _this.emails = response.data.data.reduce(function (r, a) {
                r[a.email] = __spreadArray(__spreadArray([], (r[a.email] || []), true), [a], false);
                return r;
            }, {});
        })
            .catch(function (Error) {
            console.error(Error);
        });
    }
    return EmailsController;
}());
EmailsController.$inject = ["$stateParams", "$http", "APPURL"];
exports.default = EmailsController;
