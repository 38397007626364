import moment from 'moment'
import { dateStringToDate } from '../../../common/helpers/time';

class AdminAgendaController {

    constructor($rootScope, UserService, $state, $stateParams, CalendarModel, DrivesFilterService, TimeoffRequestsModel, FlashService, $window, CalendarService, VueStoreFactory, AdminCreateDriveService) {
        this.UserService = UserService;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.VueStoreFactory = VueStoreFactory;
        this.CalendarModel = CalendarModel;
        this.DrivesFilterService = DrivesFilterService;
        this.TimeoffRequestsModel = TimeoffRequestsModel;
        this.FlashService = FlashService;
        this.$window = $window;
        this.CalendarService = CalendarService;
        this.AdminCreateDriveService = AdminCreateDriveService;

        this.modalShown = false; //rto modal
        this.rto = {}
        this.rtoIndex = null;

        this.currentUser = UserService.getCurrentUser();
        this.date = $stateParams.date;
        this.sessionsUpdated = this.sessionsUpdated.bind(this);
        this.handleAgendaResults = this.handleAgendaResults.bind(this);
        this.openRTOModal = this.openRTOModal.bind(this);
        this.closeRTOModal = this.closeRTOModal.bind(this);
        this.deleteRTODay = this.deleteRTODay.bind(this);
        this.deleteRTO = this.deleteRTO.bind(this);
        this.visitCourse = this.visitCourse.bind(this);

        this.DrivesFilterService.setDate(this.date);

        // View drives by date
        //not sure whta this is doing but i think it can be deleted
        this.agendaData = {
            'instructor_id': this.currentUser.id,
            'date': $stateParams.date
        };

        this.filterQuery = DrivesFilterService.getFilterParameters();
        this.filterQuery.statusIsnt = 'Inactive - Scheduling Conflict';
        if(!this.filterQuery.status) {
            this.filterQuery.status = 'all'
        }

        this.items = [];
        this.messengerUsers = [];

        // console.log('this.filterquery', this.filterQuery)

        this.CalendarModel.getAdminAgenda(this.filterQuery).then(this.handleAgendaResults);
        $rootScope.$on('UPDATE_AGENDA', this.sessionsUpdated);
    }

    createLessonOnDate()
    {
        this.AdminCreateDriveService.setDate(dateStringToDate(this.date));
        this.$state.go('drivecoach.admin.drives.create');
    }

    setSelectedUsers()
    {
        this.messengerUsers = this.items.filter(item => {
            return item.selected === true && item.student;
        }).map(item => {
            item.student.name = item.student.firstname + ' ' + item.student.lastname;
            return item.student;
        });
    }

    setSectorPopover(zone, sectors){
        this.zone = zone;
        this.sectors = sectors.filter(sector => {
            return sector.zone_id == zone.id;
        });
    }

    next() {
        this.$state.go('drivecoach.admin.agenda', {
            'date': moment(this.date).add(1, 'days').format('YYYY-MM-DD')
        })
    }

    prev() {
        this.$state.go('drivecoach.admin.agenda', {
            'date': moment(this.date).subtract(1, 'days').format('YYYY-MM-DD')
        })
    }

    isDriveSession(item) {
        return item.type === 'Drive Session';
    }

    isCourse(item) {
        return item.type === 'Course';
    }

    isEvent(item) {
        return item.type === 'Event';
    }

    isRTO(item) {
        return item.typeDisplay === 'RTO';
    }

    openRTOModal(rto, index) {
        this.modalShown = true;
        this.rto = rto;
        this.rtoIndex = index
    }

    closeRTOModal() {
        this.modalShown = false;
        this.rto = {};
        this.rtoIndex = null;
    }

    visitCourse(course){
        let data = {
            id: course.id,
            class_id: course.class_id,
            date:  new Date(course.date + ' 00:00:00').toLocaleDateString(),
            start: course.startTime,
            end: course.endTime,
        }
        this.VueStoreFactory.dispatch('SET_VIEW_COURSE', data);
        this.$state.go('drivecoach.admin.courses.view', { 'courseId' : course.id })
    }

    deleteRTODay(rto, index){
        const confirmDelete = this.$window.confirm('Are you sure you want to delete this day in this request? It cannot be undone.');
        if( ! confirmDelete ){
            return;
        }

        rto.deleteDay = this.date;

        this.TimeoffRequestsModel.deleteDay(rto).then((response) => {
            this.FlashService.setMessage({
                'type' : 'success',
                'message' : response.message
            });
            this.items.splice(index, 1);
            this.closeRTOModal();
            // $state.go('drivecoach.admin.calendar', {}, {'reload': true});
        })

    }

    deleteRTO(rto, index) {
        const confirmDelete = this.$window.confirm('Are you sure you want to delete this time off request? It cannot be undone.');
        if (!confirmDelete) {
            return;
        }
        this.TimeoffRequestsModel.delete(rto).then(response => {
            this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
            this.items.splice(index, 1);
            this.closeRTOModal();
            // this.$state.go('drivecoach.admin.calendar', {}, {'reload': true});
        })
    }

    sessionsUpdated() {
        // cal analog is this.CalendarService.getCalendarItems();
        this.items = this.CalendarService.getAgendaItems();
    }

    handleAgendaResults(response) {
        // console.log('response', response)
        this.DrivesFilterService.setDate(this.date);
        this.items = this.CalendarService.processAgenda(response);

        // console.log('final agenda items', this.items)
    }

}

AdminAgendaController.$inject = ['$rootScope', 'UserService', '$state', '$stateParams', 'CalendarModel', 'DrivesFilterService', 'TimeoffRequestsModel', 'FlashService', '$window', 'CalendarService', 'VueStoreFactory', 'AdminCreateDriveService'];

export default AdminAgendaController;
