import angular from 'angular'
import Vue from 'vue';
import 'ngVue';

import dcCreateCourse from './CreateCourse.vue'

import AdminCoursesCreateController from './create-course.controller'
import template from './admin-create-course.html'

function getCourseTypes(CourseTypesModel){
  return CourseTypesModel.get().then(function(response){
    // console.log('response', response)
      return response.courseTypes.filter(type => type.status === 'Active');
  })
}
getCourseTypes.$inject = ['CourseTypesModel'];

function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.admin.courses.create', {
            url : '/create',
            template : template,
            controller : AdminCoursesCreateController,
            controllerAs : 'vm',
            resolve: {
                courseTypes : getCourseTypes
            },
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'admin.courses.create', [])
    .config(config)
    .controller( 'AdminCoursesCreateController', AdminCoursesCreateController )
    .directive('dcCreateCourse', ['createVueComponent', function (createVueComponent) {
      return createVueComponent(Vue.component('dcCreateCourse', dcCreateCourse))
    }])
;
