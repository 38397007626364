import moment from 'moment'
import angular from 'angular'
import { toQueryString } from '../helpers/queryString';
function DrivesModel( $http, APPURL ) {

    let model = this,
        URLS = {
            FETCH : APPURL + '/api/v1/drives',
            UPDATE : APPURL + '/api/v1/drives',
            CREATE : APPURL + '/api/v1/drives'
        },
        drives,
        drive;

    function extract( result ) {
        return result.data;
    }

    function cacheResults( result ) {
        drives = extract( result );

        return drives;
    }

    function cacheDrive(result) {
        drive = extract(result);
        if (typeof drive.address !== 'undefined') {
            drive.address = angular.fromJson(drive.address);
            drive.pickuplocation.address = angular.fromJson(drive.pickuplocation.address);
        }
        if (drive.locations) {
            drive.locations.forEach(location => {
                location.address = angular.fromJson(location.address);
            })
        }
        return drive;
    }

    model.fixIndex = () => {
        setTimeout(() => {
            let $days = $('dc-drive-calendar .days > div');
            let len = $days.length;
            for (let i = len - 1, itm; i >= 0; i -= 1) {
                itm = $days[i];
                itm.style.zIndex = (7 - i % 7) * parseInt( i / 7 + 1 );
            }
        }, 550);

    };

    model.getDrivesByLocations = (locations) => {
        let locationIds = [];
        model.getDrives().then(response => {
            locations.forEach(location => {
                locationIds.push(location.id);
            });
            response.filter(drive => {

            })
        } );

    };

    model.getDriveByID = (id) => {
        return $http.get(URLS.FETCH + '/' + id).then(cacheDrive);
    };

    model.claimDrive = (data) => {
        data['currentTime'] = moment().unix();
        return $http.post(URLS.FETCH + '/claim', data).then(cacheResults);
    };

    model.getDriveReportCard = (id, student_id) => {
        return $http.get(URLS.FETCH + '/' + id + '/reportcard?student_id=' + student_id).then(cacheResults);
    };

    model.getDrives = () => {
        return $http.get(URLS.FETCH).then(cacheResults);
    };

    model.getDriveMeta = (id) => {
        return $http.get(URLS.FETCH + '/' + id + '/meta').then(cacheResults);
    }

    model.createDrive = (drive) => {
        return $http.post( URLS.CREATE, drive ).then(cacheResults);
    };

    model.batchCreate = (drives) => {
        return $http.post(URLS.CREATE + '/batch', drives).then(cacheResults);
    };

    model.getDrivesByDate = (date) => {
        return $http.get(URLS.FETCH).then(response => {
            return response.data.filter(drive => {
                return drive.drive_date === date;
            } )
        } )
    };

    model.getDrivesRequiringAction = (data) => {
        return $http.get(URLS.FETCH + "/requiring-action?" + toQueryString(data)).then(response => {
            return response.data;
        });
    };

    model.deleteDrive = (id) => {
        return $http.delete(URLS.FETCH + '/' + id).then(cacheResults);
    };

    model.updateDrive = (drive) => {
        return $http.put(URLS.FETCH + '/' + drive.id, drive).then(cacheDrive);
    };

    model.cancelDriveSession = (data) => {
        return $http.post(URLS.FETCH + '/cancel/' + data.id, data).then(cacheDrive);
    };

    model.complete = (data) => {
        return $http.put(URLS.FETCH + '/' + data.id + '/complete', data).then(cacheDrive);
    };

    model.completeDrivingLessons = (data) => {
        return $http.post(URLS.FETCH + '/complete', data).then(cacheDrive);
    };

    model.filterDrives = (params) => {
        return $http.post(URLS.FETCH + '/filter', params).then(cacheResults);
    };

    model.search = (params) => {
        return $http.post(URLS.FETCH + '/search', params).then(cacheResults);
    }

}
DrivesModel.$inject = ['$http', 'APPURL'];
export default DrivesModel;
