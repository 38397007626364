<template>
  <section class="container-fluid">
    <div class="row">
      <div class="col-xs-12">
        <form @submit.prevent="submit()">
          <fieldset>
            <div class="form-group">
              <label for="card_number">Card Number</label>
              <input
                class="form-control"
                v-model="payment.number"
                id="card_number"
              />
            </div>
            <div class="form-group row">
              <section>
                <div class="col-xs-12 col-md-4">
                  <label for="expiration_month">Expiration Month</label>
                  <select
                    class="form-control"
                    id="expiration_month"
                    v-model="payment.expiration_month"
                    required
                  >
                    <option
                      v-for="(opt, $index) in months"
                      :key="$index"
                      :value="opt.value"
                    >
                      {{ opt.name }}
                    </option>
                  </select>
                </div>
                <div class="col-xs-12 col-md-4">
                  <label for="expiration_year">Expiration Year</label>
                  <select
                    class="form-control"
                    v-model="payment.expiration_year"
                    id="expiration_year"
                    required
                  >
                    <option
                      v-for="(opt, $index) in years"
                      :key="$index"
                      :value="opt.value"
                    >
                      {{ opt.name }}
                    </option>
                  </select>
                </div>
              </section>

              <div class="col-xs-12 col-md-4">
                <label for="cvc">CVC</label>
                <input
                  class="form-control"
                  required
                  v-model="payment.cvc"
                  id="cvc"
                />
              </div>
            </div>
          </fieldset>
          <div class="text-center">
            <button class="btn btn-dc" style="margin-top: 2em">Submit</button>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    apiClientKey: { type: String, default: "" },
    apiLoginId: { type: String, default: "" },
    months: { type: Array, default: [{ name: "", value: "" }] },
    years: { type: Array, default: [{ name: "", value: "" }] },
  },
  data() {
    return {
        payment: {
            number: "",
            expiration_month: "",
            expiration_year: "",
            cvc: ""
        }
    };
  },
  mounted() {},
  methods: {
    submit() {
      var authData = {};
      authData.clientKey = this.apiClientKey;
      authData.apiLoginID = this.apiLoginId;
      var cardData = {};
      cardData.cardNumber = this.payment.number;
      cardData.month = this.payment.expiration_month;
      cardData.year = this.payment.expiration_year;
      cardData.cardCode = this.payment.cvc;

      // If using banking information instead of card information,
      // build a bankData object instead of a cardData object.
      //
      // var bankData = {};
      //     bankData.accountNumber = document.getElementById('accountNumber').value;
      //     bankData.routingNumber = document.getElementById('routingNumber').value;
      //     bankData.nameOnAccount = document.getElementById('nameOnAccount').value;
      //     bankData.accountType = document.getElementById('accountType').value;

      var secureData = {};
      secureData.authData = authData;
      secureData.cardData = cardData;
      // If using banking information instead of card information,
      // send the bankData object instead of the cardData object.
      //
      // secureData.bankData = bankData;

      Accept.dispatchData(secureData, this.responseHandler);
    },
    responseHandler(response) {
      if (response.messages.resultCode === "Error") {
        console.log(response.messages);
        var i = 0;
        while (i < response.messages.message.length) {
          console.log(
            response.messages.message[i].code +
              ": " +
              response.messages.message[i].text
          );
          i = i + 1;
        }
      } else {
        this.paymentFormUpdate();
        this.tokenCreated(response.opaqueData.dataDescriptor, response.opaqueData.dataValue);
      }
    },
    paymentFormUpdate(opaqueData) {
      // document.getElementById("dataDescriptor").value = opaqueData.dataDescriptor;
      // document.getElementById("dataValue").value = opaqueData.dataValue;

      // reset fields
      this.payment.number = "";
      this.payment.expiration_month = "";
      this.payment.expiration_year = "";
      this.payment.cvc = "";

      //   document.getElementById("accountNumber").value = "";
      //   document.getElementById("routingNumber").value = "";
      //   document.getElementById("nameOnAccount").value = "";
      //   document.getElementById("accountType").value = "";

      // send to server
      //   document.getElementById("paymentForm").submit();
    },
    tokenCreated(dataDescriptor, dataValue) {
      this.$emit("cardTokenCreated", {
        dataDescriptor: dataDescriptor,
        dataValue: dataValue,
      });
    },
    pay() {
      this.$refs.paymentRef.submit();
    },
  },
};
</script>
  
