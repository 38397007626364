import moment from "moment";

class dsStudentInfoOverviewController {
    constructor(UserService) {
        this.currentUser = UserService.getCurrentUser();
    }

    getStudentAge() {
        if (this.student === null) {
            return 100;
        }
        if (typeof this.student.usermeta === 'undefined') {
            return 100;
        }
        if( typeof this.student.usermeta.dob === 'undefined' ){
            return 100;
        }
        let dob = moment(this.student.usermeta.dob, 'YYYY-MM-DD' ).toDate();
        return moment( new Date() ).diff( moment( dob ), 'years' );
    }

}
dsStudentInfoOverviewController.$inject = ['UserService'];
export default dsStudentInfoOverviewController;
