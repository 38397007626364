<template>
  <div>
    <quill-editor :content="content"
                  ref="myQuillEditor"
                  :options="editor.options"
                  :disabled="editor.disabled"
                  @change="onEditorChange($event)"
                  @blur="onEditorBlur($event)"
                  @focus="onEditorFocus($event)"
                  @ready="onEditorReady($event)">
    </quill-editor>
  </div>
</template>

<script>
  // import ChildComponent from 'path/to/child'
  import 'quill/dist/quill.core.css'
  import 'quill/dist/quill.snow.css'
  import 'quill/dist/quill.bubble.css'

  import { quillEditor } from 'vue-quill-editor'

  export default {
    name: 'dcTextEditor', // this becomes the ngvue directive markup aka <dc-component-name></dc-component-name>
    components: { quillEditor },
    props: {
      'content' : { type: String, default: ''},
      'showElement': { type: Boolean, default: true },
      'placeholder' : { type: String },
      'readOnly': { type: Boolean, default: false },
      'disabled': { type: Boolean, default: false }
    },
    data() {
      return {
        toolbarOptions: [
          ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
          // ['blockquote', 'code-block'],

          [{ 'header': 1 }, { 'header': 2 }],               // custom button values
          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
          // [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
          [{ 'direction': 'rtl' }],                         // text direction

          [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

          [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
          [{ 'font': [] }],
          [{ 'align': [] }],

          ['link'],

          ['clean']                                         // remove formatting button
        ]
      }
    },
    methods: {
      onEditorBlur(quill) {
        // console.log('editor blur!', quill)
      },
      onEditorFocus(quill) {
        // console.log('editor focus!', quill)
      },
      onEditorReady(quill) {
        // console.log('editor ready!', quill)
//        this.editor.content = this.content;
      },
      onEditorChange({ quill, html, text }) {
        this.$emit('editor-content-change', {html:html, text:text})
      }
    },
    mounted () {
      // console.log('mounted')
    },
    destroyed () {
      // console.log('destroyed')
    },
    created () {
      // console.log('created')
      // console.log('content')
      // console.log(this.content)
    },
    computed: {
      editor: function() {
        return {
          options: {
            modules: {
              toolbar: this.toolbarOptions
            },
            placeholder: this.placeholder ? this.placeholder : 'Insert text here...',
            readOnly: this.readOnly,
          },
          disabled: this.disabled
        }
      },
    }
  }
</script>

<style lang="scss">
  .form-group--error .quill-editor {
    border: 1px solid red !important;
  }
</style>