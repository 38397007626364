import {isJson} from "../../../../common/helpers/json";
import {States} from "../../../../common/constants/states"

class AdminStudentPickupLocationsController {

    constructor(UserService, $stateParams, UsersModel, FlashService, LocationsModel, $q, SchoolsModel) {
        this.UserService = UserService;
        this.$stateParams = $stateParams;
        this.UsersModel = UsersModel;
        this.FlashService = FlashService;
        this.LocationsModel = LocationsModel;
        this.$q = $q;
        this.currentUser = UserService.getCurrentUser();
        this.busy = false;
        this.loading = true;
        this.states = States;
        this.resetPickupLocationInServiceArea = this.resetPickupLocationInServiceArea.bind(this)
      
        let getLocations = LocationsModel.getLocations();
        let getPickupLocations = UsersModel.getPickupLocations( $stateParams.studentId );
        let getSchool = SchoolsModel.getSchoolByID(this.currentUser.school_id);
        this.school = this.currentUser.school;
        let getMap = SchoolsModel.getMap(this.school.id, {student_id: $stateParams.studentId});

        if(isJson(this.school.address)) {
          this.school.address = JSON.parse(this.school.address);
        }
        
        //this just sets a default center while the pickup addresses load
        this.center = {
            lat: this.school.address.lat,
            lng: this.school.address.lng,
            zoom: 2
        };
        
        this.mapLocations = [];
        $q.all([getLocations, getPickupLocations, getSchool, getMap]).then(response => {
            const mapData = response[3];
            this.mapData = response[3];
            this.locations = mapData.locations;
            
            this.school.locations = this.locations;
            this.currentUser.school.locations = this.locations;
            this.currentUser.school.zones = response[2].zones;
            let data = response[1];
            this.center = mapData.center;
            
            this.load(data);
        })

    }

    load(response) {
        if(response.pickuplocations.length > 0) {
            this.pickupLocations = response.pickuplocations;
            this.mapLocations.push(...this.pickupLocations);
            this.center = {
                lat: this.pickupLocations[0].address.lat,
                lng: this.pickupLocations[0].address.lng,
                zoom: 2
            };
        }
        this.loading = false;
    }

    resetPickupLocationInServiceArea() {
        this.UsersModel.resetPickupLocationInServiceArea(this.$stateParams.studentId).then(response => {
            this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
            this.busy = false;
        }).catch(Error => {
            this.busy = false;
        });
    }

    removePickupLocation(id) {
        this.busy = true;
        
        let data = {
            'user_id': this.$stateParams.studentId,
            'location_id': id
        };
        this.UsersModel.unattachStudentPickupLocation(data.user_id, data.location_id).then(response => {
            this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
            this.pickupLocations = response.pickuplocations.sort((a, b) => {
                return a.pivot.sequence - b.pivot.sequence
            })
            this.mapLocations = [];
            this.mapLocations.push(...this.pickupLocations);
            this.busy = false;
            this.pickupLocation = this.pickupLocations[0];
        }).catch(error => {
            this.busy = false;
        });
    }

    updatePickupLocation(location){
        this.busy = true;
        
        let data = Object.assign({}, location);
        data.sequence = location.pivot.sequence;
        this.UsersModel.updatePickUpLocation(this.$stateParams.studentId, location.id, data).then(response => {
            this.busy = false;
            this.FlashService.setMessage({
                'type' : 'success',
                'message' : response.message
            })
            this.pickupLocations = this.pickupLocations.sort((a, b) => {
                return a.pivot.sequence - b.pivot.sequence
            })
            this.mapLocations = [];
            this.mapLocations.push(...this.pickupLocations);
        }).catch(error => {
            this.busy = false;
        })
    }
    
}
AdminStudentPickupLocationsController.$inject = ['UserService', '$stateParams', 'UsersModel', 'FlashService', 'LocationsModel', '$q', 'SchoolsModel'];
export default AdminStudentPickupLocationsController
