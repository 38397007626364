"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Product_1 = require("../../../classes/Product");
var Student_1 = require("../../../classes/Student");
var Coupon_1 = require("../../../classes/Coupon");
var Cashier_1 = require("../../../classes/Cashier");
var Order_1 = require("../../../classes/Order");
var InstructorCreateOrderController = /** @class */ (function () {
    function InstructorCreateOrderController($scope, $state, ProductsModel, CouponsModel, OrdersModel, FlashService, UsersModel, CreateOrderService) {
        var _this = this;
        this.CreateOrderService = CreateOrderService;
        this.$state = $state;
        this.FlashService = FlashService;
        this.OrdersModel = OrdersModel;
        this.CouponsModel = CouponsModel;
        this.UsersModel = UsersModel;
        this.product = null;
        this.student = null;
        this.products = [];
        this.coupons = [];
        this.submitting = false;
        this.couponConfig = {
            loading: true,
            coupon: {
                code: "",
                max_redemptions: null,
                description: "",
                type: "",
                amount: null,
                start: null,
                end: null
            },
            showNewCouponForm: false,
        };
        this.handleCouponStartDateChange = this.handleCouponStartDateChange.bind(this);
        this.handleCouponEndDateChange = this.handleCouponEndDateChange.bind(this);
        this.$cashier = CreateOrderService.cashier();
        var order = new Order_1.Order();
        this.$cashier.setOrder(order);
        this.$order = this.$cashier.$order;
        ProductsModel.get({ 'paging': false, 'disabled': false }).then(function (response) {
            _this.products = response.products.data.map(function (product) { return new Product_1.Product(product); });
            _this.loading = false;
        });
        CouponsModel.get({ 'paging': 0, 'valid': 1 }).then(function (response) {
            _this.coupons = response.coupons.map(function (coupon) { return new Coupon_1.Coupon(coupon); });
            _this.couponConfig.loading = false;
        });
        this.createOrder = this.createOrder.bind(this);
        $scope.$watch(function () { return _this.student; }, function (newVal) {
            if (!_this.student)
                return;
            _this.$cashier.addStudent(new Student_1.Student(_this.student));
            CreateOrderService.updateBillingAddressesList();
            CreateOrderService.updatePaymentMethodsList();
        });
    }
    InstructorCreateOrderController.prototype.handleCouponStartDateChange = function (datetime) {
        if (datetime.selected.length === 0) {
            this.couponConfig.coupon.start = null;
        }
        else {
            this.couponConfig.coupon.start = datetime.selected[0];
        }
    };
    InstructorCreateOrderController.prototype.handleCouponEndDateChange = function (datetime) {
        if (datetime.selected.length === 0) {
            this.couponConfig.coupon.end = null;
        }
        else {
            this.couponConfig.coupon.end = datetime.selected[0];
        }
    };
    InstructorCreateOrderController.prototype.createCoupon = function () {
        var _this = this;
        this.couponConfig.loading = true;
        var coupon = Object.assign({}, this.couponConfig.coupon);
        if (coupon.type === 'fixed') {
            coupon.amount = coupon.amount * 100;
        }
        this.CouponsModel.create(coupon).then(function (response) {
            _this.couponConfig.loading = false;
            var newCoupon = new Coupon_1.Coupon(response.coupon);
            _this.coupons.push(newCoupon);
            _this.FlashService.setMessage({
                'message': response.message,
                'type': 'success'
            });
            _this.couponConfig.coupon = {
                code: "",
                max_redemptions: null,
                description: "",
                type: "",
                amount: null,
                start: null,
                end: null
            };
            _this.addCouponToOrder(newCoupon);
        });
    };
    InstructorCreateOrderController.prototype.reset = function () {
        this.loading = false;
        this.submitting = false;
    };
    InstructorCreateOrderController.prototype.createOrder = function (order) {
        var _this = this;
        this.submitting = true;
        order.submitted = true;
        var data = (0, Cashier_1.cashierOrderRequestTransformer)(this.$cashier);
        // TODO: Send info to tokenizer: AcceptJS, Stripe, Paypal, ETC
        this.OrdersModel.create(data).then(function (response) {
            _this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
            _this.$state.go('drivecoach.instructor.dashboard');
            _this.reset();
        }).catch(function (Error) {
            order.submitted = false;
        });
    };
    InstructorCreateOrderController.prototype.addProductToOrder = function () {
        this.$cashier.addProduct(this.product);
        // set initial value for "amount paid today" field
        this.updatePaymentAmountInput();
        this.$cashier.calculateTotal();
    };
    /**
     * Make the payment amount input match the total due (surcharges not applied)
     */
    InstructorCreateOrderController.prototype.updatePaymentAmountInput = function () {
        this.$cashier.order().payments()[0].setAmount(this.$cashier.totalDue());
    };
    InstructorCreateOrderController.prototype.addCouponToOrder = function (coupon) {
        this.$cashier.addCoupon(coupon);
        this.$cashier.calculateTotal();
        this.updatePaymentAmountInput();
    };
    return InstructorCreateOrderController;
}());
InstructorCreateOrderController.$inject = ['$scope', '$state', 'ProductsModel', 'CouponsModel', 'OrdersModel', 'FlashService', 'UsersModel', 'CreateOrderService'];
exports.default = InstructorCreateOrderController;
