import angular from 'angular'
import Vue from 'vue';
import 'ngVue';

//need to import style json this so it will get loaded by webpack
import '../../map/map-style-2019.json'
import dcMap from './Map.vue'

//////// vue 2 leaflet 
import { Icon }  from 'leaflet'
import 'leaflet/dist/leaflet.css'

/////// leaflet fullscreen
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';
import 'leaflet-fullscreen/dist/Leaflet.fullscreen';

// this part resolve an issue where the markers would not appear
delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});
/////////

import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
window.mapboxgl = mapboxgl
import 'mapbox-gl-leaflet';

export default angular.module( 'directives.map', [] )
  .directive('dcMap', ['createVueComponent', function (createVueComponent) {
    return createVueComponent(Vue.component('dcMap', dcMap))
  }])
