import { toQueryString } from "../helpers/queryString";

function OrdersModel( $http, APPURL ) {

    let model = this,
        URLS = {
            FETCH : APPURL + '/api/v1/crm/orders',
            UPDATE : APPURL + '/api/v1/crm/orders',
            CREATE : APPURL + '/api/v1/crm/orders'
        },
        events;

    function extract( result ) {
        return result.data;
    }

    function cacheResults( result ) {
        events = extract( result );
        return events;
    }

    model.show = ( id ) => {
        return $http.get( URLS.FETCH + '/' + id ).then( cacheResults );
    };

    model.getDetails = (order) => {
        return $http.get(`${URLS.FETCH}/${order.id}/details`).then(cacheResults)
    }

    model.get = (data=null) => {
        if(data !== null){
            return $http.get(URLS.FETCH + '?' + toQueryString(data)).then( cacheResults );
        }
        return $http.get( URLS.FETCH ).then( cacheResults );
    };

    model.update = ( request ) => {
        return $http.put( URLS.FETCH + '/' + request.id, request ).then( cacheResults );
    };

    model.create = ( request ) => {
        return $http.post( URLS.FETCH, request ).then( cacheResults );
    };

    model.delete = ( data ) => {
        return $http.delete( URLS.FETCH + '/' + data.id ).then( cacheResults );
    };

    model.search = ( params ) => {
        return $http.post( URLS.FETCH + '/search', params ).then( cacheResults );
    };

    model.submitPayment = (data) => {
        return $http.post(`${URLS.FETCH}/${data.order_id}/payments`, data).then( cacheResults );
    }

    model.reports = (data) => {
        return $http.get(`${URLS.FETCH}/reports?${toQueryString(data)}`).then(cacheResults);
    }

    model.voidOrder = order_id => {
        return $http.post(`${URLS.FETCH}/${order_id}/void`).then(cacheResults);
    }

    model.sendOrder = (order_id, data) => {
        return $http.post(`${URLS.FETCH}/${order_id}/send`, data).then(cacheResults);
    }
    
    model.printOrder = order_id => {
        return $http.post(`${URLS.FETCH}/${order_id}/print`).then(cacheResults);
    }

    model.validateOrderForm = (data) => {
        return $http.post(`${APPURL}/api/v1/crm/order-form/validate`, data).then(cacheResults);
    }

    model.validatePaymentForm = (data) => {
        if (typeof data.order_id === "undefined") {
            // console.log(data)
            throw Error('You must have an order ID in order to validate this form.')
        }
        let order_id = data.order_id;
        delete data.order_id;
        return $http.post(`${APPURL}/api/v1/crm/orders/${order_id}/payments/validate`, data).then(cacheResults);
    }

    model.getClientToken = () => {
        return $http.post(`${APPURL}/api/v1/crm/order-form/token`).then(cacheResults);
    }

    model.salesReports = (params) => {
        if(params !== null){
            return $http.get( URLS.FETCH + '/sales-reports?' + toQueryString(params)).then( cacheResults );
        }
        return $http.get( URLS.FETCH + '/sales-reports').then( cacheResults );
    }

}
OrdersModel.$inject = ['$http', 'APPURL'];
export default OrdersModel;