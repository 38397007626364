import moment from 'moment'
import { isJson } from '../../../../common/helpers/json'
class AdminStudentDrivesController {

    constructor($stateParams, UsersModel, $window){
        this.date = new Date();
        this.currentPage = 1;
        this.$window = $window;
        this.UsersModel = UsersModel;

        this.handleStudentDrives = this.handleStudentDrives.bind(this);
        this.formatDrive = this.formatDrive.bind(this);

        UsersModel.getStudentDrives({'id': $stateParams.studentId, 'page_results': false})
            .then(this.handleStudentDrives);
        UsersModel.getStudentByID($stateParams.studentId).then(response => {
            this.student = response;
        });

    }

    formatDrive(drive) {
        const minutes = moment.duration(drive.session_length, 'minutes');
        drive.startTime = moment(drive.start_time, 'HH:mm:s').format('h:mm a');
        drive.endTime = moment(drive.start_time, 'HH:mm:s').add(minutes, 'minutes').format('h:mm a');
        drive.duration = moment.duration(drive.session_length, 'minutes').asHours();
        drive.duration = moment.duration(drive.duration, 'hours').humanize();
        if (drive.instructor) {
            let license = drive.instructor.usermeta.filter(usermeta => {
                return usermeta.meta_key === 'license';
            })[0].meta_value;
            drive.instructor.license = isJson(license) ? JSON.parse(license).number : license;
        }
        if( drive.observation === true ){
            drive.type.name = drive.type.name + ' (Observation)'; 
        }
        return drive;
    }

    handleStudentDrives(response) {
        this.drives = response.drives.filter(this.formatDrive);
    }

    print() {
        this.$window.print();
    }

}
AdminStudentDrivesController.$inject = ['$stateParams', 'UsersModel', '$window'];
export default AdminStudentDrivesController;