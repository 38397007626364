import { States } from '../../../common/constants/states'
import { PaymentMethods } from "../../../common/constants/payment_methods";
import { Months, Years } from "../../../common/constants/months_and_years";
import { createBillingGatewayFromPolicies } from '../../../classes/billing/BillingGateway';
import { inDollars } from '../../../../DriveScout/Billing/Teller';
import { loadStripe } from '@stripe/stripe-js'
import { Product } from '../../../classes/Product';
import { Coupon } from '../../../classes/Coupon';
import { Student } from '../../../classes/Student';

class dsOrderFormController {

    constructor($rootScope, $scope, $location, UsersModel, FlashService, CreateOrderService, SchoolsModel) {
        this.loading = true;
        this.submitting = false;
        this.inDollars = inDollars.bind(this);
        this.coupons = [];
        this.CreateOrderService = CreateOrderService;
        this.SchoolsModel = SchoolsModel;
        this.UsersModel = UsersModel;
        this.FlashService = FlashService;
        this.billingAddressDoesNotAlreadyExist = false;
        this.gateway = 'none';
        this.gateway_config = {};
        this.returnUrl = $location.absUrl();

        this.surchargeAmountBilled = 0;

        this.handleAuthorizeCard = this.handleAuthorizeCard.bind(this);
        this.handleCompletedPaymentIntent = this.handleCompletedPaymentIntent.bind(this);
        this.setStudentCart = this.setStudentCart.bind(this);

        /**
         * The coupon the admin selects to add to the order.
         */
        this.selectedCoupon = {};

        //////////////////////////////////

        /** @type Cashier */
        this.$cashier = this.CreateOrderService.cashier();

        $scope.$watch(() => this.$cashier.$order.$student, newVal => {
            if (!this.$cashier.$order.$student) return;
            if (this.gateway === 'stripe') {
                this.returnUrl = $location.absUrl() + '?student_id=' + this.$cashier.order().student().id();
                this.getPaymentIntent();
            }
        });

        const policies = this.$cashier.policies();
        const billingPolicy = policies.getPolicyByName('billing');
        if (billingPolicy.metaExistsByKey('billing_gateway')) {
            this.gateway = billingPolicy.getMetaValueByKey('billing_gateway');
            this.gateway_config = createBillingGatewayFromPolicies(policies).config();
        }

        if (this.gateway === "stripe") {
            this.intent = {}
            this.setupIntentClientSecret = "";
            let student_id = 0;
            try {
                const queryString = new URLSearchParams(window.location.search);
                if (queryString.has("setup_intent_client_secret")) {
                    this.setupIntentClientSecret = queryString.get("setup_intent_client_secret");
                }
                if (queryString.has("student_id")) {
                    student_id = queryString.get("student_id");
                }
            } catch (Error) {
                this.setupIntentClientSecret = "";
                student_id = 0;
            }

            if (this.setupIntentClientSecret !== "" && student_id != 0) {
                this.handleCompletedPaymentIntent(student_id).then(response => {
                    $scope.$apply(() => {
                        this.setupIntentMessage = response.message,
                        this.setupIntentStatus = 'success';
                    });
                });
            }
        }

        // set surcharge data
        this.surchargeBilledSeparately = this.$cashier.surchargeBilledSeparately();
        this.surchargeAmount = this.$cashier.surchargeAmount();
        this.surchargeDetail = this.$cashier.surchargeDetail();

        // set payment methods
        this.payment_methods = [].concat(PaymentMethods);
        if (this.gateway === "none") {
            this.payment_methods = this.payment_methods.filter(method => {
                return method.value !== 'card';
            });
        }
        this.months = Months;
        this.years = Years;
        this.states = States;

        this.loading = false;
    }

    getPaymentIntent() {
        this.UsersModel.getStudentPaymentSetupIntent(this.$cashier.order().student().id()).then(response => {
            this.intent = response.intent;
        });
    }

    setStudentCart()
    {
        let payload = {
            products: [].concat(this.$cashier.order().products().map(product => {
                return { id: product.id() }
            })),
        }
        if (this.$cashier.order().coupon() != null) {
            payload.coupon = {
                id: this.$cashier.order().coupon().id()
            }
        }
        return this.UsersModel.setStudentCart(this.$cashier.order().student().id(), payload).then(response => {
            console.log(response)
        }).catch(Error => {
            console.error(Error)
        })
    }

    async handleCompletedPaymentIntent(student_id)
    {
        let stripe = await loadStripe(this.gateway_config.stripe_publishable_key);

        const { error, setupIntent } = await stripe.retrieveSetupIntent(
            this.setupIntentClientSecret,
        );

        let { cart } = await this.UsersModel.getStudentCart(student_id);
        let {products, coupon, student} = cart;
        const $products = products.map(p => new Product(p));
        const $student = new Student(student);
        this.$cashier.order().setProducts($products);
        this.$cashier.order().setStudent($student);
        if (coupon) {
            const $coupon = new Coupon(coupon);
            this.$cashier.order().setCoupon($coupon);
        }
        // debugger;

        this.setupIntent = setupIntent;
        if (error) {
            console.info('stripe error');
            console.error(error);
        }

        // set token data on order
        this.$cashier.order().$payments = this.$cashier.order().payments().map(payment => {
            if (payment.paymentMethod() === 'card') {
                payment.creditCard().tokenize(setupIntent.payment_method);
            }
            return payment;
        });

        // debugger;
        return this.submit(this.$cashier.order());
    }

    handleAuthorizeCard(tokenData)
    {
        let valid = this.$cashier.order().products().length === 0 || !this.$cashier.order().student() || this.$cashier.order().submitted === true;
        if (!valid) {
            // fail
        }

        // set token data on order
        this.$cashier.order().$payments = this.$cashier.order().payments().map(payment => {
            if (payment.paymentMethod() === 'card') {
                payment.creditCard().tokenize(tokenData);
            }
            return payment;
        });

        this.submit(this.$cashier.order());
    }

    removeCoupon(){
        this.$cashier.removeCoupon();
        this.$cashier.order().payments()[0].setAmount(this.$cashier.totalDue());
        this.$cashier.calculateTotal();
    }

    removeProductFromOrder(product) {
        this.$cashier.removeProduct(product);

        let oldPaymentAmount = this.$cashier.order().payments()[0].amount()
        let newTotalWithoutSurcharge = this.$cashier.totalDue()
        if(oldPaymentAmount > newTotalWithoutSurcharge) {
            this.$cashier.order().payments()[0].setAmount(newTotalWithoutSurcharge);
        }

        this.$cashier.calculateTotal();
    }

    /**
     * Set the billing address of the given payment at the index provided.
     * @param {number} index
     * @param {} address
     */
    addBillingAddress(index, address) {
        this.$cashier.addBillingAddress(index, address);
        this.$cashier.calculateTotal();
    }

    selectPaymentMethod(id, paymentIndex) {
        this.$cashier.setCustomerPaymentMethod(id, paymentIndex);
    }
}
dsOrderFormController.$inject = ['$rootScope', '$scope', '$location', 'UsersModel', 'FlashService', 'CreateOrderService', 'SchoolsModel'];
export default dsOrderFormController;
