import moment from 'moment'
import {isJson} from "../../../../../common/helpers/json";

class AdminStudentViewOrdersController {

    constructor($stateParams, UsersModel){
        this.$stateParams = $stateParams;
        this.loading = true;
        this.UsersModel = UsersModel;
        this.currentPage = 1;

        UsersModel.getStudentByID($stateParams.userId).then(response => {
            if (response.usermeta.hasOwnProperty('permit')) {
                response.usermeta.permit.issuedate = moment(response.usermeta.permit.issuedate).toDate();
                response.usermeta.permit.expirationdate = moment(response.usermeta.permit.expirationdate).toDate();
            }
            this.student = response;
        });

        UsersModel.getStudentOrders({'student_id': $stateParams.userId}).then(response => {
            this.orders = response.orders.data.map(order => {
                order.created_at = moment(order.created_at).format('MM/DD/YYYY h:mm a')
                order.lineitems = order.lineitems.map(item => {
                    item.product_details = isJson(item.product_details) ? JSON.parse(item.product_details) : item.product_details;
                    return item;
                })
                return order
            });
            this.totalItems = response.orders.total;
            this.loading = false;
        });
    }

    pageChanged(){
        this.UsersModel.getStudentOrders({'student_id' : this.$stateParams.userId, 'page' : this.currentPage}).then(response => {
            this.orders = response.orders.data.map(order => {
                order.created_at = moment(order.created_at).format('MM/DD/YYYY h:mm a')
                order.lineitems = order.lineitems.map(item => {
                    item.product_details = isJson(item.product_details) ? JSON.parse(item.product_details) : item.product_details;
                    return item;
                })
                return order
            });
        })
    }

}
AdminStudentViewOrdersController.$inject = ['$stateParams', 'UsersModel'];
export default AdminStudentViewOrdersController;