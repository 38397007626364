import cloneDeep from 'lodash/cloneDeep';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import { inDollars } from '../../../../DriveScout/Billing/Teller';

class AdminProductsController {
    constructor(ProductsModel, FlashService, UserService, ProductCategoriesModel){
        this.products = [];
        this.user = UserService.getCurrentUser();
        this.loading = true;
        this.ProductsModel = ProductsModel;
        this.FlashService = FlashService;
        this.inDollars = inDollars.bind(this);
        
        this.options = {
            disabled: [
                { name: 'Disabled', value: true },
                { name: 'Enabled', value: false }
            ],
            purchasable: [
                { name: 'Shown', value: true },
                { name: 'Hidden', value: false }
            ],
            category: [],
            sort: [
                { name: 'Name A-Z', type: 'name', direction: 'asc' },
                { name: 'Name Z-A', type: 'name', direction: 'desc' },
                { name: 'SKU A-Z', type: 'sku', direction: 'asc' },
                { name: 'SKU Z-A', type: 'sku', direction: 'desc' },
                { name: 'Date Added (Newest First)', type: 'created_at', direction: 'desc' },
                { name: 'Date Added (Oldest First)', type: 'created_at', direction: 'asc' },
                { name: 'Price (Low to High)', type: 'price', direction: 'asc' },
                { name: 'Price (High to Low)', type: 'price', direction: 'desc' },
                { name: 'Website Order (Low to High)', type: 'sequence', direction: 'asc' },
                { name: 'Website Order (High to Low)', type: 'sequence', direction: 'desc' }
            ]
        }
        this.search = {};
        this.search.disabled = this.options.disabled[1]; //show enabled products only by default

        ProductCategoriesModel.get().then( response => {
            this.options.category = response.categories;
        })

        ProductsModel.get(this.getSearchParams()).then(response => {
            this.products = response.products.data;
            this.totalItems = response.products.total;
            this.currentPage = response.products.current_page;
            this.loading = false;
        })
    }

    toggleProductPurchasable(product){
        let data = cloneDeep(product);
        product.purchasable = ! product.purchasable;
        data.purchasable = ! data.purchasable;
        this.ProductsModel.toggleData(data).then(response => {
            this.FlashService.setMessage({
                'type' : 'success',
                'message' : response.message
            })
        })
    }

    toggleProductDisable(product){
        let data = cloneDeep(product);
        data.disabled = ! data.disabled;
        product.disabled = ! product.disabled;
        this.ProductsModel.toggleData(data).then(response => {
            this.FlashService.setMessage({
                'type' : 'success',
                'message' : response.message
            })
        })
    }

    pageChanged(){
        this.loading = true;
        this.ProductsModel.get(this.getSearchParams(this.currentPage)).then(response => {
            this.loading = false;
            this.products = response.products.data;
            this.totalItems = response.products.total;
            this.currentPage = response.products.current_page;
            this.loading = false;
        })
    }

    searchProducts() {
        this.loading = true;

        this.ProductsModel.get(this.getSearchParams()).then((response) => {
            this.products = response.products.data;
            this.totalItems = response.products.total;
            this.currentPage = response.products.current_page;
            this.loading = false;
        })
    }

    getSearchParams(currentPage = 1, removeNulls = true) {
        let params = {
            'nameOrSKU': this.search.nameOrSKU ? this.search.nameOrSKU : null,
            'disabled': this.search.disabled ? this.search.disabled.value : null,
            'purchasable': this.search.purchasable ? this.search.purchasable.value : null,
            'category_id': this.search.category ? this.search.category.id : null,
            'sort_type': this.search.sort ? this.search.sort.type : null,
            'sort_direction': this.search.sort ? this.search.sort.direction : null,
            'page': currentPage ? currentPage : null
        }
        if (removeNulls === true) {
            params = omitBy(params, isNil)
        }
        
        return params;
    }
}
AdminProductsController.$inject = ['ProductsModel', 'FlashService', 'UserService', 'ProductCategoriesModel'];
export default AdminProductsController;
