import {States} from "../../../../common/constants/states";

class AdminSchoolEditController {

    constructor($state, $stateParams, SchoolsModel, FlashService, TIMEZONES) {
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.SchoolsModel = SchoolsModel;
        this.FlashService = FlashService;
        this.TIMEZONES = TIMEZONES;
        this.states = States;
        this.statuses = [{'name' : 'Active', 'value' : 'ACTIVE'}, {'name' : 'Staging', 'value' : 'STAGING'}];

        // this will be student info pulled from the server later
        SchoolsModel.getSchoolByID($stateParams.schoolId).then(response => {
            this.school = response;
            this.timezones = TIMEZONES.map(timezone => {
                if (timezone.code === this.school.timezone) {
                    this.school.timezone = timezone;
                }
                return timezone;
            });
        });
    }

    submit() {
        let school = Object.assign({}, this.school);
        school.timezone = this.school.timezone.code;
        this.SchoolsModel.editSchool(school).then(response => {
            this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
            this.$state.go('drivecoach.admin.schools.view', {'schoolId': this.school.id}, {'reload': true});
        })
    }

}
AdminSchoolEditController.$inject = ['$state', '$stateParams', 'SchoolsModel', 'FlashService', 'TIMEZONES'];
export default AdminSchoolEditController;