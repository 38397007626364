import { Order } from "./Order";
import { Policies } from "./Policies";
import { School } from "./School";
import Dinero from 'dinero.js'
import { inDollars } from "../../DriveScout/Billing/Teller";

class Cashier {

    /**
     * This is the class responsible for creating new orders
     *
     * @param {School} school The school this order is being processed for
     * @param {Order} order The order being worked on
     * @param {Payment[]} payments The payments to be applied
     */
    constructor(school, order = null) {
        this.$school = school;
        if (order === null) {
            this.$order = new Order();
        } else {
            this.$order = order;
        }

        this.$addresses = [];
        this.$paymentMethods = [];

        this.$use_existing_payment_method = false;

        this.$surchargeConfig = {
            surchargeBilledSeparately: false,
            surchargeAmount: 0,
            surchargeDetail: ''
        }

        this.$surchargeAmountBilled = 0;
        this.setupPolicies();
    }

    /**
     * Whether or not the surcharge should be applied to this order. Surcharges are only
     * applied to orders when they're being paid with credit cards.
     *
     * @return {Boolean}
     */
    checkSurchargeRequired(){
        if (this.order().payments().length === 0) {
            return false;
        }
        return this.surchargeBilledSeparately() && this.order().payments()[0].paymentMethod() === 'card';
    }

    /**
     * Whether or not this school has the Surcharge feature turned on.
     *
     * @returns {Boolean}
     */
    surchargeBilledSeparately(){
        return this.$surchargeConfig['surchargeBilledSeparately'];
    }

    /**
     * The surcharge detail text, generally something about why the user is being billed
     * more money because of some payment gateway tax
     *
     * @returns {string}
     */
    surchargeDetail(){
        return this.$surchargeConfig['surchargeDetail'];
    }

    /**
     * The percentage value of the surcharge. It is a whole number, for example: 3 would be 3 percent.
     * TODO: Rename this to surchargePercentage
     *
     * @returns {number}
     */
    surchargeAmount(){
        return this.$surchargeConfig['surchargeAmount'];
    }

    /**
     * When a surcharge is required, this is the amount of the surcharge in dollars.
     *
     * @returns {String}
     */
    surchargeAmountBilled(){
        return this.$surchargeAmountBilled;
    }

    /**
     * This is an alias to the school's policies, needed to determine things like
     * surcharge & checkout rules.
     *
     * @returns {Policies}
     */
    policies() {
        return this.school().policies();
    }


    /**
     * The school this cashier works for
     *
     * @returns {School}
     */
    school(){
        return this.$school;
    }


    /**
     * This returns the existing Order to returns a new one.
     *
     * @returns {Order}
     */
    order(){
        if (!this.$order) return new Order();
        return this.$order;
    }

    /**
     * Sets the Order to be worked on.
     *
     * @param {Order} order
     */
    setOrder(order){
        this.$order = order;
    }

    /**
     * Sets the possible existing billing addresses the customer already has on record.
     *
     * @param {[]Address]} addresses A collection of Address objects
     */
    setStudentAddresses(addresses){
        this.$addresses = addresses;
    }

    /**
     * Sets the possible existing payment methods the customer already has on record.
     *
     * @param {[]PaymentMethod]} paymentMethods A collection of PaymentMethod objects
     */
    setStudentPaymentMethods(paymentMethods) {
        this.$paymentMethods = [].concat(paymentMethods);
    }

    /**
     * Set the payment method to be used with a given payment
     *
     * @param {number} paymentMethodID The ID of a valid payment method
     * @param {number} paymentIndex The index of the payment on the order to apply the payment method to
     */
    setCustomerPaymentMethod(paymentMethodID, paymentIndex) {
        this.$use_existing_payment_method = true;
        this.$order.$payments[paymentIndex].$payment_method_id = paymentMethodID;
        this.$order.$payments[paymentIndex].$use_existing_payment_method = true;
    }

    /**
     * This sets the surcharge information based on the school's Billing policies.
     *
     * @returns {void}
     */
    setupPolicies() {
        const $billingPolicy = this.policies().getPolicyByName('billing');
        if ($billingPolicy) {
            if ($billingPolicy.metaExistsByKey('surcharge_on')) {
                const surchargeOn = $billingPolicy.getMetaValueByKey('surcharge_on');
                this.$surchargeConfig['surchargeBilledSeparately'] = surchargeOn === 'surcharge_on';
            }

            if ($billingPolicy.metaExistsByKey('surcharge_amount')) {
                const amount = parseFloat($billingPolicy.getMetaValueByKey('surcharge_amount'));
                this.$surchargeConfig['surchargeAmount'] = amount;
            }

            if ($billingPolicy.metaExistsByKey('surcharge_detail')) {
                this.$surchargeConfig['surchargeDetail'] = $billingPolicy.getMetaValueByKey('surcharge_detail');
            }
        }
    }

    /**
     * Add a product to the cart.
     *
     * @param {Product} product
     */
    addProduct(product) {
        const products = [].concat(...this.order().products(), product)
        this.order().setProducts(products);
    }

    /**
     * Add a coupon to the order.
     *
     * @param {Coupon} coupon
     */
    addCoupon(coupon) {
        this.order().setCoupon(coupon);
    }

    removeCoupon(){
        this.order().removeCoupon();
    }

    /**
     * Remove a product from the cart.
     *
     * @param {Product} product A Product object
     */
    removeProduct(product) {
        const products = this.order().products().filter(p => {
            return p.id() !== product.id();
        })
        this.order().setProducts(products);
    }

    /**
     * Add a Student to the order.
     *
     * @param {Student} student A Student object
     */
    addStudent(student) {
        this.order().setStudent(student);
    }

    /**
     * Remove a student from the order.
     */
    removeStudent() {
        this.order().removeStudent();
    }

    /**
     * the possible existing billing addresses the customer already has on record.
     *
     * @returns {[]Address} A collection of Address objects
     */
    addresses() {
        return this.$addresses;
    }

    /**
     * The possible existing billing addresses the customer already has on record.
     *
     * @returns {[]{id:number,name:string,last4:string,card_type:string,card_expiration_date:string,street:string,city:string,state:string,zip:string}} A collection of PaymentMethod objects
     */
    paymentMethods() {
        return this.$paymentMethods;
    }

    /**
     * Set the billing address to be used for payment.
     *
     * @param {number} index The index of the payment to set the billing address of. This is typically 0.
     * @param {Address} address The billing address to add.
     */
    addBillingAddress(index = 0, address) {
        this.$order.payments()[index].creditCard().setBillingAddress(address);
        this.checkBillingAddressAlreadyExistsAsPickupLocation(address.street())
    }

    /**
     * If the billing address is already an available address that means its a pick-up location. Students cannot have
     * duplicate pick-up locations, so prevent the admin from being able to select the option to add it as a
     * pick-up location
     *
     * @param {String} billingAddress The Billing Address street.
     * @returns {void}
     */
    checkBillingAddressAlreadyExistsAsPickupLocation(billingAddress = '') {
        if (!billingAddress) {
            return false;
        }
        let addressExists = this.addresses().filter(address => {
            return address.street().toLowerCase() === billingAddress.toLowerCase()
        }).length > 0;
        if (!addressExists) {
            this.billingAddressDoesNotAlreadyExist = true;
            this.$order.$payments[0].add_billing_address_as_pickup_location = false;
        } else {
            this.billingAddressDoesNotAlreadyExist = false;
            this.$order.$payments[0].add_billing_address_as_pickup_location = true;
        }
    }

    /**
     * Calculates and updates the subtotal and total for the order. This takes into account the school's
     * existing checkout rules regarding surcharges, etc.
     *
     * @return {number} Returns the new order total in cents.
     */
    calculateTotal() {
        this.$surchargeAmountBilled = 0;

        if (this.order().products().length === 0) {
            return 0;
        }

        this.order().setTotal(this.totalDue());

        if (this.surchargeBilledSeparately()) {
            let surchargeBase = this.order().total();
            // TODO: Refactor this. This entire method relies on too much spooky action at a distance.
            // namely, this assumes 'this.order().payments()[0]' will always be a "card" payment
            // method, that there will only ever be one payment being applied at a time and
            // that the most relevant payment will be in position 0 of the payments
            // collection.
            if (this.order().payments().length > 0 && this.order().payments()[0].paymentMethod() === 'card' && this.totalCreditCardPaymentsInCents() > 0){
                surchargeBase = this.totalCreditCardPaymentsInCents();
            }
            const surchargeInCents = this.calculateSurcharge(surchargeBase);

            const surchargeInDollars = inDollars(surchargeInCents);

            // apply surcharge
            this.$surchargeAmountBilled = surchargeInDollars;
            this.order().setTotal(this.totalDue() + surchargeInCents);

            this.orderTotal = this.order().total();

            return this.order().total();
        }

        return this.order().total();
    }

    /**
     * Returns the given amount in cents with the existing surcharge applied.
     *
     * @param {number} amount
     */
    paymentWithSurcharge(amount)
    {
        return amount + this.calculateSurcharge(amount);
    }

    /**
     * Returns the amount in cents due. Computed as the total product price in cents - applied coupons
     *
     * @return {number} The amount due in cents
     */
    totalDue()
    {
        if (this.couponProvided() === false) {
            return this.totalProductPriceInCents();
        }

        let totalDue = this.applyProvidedCouponTo(this.totalProductPriceInCents())

        return totalDue;
    }

    /**
     * Returns the amount in dollars due. Computed as the total product price in cents - applied coupons
     * @return {String} Formatted string for dollar amount
     */
    totalDueInDollars(){
        return inDollars(this.totalDue());
    }

    /**
     * Returns the amount in dollars due. Computed as the total product price in cents - applied coupons + any surcharge
     * @return {String} The amount due in dollars as a formatted string
     */
    totalDueWithSurchargeInDollars()
    {
        let totalDue = this.paymentWithSurcharge(this.totalDue())
        return inDollars(totalDue);
    }

    totalBilled()
    {
        if (this.order().payments().length > 0 && this.order().payments()[0].paymentMethod() !== 'card') {
            return this.totalPaymentsInCents();
        }

        // If paying with a card...
        if (this.totalCreditCardPaymentsInCents() > 0) {
            return this.paymentWithSurcharge(this.totalCreditCardPaymentsInCents());
        }

        // Otherwise just apply the surcharge to the payment due
        return this.paymentWithSurcharge(this.totalDue())
    }

    /**
     * Calculate the surcharge in cents for a given value in cents
     *
     * @param {number} totalDue
     * @return {number} The surcharge for the given amount of money in cents
     */
    calculateSurcharge(totalDue)
    {
        if (this.surchargeBilledSeparately() === false || this.totalCreditCardPaymentsInCents() === 0) {
            return 0;
        }

        if (this.surchargeBilledSeparately()) {
            return Dinero({ amount: totalDue, currency: 'USD' })
                .percentage(this.$surchargeConfig['surchargeAmount'])
                .getAmount()
        }
        return 0;
    }

    /**
     * Applies the currently provided coupon to a given amount of money in cents
     *
     * @param {number} amount The amount to apply the currently provided coupon to
     * @return {number} The new amount with the coupon applied
     */
    applyProvidedCouponTo(amount)
    {
        // apply coupon if provided, otherwise proceed
        if (this.couponProvided()){
            if (this.coupon().type() === 'fixed') {
                return amount - this.coupon().amount();
            }

            if (this.coupon().type() === 'percentage') {
                const subAmount = Dinero({ amount: amount, currency: 'USD' }).percentage(this.coupon().amount());
                const dinero = Dinero({amount: amount, currency: 'USD'}).subtract(subAmount)
                return dinero.getAmount()
            }
        }

        return amount;
    }

    /**
     * The coupon applied to this order
     *
     * @return {Coupon}
     */
    coupon(){
        return this.order().coupon();
    }

    /**
     * Whether or not there is a coupon applied
     *
     * @return {Boolean}
     */
    couponProvided(){
        return this.order().coupon() !== null;
    }

    /**
     * To display how much in dollars the coupon is subtracting for a percentage type coupon
     *
     * @param {number} total The total amount the coupon is applied to
     * @return {String}
     */
    couponSubAmount(total) {
        const subAmount = Dinero({ amount: total, currency: 'USD' }).percentage(this.coupon().amount());
        return inDollars(subAmount.getAmount());
    }

    /**
     * Returns the amount the user is going to be charged in dollars.
     *
     * @returns {String}
     */
    amountPaidToday() {
        return inDollars(this.totalBilled());
    }

    /**
     * Prevent overpayment
     *
     * @returns {Boolean}
     */
    validatePayment() {
        if (this.totalPaymentsInCents() > this.totalDue()) {
            this.order().payments()[0].setAmount(this.totalDue());
            return false;
        }

        return true;
    }

    /**
     * The total amount of payments made with a credit card in cents
     *
     * @returns {number} The total in cents of credit card payments applied to this order.
     */
    totalCreditCardPaymentsInCents() {
        if (this.order().payments().length === 0) {
            return 0;
        }
        return this.order().payments().filter(payment => payment.paymentMethod() === 'card').reduce((i, payment) => {
            return payment.amount() + i;
        }, 0)
    }

    /**
     * The sum of the orders payments in cents
     *
     * @returns {number}
     */
    totalPaymentsInCents() {
        if (this.order().payments().length === 0) {
            return 0;
        }
        return this.order().payments().reduce((i, payment) => {
            return payment.amount() + i;
        }, 0)
    }

    /**
     * The sum of the orders products prices in cents.
     *
     * @returns {number}
     */
    totalProductPriceInCents() {
        if (this.order().products().length === 0) {
            return 0;
        }
        return this.order().products().reduce((i, product) => {
            return product.price() + i;
        }, 0);
    }

    /**
     * Whether or not a service has been purchased that allows for partial payments
     *
     * @return bool
     */
    allowsPartialPayments()
    {
        const allowsPartialPayments = this.order().products().filter(product => {
            return product.allowsPayLater() === true;
        }).length > 0;

        return allowsPartialPayments;
    }

    validateMinimumPayment()
    {
        // If the school does not allow for partial payments on the services being purchased,
        // make sure that the payment provided === the products purchased - applied coupons
        if (this.allowsPartialPayments() === false) {
            if ((this.totalPaymentsInCents() !== this.totalDue())) {
                return false;
            }

            return true;
        } else {
            // what is the minimum payment required?
            // minimum payment required per service added together
            let $minimum_payment_required = this.minimumDepositAmountRequired();

            if (this.couponProvided()) {
                $minimum_payment_required = this.applyProvidedCouponTo($minimum_payment_required);
            }

            if (this.totalPaymentsInCents() < $minimum_payment_required){
                return false;
            }

            return true;
        }
    }

    /**
     * The minimum deposit required for this order based on the products attached to it.
     *
     * @returns {number} The minimum deposit required for this order.
     */
    minimumDepositAmountRequired()
    {
        return this.order().products().reduce(($carry, $product) => {
            return $product.calculateMinimumDepositRequired($product.price()) + $carry;
        },0);
    }

    /**
     * The minimum payment required for this order based on the LineItem's associated with the order.
     *
     * @returns {number} The minimum payment required for this order.
     */
    minimumPaymentAmountRequired(){
        return this.order().lineItems().reduce(($carry, $lineitem) => {
            return $lineitem.calculateMinimumPaymentRequired($lineitem.price()) + $carry;
        },0);
    }


}

/**
 *
 * @param {Cashier} cashier
 */
function cashierOrderRequestTransformer(cashier){

    const $data = {
        'total': cashier.order().total(),
        'student': {
            id: cashier.order().student().id(),
        },
        'products': cashier.order().products().map(product => {
            return {
                id: product.id(),
            };
        }),
        'payments' : cashier.order().payments().map(payment => {
            let paymentData = {
                payment_method: payment.paymentMethod(),
                payment_amount: payment.amount(),
                add_billing_address_as_pickup_location: payment.$add_billing_address_as_pickup_location,
                use_existing_payment_method: payment.useExistingPaymentMethod(),
            }

            if (payment.paymentMethod() === 'card') {
                let card = {
                    token: payment.creditCard().token(),
                    name_on_card: payment.creditCard().nameOnCard(),
                    billing_address: {
                        street: payment.creditCard().billingAddress().street(),
                        city: payment.creditCard().billingAddress().city(),
                        state: payment.creditCard().billingAddress().region(),
                        zip: payment.creditCard().billingAddress().postalCode(),
                    }
                }

                if (payment.useExistingPaymentMethod() === true) {
                    card.use_existing_payment_method = true;
                    card.payment_method_id = payment.paymentMethodID();
                    delete card.token;
                    delete card.billing_address;
                    delete card.name_on_card;
                }

                if (payment.useExistingPaymentMethod() === false && payment.creditCard().token() === null) {
                    card.number = payment.$card.number();
                    card.expiration_year = payment.creditCard().expirationYear();
                    card.expiration_month = payment.creditCard().expirationMonth();
                    card.cvc = payment.creditCard().cvv();
                    delete card.token;
                }
                paymentData.card = card;
            }

            if (payment.paymentMethod() === 'check') {
                paymentData.check_number = payment.checkNumber();
            }
            return paymentData;
        })
    };

    if (cashier.couponProvided()) {
        $data.coupon = cashier.coupon().code();
    }

    return $data;
}

export { Cashier, cashierOrderRequestTransformer }
