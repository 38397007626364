import angular from 'angular'
import template from './admin-student-create-high-school-pick-up-location.html'
import AdminStudentCreateHighschoolController from './admin-student-create-high-school-pick-up-location.controller'

function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.admin.students.pickuplocations.create.highschool', {
            'url' : '/high-school',
            'controller' : AdminStudentCreateHighschoolController,
            'controllerAs' : 'vm',
            'template' : template,
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner,
                    USER_ROLES.staff
                ]
            }
        } )

    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'admin.dashboard.students.pickuplocations.create.highschool', [] )
    .config(config)
    .controller( 'AdminStudentCreateHighschoolController', AdminStudentCreateHighschoolController )
;
