import angular from 'angular'
import AdminStudentViewController from './admin-view-student.controller'
import template from './admin-view-student.html'
import Fees from './fees/admin-student-fees.module'
import Orders from './orders/student-orders.module'
import Tasks from './tasks/tasks.module'
import Emails from './emails/emails.module'
import CreateOrder from './create-order/create-order.module'
import Requirements from './lesson-plan-requirements/lesson-plan-requirements.module'
import Classes from './classes/classes.module'
import Instructors from './instructors/instructors.module'
import Cancelations from './cancelations/admin-view-student-cancelations.module'
import DriveDeadline from './drive-deadline/drive-deadline.module'
import Certificate from './certificate/certificate.module'
import OnlineDriversEd from './online-drivers-ed/online-drivers-ed.module'
import StudentPaymentMethods from './payment-methods/payment-methods.module'

function getBalance(UsersModel, $stateParams) {
    return UsersModel.getStudentBalance($stateParams.userId).then(response => {
        return response.balance;
    });
}
getBalance.$inject = ['UsersModel', '$stateParams'];

function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.admin.students.view', {
            'url' : '/view/:userId',
            'controller' : AdminStudentViewController,
            'controllerAs' : 'vm',
            'template' : template,
            resolve: {
                'balance' : getBalance
            },
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner,
                    USER_ROLES.staff
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];


export default angular.module('admin.dashboard.students.view', [
    Fees.name,
    Orders.name,
    CreateOrder.name,
    Tasks.name,
    Emails.name,
    Requirements.name,
    Classes.name,
    Instructors.name,
    Cancelations.name,
    DriveDeadline.name,
    Certificate.name,
    OnlineDriversEd.name,
    StudentPaymentMethods.name
]).config(config)
    .controller('AdminStudentViewController', AdminStudentViewController);
