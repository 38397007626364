import difference from 'lodash/difference'
import flattenDeep from 'lodash/flattenDeep'
import { isJson } from '../../../../common/helpers/json';
function AdminInstructorEditLocations(  $state, UsersModel, FlashService, instructor, LocationsModel ) {

    const vm = this;
    vm.instructor = instructor;
    vm.unchecked = true;
    vm.loading = true;
    vm.formData = {
        'id' : vm.instructor.id,
        'sectors' : vm.instructor.zoneparts,
        'locations' : vm.instructor.locations
    };

    vm.deselectSectors = deselectSectors;
    vm.hasLocation = hasLocation;
    vm.getZonesAndSectors = getZonesAndSectors;
    vm.submit = submit;
    vm.checkSelect = checkSelect;

    init();

    function init(){
        vm.user = UserService.getCurrentUser();
        vm.school = vm.user.school;
        vm.school.address = isJson(vm.user.school.address) ? JSON.parse(vm.user.school.address) : vm.user.school.address;
        vm.formData.school_id = vm.school.id;
        
        LocationsModel.getLocations().then(response => {
            const locations = response;
            vm.locations = locations;
            getZonesAndSectors( vm.locations );
            vm.locations.forEach( function ( location, index ) {
                if ( typeof vm.formData.locations[index] !== 'undefined' ) {
                    if ( vm.formData.locations[index].id === location.id ) {
                        vm.formData.locations[index] = location;
                    }
                }
            } );
            vm.sectors.forEach( function ( sector, index ) {
                if ( typeof vm.formData.sectors[index] !== 'undefined' ) {
                    if ( vm.formData.sectors[index].id === sector.id ) {
                        vm.formData.sectors[index] = sector;
                    }
                }
            } );
            vm.loading = false;
        })
    }

    function deselectSectors() {

        //var removedLocations = _.difference(vm.locations, vm.formData.locations);
        let removedLocations = vm.locations.filter( function ( location ) {
            let selected = true;
            vm.formData.locations.forEach( function ( selectedLocation ) {
                if ( selectedLocation !== null && selectedLocation.id === location.id ) {
                    selected = false;
                }
            } );
            return selected;
        } );

        if ( removedLocations.length > 0 ) {
            // remove the removed locations sectors from the selected sectors
            removedLocations.forEach( function ( location ) {
                let removedSectors = difference( location.zoneparts, vm.formData.sectors );

                vm.formData.sectors = vm.formData.sectors.filter( function ( sector ) {

                    // compare this sector to each of the removed sectors. if their ids match, remove this sector
                    // from vm.formData.sectors
                    let keep = true;
                    removedSectors.forEach( function ( rSector ) {
                        if ( sector.id === rSector.id ) {
                            keep = false;
                        }
                    } );

                    return keep;

                } );

            } )
        }


        // If no locations are selected deselect all sectors
        if ( removedLocations.length === vm.locations.length ) {
            vm.formData.sectors = [];
        }

        vm.sectors.forEach( function ( sector, index ) {
            if ( typeof vm.formData.sectors[index] !== 'undefined' ) {
                if ( vm.formData.sectors[index].id === sector.id ) {
                    vm.formData.sectors[index] = sector;
                }
            }
        } );
    }

    function hasLocation( id ) {
        if ( typeof vm.instructor !== 'undefined' ) {
            return vm.instructor.locations.filter( function ( location ) {
//                console.log( location.id === id );
                return location.id === id;
            } )
        }
        return false;
    }

    function getZonesAndSectors( locations ) {
        vm.zones = [];
        vm.sectors = [];

        let _zones = [];
        let _sectors = [];

        locations.forEach( function ( location ) {
            if ( null !== location ) {
                let o = JSON.parse( location );
                _zones.push( o.zones );
            }
        } );

        vm.zones = flattenDeep( _zones );

        vm.zones.forEach( function ( zone ) {
            _sectors.push( zone.zoneparts );
        } );

        vm.sectors = flattenDeep( _sectors );

    }

    function submit( isValid ) {
        vm.submitted = true;
        checkSelect();

        if ( isValid && ! vm.unchecked ) {
            UsersModel.editInstructorLocations( vm.formData ).then( function ( response ) {
                FlashService.setMessage( { 'type' : 'success', 'message' : response.message } );
                vm.submitted = false;
                $state.go( 'drivecoach.admin.instructors.index', {}, { 'reload' : true } );
            } );
        } else {
            FlashService.setMessage( { 'type' : 'error', 'message' : 'Please fill in the form.' } );
        }
    }

    function checkSelect() {
        vm.unchecked = true;
        for ( let i = vm.formData.sectors.length - 1, itm; i >= 0; i -- ) {
            itm = vm.formData.sectors[i];
            if ( itm ) {
                vm.unchecked = false;
                break;
            }
        }
    }

}
AdminInstructorEditLocations.$inject = [ '$state', 'UsersModel', 'FlashService', 'instructor', 'LocationsModel'];

export default AdminInstructorEditLocations;
